import React, { useEffect, useMemo, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardText,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledTooltip,
    Spinner
} from "reactstrap";
import classnames from "classnames";
import TableContainer from 'components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { retriveBranchInfo,IRTvalidateDupName,setBranchNameExist,setDepartmentExist, deleteBranchInfo,setBranchCodeExist, seteditDeptInfo,createBranchInfo, seteditBranchInfo, setBranchData, setDepartmentData, retriveDeptInfo, createDeptInfo, deleteDeptInfo } from 'toolkitStore/IncidentReport/IR_OrgSlice';
import ModalComponent from './Components/Modal';
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from 'sweetalert2';
import { message } from 'antd';
import store from 'store';

const IROrganisation = () => {
    const [activeTab1, setactiveTab1] = useState("5");
    const [modal, setModal] = useState(false);
    const [modalDept, setModalDept] = useState(false);
    const [branchNameExist, setBranchNameExit] = useState(false);
    

    const dispatch = useDispatch()
    const IR_OrgSlice = useSelector(state => state.IR_OrgSlice)
    const [authUser, setAuthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [clientInfo, setClientInfo] = useState(JSON.parse(sessionStorage.getItem("client_info")))[0]


    console.log(IR_OrgSlice,'IR_OrgSlice')

    useEffect(() => {
        console.log("use effect")
        dispatch(retriveBranchInfo())
    }, [])

    const toggle1 = tab => {
        if (activeTab1 !== tab) {
            setactiveTab1(tab);
        }
    };

    const onClickDelete = (item,mode) => {

        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: `Do you want to delete this ${mode === "1" ? "Branch" :"Department"} ?`,
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                mode === "1" ? dispatch(deleteBranchInfo(item)) : dispatch(deleteDeptInfo(item))
            }


        })


    }



    const columns = useMemo(
        () => [
            {
                accessor: "branch_name",
                Header: "Branch Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.branch_name}
                        </div>

                    )
                }
            },
            {
                accessor: "branch_code",
                Header: "Branch Code",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.branch_code ?item.branch_code:"-" }
                        </div>

                    )
                }
            },
            {
                accessor: "branch_contact",
                Header: "Branch Contact",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.branch_contact ? item.branch_contact :"-"}
                        </div>

                    )
                }
            },
            {
                accessor: "Action",
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        dispatch(seteditBranchInfo(item))
                                        setModal(true)
                                        validation.setValues(item);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        onClickDelete(item,"1");
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>

                    )

                }



            }

        ]
    )



    const Deptcolumns = useMemo(
        () => [
            {
                accessor: "dept_name",
                Header: "Department Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.dept_name}
                        </div>

                    )
                }
            },
            {
                accessor: "dept_code",
                Header: "Department Code",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.dept_code ? item.dept_code  :"-"}
                        </div>

                    )
                }
            },
            {
                accessor: "dept_branch",
                Header: "Department Branch",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    var branch_info = _.find(IR_OrgSlice.branchData,{_id : item.dept_branch})
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {branch_info?.branch_name}
                        </div>

                    )
                }
            },
            {
                accessor: "Action",
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        dispatch(seteditDeptInfo(item))
                                        setModalDept(true)
                                        deptValidation.setValues(item);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        onClickDelete(item,"2");
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>

                    )

                }



            }

        ]
    )

    const validation = useFormik({
        initialValues: {
            branch_name: "",
            branch_address: "",
            branch_contact: "",
            branch_ccode: '+91',
            branch_code: "",
            branch_email: "",
            branch_descrption: "",

        },
        validationSchema: Yup.object({
            branch_name: Yup.string().required("Branch Name is required").test(async function (value) {
                console.log(value,'value',store.getState())
                if(value !== undefined){
                return !(await dispatch(IRTvalidateDupName(store.getState().IR_OrgSlice.editBranchInfo,"cln_ir_branches","branch_name",value,setBranchNameExist)));
            }
            })
            .matches(/\S+/, "Branch Name cannot be just spaces")
            .min(clientInfo.incd_rpt_validation.name_min,`Branch name must be at least ${clientInfo.incd_rpt_validation.name_min} characters.`),
            branch_code: Yup.string().required("Branch code is required").test(async function (value) {
                console.log(value,'value',store.getState())
                if(value !== undefined){
                return !(await dispatch(IRTvalidateDupName(store.getState().IR_OrgSlice.editBranchInfo,"cln_ir_branches","branch_code",value,setBranchCodeExist)));
                }
            }),
            branch_descrption:Yup.string()
            .nullable() // Allows the field to be null
            .notRequired() // Field is not mandatory
            .test(
                'description-validation',
                'Description must be at least 10 characters and cannot be just spaces',
                function (value) {
                    if (!value) return true; // If no input, validation passes
                    const trimmedValue = value.trim();
                    return trimmedValue.length >= clientInfo.incd_rpt_validation.desc_min; // Applies validation only when input exists
                }

            )
        }),
        onSubmit: (values) => {

            // if (!values.branch_contact && !values.branch_email) {
            //     validation.setErrors({
            //         branch_contact: "Enter either Phone number or Email.",
            //         branch_email: "Enter either Phone number or Email.",
            //     });
            //     return; 
            // }

            values["created_by"] = authUser.user_data._id
            if (IR_OrgSlice.editBranchInfo !== null) {
                values["_id"] = IR_OrgSlice.editBranchInfo._id
            }
            if (String(values.branch_ccode) === "undefined") {
                values["branch_ccode"] = "+91"
            }

            console.log(values, String(values.branch_ccode));
            if (IR_OrgSlice.branchNameExist === false) {
                dispatch(createBranchInfo(values))
                setModal(false);

            }
        },
    });


    const deptValidation = useFormik({
        initialValues: {
            dept_branch: "",
            dept_name: "",
            dept_code: "",
            dept_descrption: '',

        },
        validationSchema: Yup.object({
            dept_name: Yup.string().required("Department Name is required").test(async function (value) {
                console.log(value,'value')
                return !(await dispatch(IRTvalidateDupName(store.getState().IR_OrgSlice.editDeptInfo,"cln_ir_depart_lists","dept_name",value,setDepartmentExist)));
            })
            .matches(/\S+/, "Department Name cannot be just spaces")
            .min(clientInfo.incd_rpt_validation.name_min, `Department Name must be at least ${ clientInfo.incd_rpt_validation.name_min } characters`)

            ,
            dept_branch: Yup.string().required("Department Branch is required"),
            dept_descrption : Yup.string()
            .nullable()
            .notRequired()
            .test(
                'description-validation',
                'Description must be at least 10 characters and cannot be just spaces',
                function (value) {
                    if (!value) return true; // If no input, validation passes
                    const trimmedValue = value.trim();
                    return trimmedValue.length >= clientInfo.incd_rpt_validation.desc_min; // Applies validation only when input exists
                }
            )
        }),
        onSubmit: (values) => {
            console.log(values, 'values');
            values["created_by"] = authUser.user_data._id
            if (IR_OrgSlice.editDeptInfo !== null) {
                values["_id"] = IR_OrgSlice.editDeptInfo._id
            }
            if (IR_OrgSlice.deptNameExist === false) {
                dispatch(createDeptInfo(values))
                setModalDept(false)
            }

        },
    });



    const fields = [
        { label: "Branch Name :", name: "branch_name", placeholder: "Enter Branch Name", mandatory: true, type: "text" , name_exist : IR_OrgSlice.branchNameExist , message : IR_OrgSlice.branchNameExist ? "Branch Name already exist" :""},
        { label: "Branch Address :", name: "branch_address", placeholder: "Enter Branch Address", mandatory: false, type: "textarea" },
        { label: "Branch Contact :", name: "branch_contact", placeholder: "Enter Branch Contact", mandatory: false, type: "number", countryCode: true, country_list: authUser.client_info[0].countries },
        { label: "Branch Code :", name: "branch_code", placeholder: "Enter Branch Code", mandatory: false, type: "text" ,name_exist : IR_OrgSlice.branchCodeExist , message : IR_OrgSlice.branchCodeExist ? "Branch Code already exist" :"" },
        { label: "Branch Email :", name: "branch_email", placeholder: "Enter Branch Email", mandatory: false, type: "text" },
        { label: "Branch Description :", name: "branch_descrption", placeholder: "Enter Branch Description", mandatory: false, type: "textarea" },
    ];


    const fieldsDept = [
        { label: "Select Branch :", name: "dept_branch", placeholder: "Choose Branch", mandatory: true, type: "select", options: IR_OrgSlice.branchData, key: "branch_name", value: "_id" },
        { label: "Department Name :", name: "dept_name", placeholder: "Enter Department Name", mandatory: true, type: "text",name_exist : IR_OrgSlice.deptNameExist , message : IR_OrgSlice.deptNameExist ? "Department Name already exist" :"" },
        { label: "Department Code :", name: "dept_code", placeholder: "Enter Department Code", mandatory: false, type: "text" },
        { label: "Department Description :", name: "dept_descrption", placeholder: "Enter Department Description", mandatory: false, type: "textarea" },
    ];



    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Organisation" breadcrumbItem="Organisation" />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Nav pills className="navtab-bg nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab1 === "5",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("5");
                                                    }}
                                                >
                                                    Branch
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab1 === "6",
                                                    })}
                                                    onClick={() => {
                                                        toggle1("6");
                                                        dispatch(retriveDeptInfo())
                                                    }}
                                                >
                                                    Department
                                                </NavLink>
                                            </NavItem>

                                        </Nav>

                                        <TabContent activeTab={activeTab1} className="p-3 text-muted">
                                            <TabPane tabId="5">
                                                <Row>
                                                    <Col sm="12">
                                                        <CardBody className="border-bottom">
                                                            <div className="d-flex align-items-center">
                                                                <h5 className="mb-0 card-title flex-grow-1">Branch List</h5>
                                                                <div className="flex-shrink-0">
                                                                    <Link to="#!"
                                                                        onClick={() => {
                                                                            setModal(true);
                                                                            validation.setValues({});
                                                                            validation.resetForm()
                                                                        }}
                                                                        className="btn btn-primary me-1">Add New Branch</Link>
                                                                    <Link to="#!" className="btn btn-light me-1" onClick={() => {
                                                                        dispatch(setBranchData([]));
                                                                        dispatch(retriveBranchInfo());
                                                                        dispatch(setBranchNameExist(false))

                                                                    }}><i className="mdi mdi-refresh"></i></Link>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        {
                                                            IR_OrgSlice.branchData.length > 0 ?
                                                                <CardBody>
                                                                    <TableContainer
                                                                        columns={columns}
                                                                        data={IR_OrgSlice.branchData}
                                                                        isGlobalFilter={true}
                                                                        isAddOptions={false}
                                                                        isJobListGlobalFilter={false}
                                                                        isPagination={true}
                                                                        iscustomPageSizeOptions={true}
                                                                        isShowingPageLength={true}
                                                                        customPageSize={5}
                                                                        tableClass="table-bordered align-middle nowrap mt-2"
                                                                        paginationDiv="col-sm-12 col-md-7"
                                                                        pagination="pagination justify-content-end pagination-rounded"
                                                                    />
                                                                   
                                                                </CardBody>
                                                                :
                                                                IR_OrgSlice.branchData.length === 0 && IR_OrgSlice.aplnRequestLoading === false ?
                                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                                    No Data

                                                                </div>
                                                                :
                                                                <CardBody style={{ height: "100vh" }}>
                                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                                        <div>Loading...</div>
                                                                        <Spinner className="ms-2" color="primary" />
                                                                    </div>
                                                                </CardBody>
                                                        }


                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="6">
                                                <Row>
                                                    <Col sm="12">
                                                        <CardBody className="border-bottom">
                                                            <div className="d-flex align-items-center">
                                                                <h5 className="mb-0 card-title flex-grow-1">Department List</h5>
                                                                <div className="flex-shrink-0">
                                                                    <Link to="#!"
                                                                        onClick={() => {
                                                                            setModalDept(true);
                                                                            deptValidation.setValues({});
                                                                            deptValidation.resetForm()
                                                                        }}
                                                                        className="btn btn-primary me-1">Add New Department</Link>
                                                                    <Link to="#!" className="btn btn-light me-1" onClick={() => {
                                                                        dispatch(setDepartmentData([]));
                                                                        dispatch(retriveDeptInfo())

                                                                    }}><i className="mdi mdi-refresh"></i></Link>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        {
                                                            IR_OrgSlice.departMentData.length > 0 ?
                                                                <CardBody>
                                                                    <TableContainer
                                                                        columns={Deptcolumns}
                                                                        data={IR_OrgSlice.departMentData}
                                                                        isGlobalFilter={true}
                                                                        isAddOptions={false}
                                                                        isJobListGlobalFilter={false}
                                                                        isPagination={true}
                                                                        iscustomPageSizeOptions={true}
                                                                        isShowingPageLength={true}
                                                                        customPageSize={5}
                                                                        tableClass="table-bordered align-middle nowrap mt-2"
                                                                        paginationDiv="col-sm-12 col-md-7"
                                                                        pagination="pagination justify-content-end pagination-rounded"
                                                                    />
                                                                </CardBody>
                                                                :
                                                                IR_OrgSlice.departMentData.length === 0 && IR_OrgSlice.aplnRequestLoading === false ?
                                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                                    No Data

                                                                </div>
                                                                :
                                                                <CardBody style={{ height: "100vh" }}>
                                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                                        <div>Loading...</div>
                                                                        <Spinner className="ms-2" color="primary" />
                                                                    </div>
                                                                </CardBody>
                                                        }

                                                        {
                                                            modalDept &&
                                                            <ModalComponent
                                                                modal={modalDept}
                                                                toggle={() => { setModalDept(!modalDept) }}
                                                                onSubmit={() => { deptValidation.handleSubmit }}
                                                                fields={fieldsDept}
                                                                validation={deptValidation}
                                                                isEdit={false}
                                                                backDrop={"static"}
                                                                modalHeader={IR_OrgSlice.editDeptInfo !== null ? "Edit Department" : "Add Department"}
                                                                btnName={IR_OrgSlice.editDeptInfo !== null ? "Update" : "Save"}

                                                            />

                                                        }
                                                         {
                                                                        modal &&
                                                                        <ModalComponent
                                                                            modal={modal}
                                                                            toggle={() => { setModal(!modal) }}
                                                                            onSubmit={() => { validation.handleSubmit }}
                                                                            fields={fields}
                                                                            validation={validation}
                                                                            isEdit={false}
                                                                            backDrop={"static"}
                                                                            modalHeader={IR_OrgSlice.editBranchInfo !== null ? "Edit Branch" : "Add Branch"}
                                                                            btnName={IR_OrgSlice.editBranchInfo !== null ? "Update" : "Save"}
                                                                        />

                                                                    }



                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )

}
export default IROrganisation
