import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Input,Col, Row, UncontrolledTooltip,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label, DropdownMenu, DropdownToggle, UncontrolledDropdown, Tooltip, UncontrolledAlert } from "reactstrap"
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import SortableTree from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import { Space, Badge, Tag,Popconfirm } from 'antd'
import Select from 'react-select';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import { post } from 'helpers/api_helper';
import { addNode, crudNode,setnodeUsers,setShowUserListDrawer, setnodeInfo,getHdata, publishHStructure, dndNode, setMenuName, saveTreeData, getTreeFromFlatDataAsync, setTreeData,saveData, deleteNode, getNodeData, setState, updateTreeData, editNode, updateNode, onTreeChange, treeDataToFlat, addNodeUser } from '../../../../toolkitStore/IncidentReport/htree'
import UserListForm from './UserListForm';
import { retriveNodeUsers,addNodeUsers,retriveUserList } from 'toolkitStore/IncidentReport/IR_LocationSlice';
import './sortableTree.css'
import store from 'store';




const TreeStructure = (props) => {
    const dispatch = useDispatch();
    // const history = useNavigate()
    const [dataLoaded, setDataLoaded] = useState(true);
    const [dropdownStates, setDropdownStates] = useState({});
    const [dropdownStatesEdit, setdropdownStatesEdit] = useState({});
    const [createNewCat, setcreateNewCat] = useState(false)
    const [getSubTitledd, setgetSubTitledd] = useState("")
    const [enableCode, setenableCode] = useState(false)
    const [hInfo, setHinfo] = useState(JSON.parse(sessionStorage.getItem("hInfo")))
    const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [catListInfo, setcatListInfo] = useState([])
    const [userInfo, setuserInfo] = useState([])

    const [viewUsers, setviewUsers] = useState(false)
    const [editCatName, seteditCatName] = useState(false)
    const [codeErrorEnabled,setcodeErrorEnabled] = useState(false)
    const [codeError,setcodeError] = useState('')
    const [invalidTittle,setinvalidTittle] = useState(false)


    const optionRef = useRef()


    const treeData = useSelector(updateTreeData);
    const state = useSelector(state => state.IncidentHtreeData);
    const nodeUsers = state.nodeUsers
    const nodeInfo = state.nodeInfo

    console.log(state,'treeData');

    const toggle = () => {
        dispatch(setState({ mainToggle: !state.mainToggle }));
    };


    useEffect(() => {
        dispatch(getHdata())

    }, [])


    useEffect(() => {
        var catList = listOutCategory()
        setcatListInfo(catList)
    }, [state.treeData])


    const checkNodeValidation=async(values)=>{
        var getCode = String(values.code).replace(/\s/g, '').toLowerCase()
        var flat_data = treeDataToFlat(state.treeData)
        var flat_data = _.map(flat_data,'node')
        if(state.crudStatus==2){



        }
        else{
            var getCodeValue = _.findIndex(flat_data, function (o) { if (String(o.code).replace(/\s/g, '').toLowerCase() == getCode) { return true } else { return false } });
            console.log(getCodeValue,'getCodeValue')
            if(getCodeValue !==-1){
                
                setcodeErrorEnabled(true)
                setcodeError("Code is already available")
                return true
            }
            else{
                return false
            }
        }
    }


    const addCrudNode = async(event, values) => {
        console.log(values, 'values', state.crudStatus, state, nodeInfo)
        var validateNode=false
        if(enableCode){
         validateNode = await checkNodeValidation(values)
        }
        if(!validateNode){
        if (state.crudStatus === 0) {
            values["parent"] = null
            values["child_id"] = []
            values["h_id"] = hInfo._id
            values["company_id"] = hInfo.company_id
            values["company_name"] = hInfo.company_name
            values["owner_id"] = authUser.user_data._id
            values["user_path"] = []
            values["unique_users"] = []
            values["node_level"] = state.path.length,
            values["category_input"] = values.subtitledd == "0" || values.subtitledd === "" ? false : true
        }
        if (state.crudStatus === 1) {
            if(values.subtitledd === "0"){
                values.subtitledd = ""
            }
            values["parent"] = nodeInfo.id
            values["child_id"] = []
            values["h_id"] = hInfo._id
            values["company_id"] = hInfo.company_id
            values["company_name"] = hInfo.company_name
            values["owner_id"] = authUser.user_data._id
            values["user_path"] = []
            values["unique_users"] = []
            values["node_level"] = state.path.length,
            values["subtitle"] = values.subtitledd == "" || (values.subtitledd === "1" && values.subtitle === "") ? "Level " + (state.path.length + 1) : values.subtitledd === "1" ? values.subtitle : values.subtitledd
            values["category_input"] = (values.subtitledd !== "" && values.subtitledd !== "0") || (values.subtitledd == "1" && values.subtitle !== "") ? true : false
            values["node_positon"] = "Level " + (state.path.length + 1)

        }

        const action = state.crudStatus === 2 ? updateNode : crudNode;
        dispatch(action(values));
        setDropdownStates({})
        setdropdownStatesEdit({})
        setcreateNewCat(false)
        }
    };

    const toggleToolTip = (targetName) => {
        // console.log('called..')
    };

    const handleTreeChange = (newTreeData) => {
        onTreeChange(newTreeData, dispatch);
    };

    const toggleDropdown = (id) => {
        setDropdownStates({
            ...dropdownStates,
            [id]: !dropdownStates[id] // Toggle the state for the clicked dropdown menu
        });
        setcodeError('')
        setcodeErrorEnabled(false)
        // dispatch(addNode(node, updatedPath, 0))
    };

    const toggleDropdownEdit = (id) => {
        setdropdownStatesEdit({
            ...dropdownStatesEdit,
            [id]: !dropdownStatesEdit[id] // Toggle the state for the clicked dropdown menu
        });
    };


    const selectCat = (event, mode) => {
        console.log(event, mode)
        if (mode === undefined) {
            setcreateNewCat(event.target.value == "1" ? true : false)
            setgetSubTitledd(event.target.value)
        }
        else {
            optionRef.current.value = "0"
            setcreateNewCat(false)

        }

    }


    const deleteAllUsers = async (node) => {
        dispatch(addNodeUsers([],state.nodeInfo,"0"))
        setDropdownStates({})
        setdropdownStatesEdit({});
        dispatch(setnodeUsers([]))

        // console.log(node, 'node')
        // var flat_data = treeDataToFlat(state.treeData)
        // var flat_data = _.map(flat_data, 'node')
        // var flat_data = [...flat_data]
        // var getNodeidx = _.findIndex(flat_data, { id: node.id })
        // console.log(getNodeidx, 'getNodeidx')
        // if (getNodeidx !== -1) {
        //     flat_data[getNodeidx] = {
        //         ...flat_data[getNodeidx],
        //         unique_users: [],
        //         user_path: [],
        //         user_permission_acpln: []
        //     }
        // }
        // console.log(flat_data, 'flat_data')
        // var updatedTreedata = await getTreeFromFlatDataAsync(flat_data)
        // console.log(updatedTreedata, 'updatedTreedata')
        // dispatch(setnodeUsers([]))
        // dispatch(setTreeData(updatedTreedata));
        // saveTreeData(updatedTreedata, state.totalHLength,dispatch)
        // // setDropdownStates({})
        // setdropdownStatesEdit({});

    }


    const handleDeleteOne=(_id)=>{
        var nodeUsersInfo = _.cloneDeep(nodeUsers)

        console.log(nodeUsersInfo,'nodeUsersInfo');

        var selectedId = []
        nodeUsersInfo.map((ele,pos)=>{
            if(ele._id !==_id){
                selectedId.push(ele)
            }
        })
        console.log(selectedId,'selectedId');
        dispatch(addNodeUsers(selectedId,state.nodeInfo,"0"))
        setDropdownStates({})
        setdropdownStatesEdit({});
        dispatch(setnodeUsers([]))
        



    }

    const deleteNodedup = async (user_data, node, idx, mode) => {

        if (mode === "delete_all") {
            var filter_userpath = node.user_path.filter(ele => ele._id !== user_data._id)
            var filtered_unique_user = node.unique_users.filter(ele => ele._id !== user_data._id)
            var filtered_user_permission = node?.user_permission_acpln?.filter(ele => ele.user_id !== user_data._id)
            var node = { ...node }
            node["user_path"] = filter_userpath
            node["unique_users"] = filtered_unique_user
            node["user_permission_acpln"] = filtered_user_permission
            console.log(node, 'node')
            var flat_data = treeDataToFlat(state.treeData)
            var flat_data = _.map(flat_data, 'node')
            var flat_data = [...flat_data]
            var getNodeidx = _.findIndex(flat_data, { id: node.id })
            console.log(getNodeidx, 'getNodeidx')
            if (getNodeidx !== -1) {
                flat_data[getNodeidx] = {
                    ...flat_data[getNodeidx],
                    unique_users: node.unique_users,
                    user_path: node.user_path,
                    user_permission_acpln: node.user_permission_acpln
                }
            }
            console.log(flat_data, 'flat_data')
            var updatedTreedata = await getTreeFromFlatDataAsync(flat_data)
            console.log(updatedTreedata, 'updatedTreedata')
            dispatch(setTreeData(updatedTreedata));
            saveTreeData(updatedTreedata, state.totalHLength,dispatch)
            dispatch(setnodeUsers(node.unique_users))
        }
        setDataLoaded(false)

        setTimeout(() => {
        setDataLoaded(true)
            
        }, 200);

    }




    const listOutCategory = () => {
        console.log(state.treeData, 'state.treeData')
        var flatData = _.map(treeDataToFlat(state.treeData), item => {
            return item.node.subtitle
        })

        var uniqueHlevels = _.uniqBy(flatData)
        return uniqueHlevels
    }

    const handleTagClose = async (event, user_data, cat_data, cat_idx, node_index, h_name, node) => {
        console.log(user_data, cat_data, cat_idx, node_index, h_name, node, 'user_data, cat_data, cat_idx, node_index, h_name,node')
        var user_data = { ...user_data }
        var updatenodeUsers = _.cloneDeep(nodeUsers)
        var node = _.cloneDeep(node)

        var filter_cat_data = updatenodeUsers[node_index].cat_type.filter(ele => ele !== cat_data)
        var filter_h_data = updatenodeUsers[node_index].hirerachy_name.filter(ele => ele !== h_name)

        updatenodeUsers[node_index].cat_type = filter_cat_data
        updatenodeUsers[node_index].hirerachy_name = filter_h_data
        if (filter_cat_data.length == 0) {
            var updated_unique_userdata = node.unique_users.filter((ele, index) => index !== node_index);
            console.log(updated_unique_userdata,'updated_unique_userdata')
            node.unique_users = updated_unique_userdata
        }

        var update_node_data = []

        node.user_path.map((data, idx1) => {
            if ((data.cat_type == cat_data) && (data._id == user_data._id)) {

            }
            else {
                update_node_data.push(data)
            }
        })

        user_data.cat_type = user_data.cat_type.filter(type => type !== cat_data)
        user_data.hirerachy_name = user_data.hirerachy_name.filter(type => type !== h_name)

        var getuniqueUserIdx = _.findIndex(node.unique_users, { _id: user_data._id })
        if (getuniqueUserIdx !== -1) {
            node.unique_users[getuniqueUserIdx] = {
                ...node.unique_users[getuniqueUserIdx],
                hirerachy_name: user_data.hirerachy_name,
                cat_type: user_data.cat_type,
            }

        }


        var find_user_path_idx = _.findIndex(node.user_path, { _id: user_data._id, cat_type: cat_data });
        var flat_data = treeDataToFlat(state.treeData)
        var flat_data = _.map(flat_data, 'node')
        var flat_data = [...flat_data]
        var flat_data_idx = _.findIndex(flat_data, function (item) { return item.id == node.id })
        if (flat_data_idx !== -1) {
            flat_data[flat_data_idx] = {
                ...flat_data[flat_data_idx],
                unique_users: node.unique_users,
                user_path: update_node_data
            }
            console.log(flat_data, 'flat_data', node, user_data)
        }
        var getnodeIdx = _.findIndex(flat_data,{id : node.id })
        console.log(flat_data[getnodeIdx].unique_users,'flat_data[getnodeIdx].unique_users')
        var userIdx = _.findIndex(flat_data[getnodeIdx].unique_users,{_id : user_data._id})
        console.log(userIdx,'userIdx')
        if(userIdx === -1){
            var updatedInfo = flat_data[getnodeIdx].user_permission_acpln.filter((ele,i)=>{
                if(ele.user_id !== user_data._id){
                    return ele
                }
            })
            console.log(updatedInfo,'updatedInfo')
            flat_data[flat_data_idx].user_permission_acpln = updatedInfo
            // _.filter(flat_data[getnodeIdx].user_permission_acpln, {})
        }
        var updatedTreedata = await getTreeFromFlatDataAsync(flat_data)
        console.log(updatedTreedata, 'updatedTreedata')
        dispatch(setTreeData(updatedTreedata));
        saveTreeData(updatedTreedata, state.totalHLength,dispatch)
     
        if(getnodeIdx !==-1){
            dispatch(setnodeUsers(flat_data[getnodeIdx].unique_users))
        }
        setDataLoaded(false)

        setTimeout(() => {
        setDataLoaded(true)
            
        }, 200);
    }


    const gotoBack = () => {
        // history("/incd-hirchy")
    }

    const editCat = (data) => {
        console.log(data, 'data')
        setgetSubTitledd(data.value)
        seteditCatName(true)
        setcreateNewCat(true)
    }


    const validateNodeName=(event)=>{
        var validate_node = _.filter(state.treeData, { node_level: 0 })
        const formattedNameToValidate = event.target.value.replace(/\s/g, '').toLowerCase();
        const isNameValid = validate_node.some((name) =>
            name.title.replace(/\s/g, '').toLowerCase() === formattedNameToValidate
          );
          setinvalidTittle(isNameValid)
    }


    const validateSubmenuName=(e)=>{
        if(nodeInfo.children !== undefined){
            if(nodeInfo.children.length >0){
                var validate_node = nodeInfo.children.filter((e) => e.node_level == (nodeInfo.node_level + 1))
                const formattedNameToValidate = e.target.value.replace(/\s/g, '').toLowerCase();
    
                const isNameValid = validate_node.some((name) =>
                    name.title.replace(/\s/g, '').toLowerCase() === formattedNameToValidate
                );
                setinvalidTittle(isNameValid)
            }
            else{

                var flatData = treeDataToFlat(state.treeData)
                const filter_same_lvl_data = flatData.filter(item =>
                    item.node_level === nodeInfo.node_level && item.title !== nodeInfo.title
                  );
                  
                console.log(filter_same_lvl_data, 'filter_same_lvl_data')
    
                const formattedNameToValidate = e.target.value.replace(/\s/g, '').toLowerCase();
    
                const isNameValid = filter_same_lvl_data.some((name) =>
                    name.title.replace(/\s/g, '').toLowerCase() === formattedNameToValidate
                );
                setinvalidTittle(isNameValid)
            }
        }
    }

    const saveHStructure=async()=>{
        var flatData =await saveTreeData(state.treeData,state.totalHLength,dispatch)

    }

    const upDateCatName = (flatData, values) => {
        console.log(values,'values')
        return flatData.map((data) => {
          if (data.subtitle === nodeInfo.subtitle) {
            return {
              ...data,
              subtitle: values.subtitle
            };
          }
          return data;
        });
      }
      



    const saveAllnodes=async(event,values)=>{
        console.log(values)
        var flat_data = await treeDataToFlat(state.treeData)
        var flat_data = _.map(flat_data,'node')
       var getupdatedFlat = await upDateCatName(flat_data, values)
        var treeData = await getTreeFromFlatDataAsync(getupdatedFlat, true)
        console.log(getupdatedFlat,'getupdatedFlat',treeData)
        dispatch(setTreeData(treeData))
        saveData(getupdatedFlat,state.totalHLength,dispatch)
        setdropdownStatesEdit({})   
        dispatch(getHdata())

    }

    const truncateText = (text, length) => {
        if (text?.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    };



    const retriveUserInfo=async(nodeInfo)=>{
        console.log(nodeInfo,'nodeInfo')

        try {
          const responseData = await  post("incident-report/retrive-user-info",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                h_id : nodeInfo.h_id,
                node_id : nodeInfo.id,
            })
            console.log(responseData,'responseData');
            if(responseData.status === 200){
                setuserInfo(responseData.data.data)
            }


        } catch (error) {
            
        }


    }

    const drawerStyles = {
        width: '100%', // Default to full width for small screens
        maxWidth: '700px', // Maximum width for larger screens
    };


    useEffect(()=>{


    },[store.getState().IR_LocationSlice.locUserList])

    return (
        <React.Fragment>
            {dataLoaded ? (
                <Container fluid>
                    <Breadcrumbs
                        title={"Hirerachy / " + hInfo.hname}
                        breadcrumbItem="Hirerachy"
                        isBackButtonEnable={true}
                        // gotoBack={() => gotoBack()}

                    />
                    <Row className='mb-2'>
                        <Col className='text-end'>
                            <Link to={"/manage-location"}><button className='btn btn-outline-primary btn-sm'>Back</button></Link>
                        </Col>
                    </Row>


                    <div className="d-flex flex-row" style={{ position: "relative", width: "100%", minHeight: "100vh" }}>
                        <div style={{ background: "white", width: "100%", transition: "width .35s ease-in-out", position: "absolute", float: "left", }} className="p-3 me-2" >
                            <div className="row">
                                <div className="mb-2 col-10 ">
                                    <UncontrolledDropdown isOpen={state.mainToggle} toggle={toggle}>
                                        <DropdownToggle className="btn btn-primary" color="#eff2f7" onClick={() => { dispatch(setState({ path: [], crud: true, crudStatus: 0, type: 0, children: [], mainToggle: true })); setenableCode(false);setinvalidTittle(false) }} >
                                            <i className="mdi mdi-plus me-1 "></i> Create New
                                        </DropdownToggle>
                                        <DropdownMenu style={{ width: 250 }} className="">
                                            <div className="px-4">
                                                <AvForm onValidSubmit={addCrudNode}>
                                                    <div className="my-2">
                                                        <AvField name="title" label="Menu Name "
                                                            placeholder="Enter Menu" type="text" errorMessage="Enter Menu" validate={{ required: { value: true }, }}
                                                            defaultValue={""}
                                                            onChange={(e) => { dispatch(setMenuName(e.target.value));validateNodeName(e) }} />
                                                    </div>
                                                    {
                                                        invalidTittle &&
                                                        <div style={{ fontSize: 'smaller' }} className='text-danger'>Title name should not same</div>
                                                    }
                                                    <div className="mb-3">

                                                        <Label className="" htmlFor="autoSizingSelect">Category</Label>
                                                        {
                                                          
                                                            <AvInput
                                                                type="select"
                                                                name="subtitledd"
                                                                label="Name"
                                                                className="form-select"
                                                                id="cat"
                                                                ref={optionRef}
                                                                onChange={(e) => selectCat(e)}
                                                            >
                                                                <option value="0" defaultValue>Choose...</option>
                                                                <option value="1"  >Create New</option>
                                                                {
                                                                    catListInfo.map((data, idx) => {
                                                                        return (
                                                                            <option value={data} selected key={idx}>{data}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </AvInput>
                                                        }
                                                    </div>
                                                    {
                                                        createNewCat ?
                                                            <AvField
                                                                name="subtitle"
                                                                type="text"
                                                                required
                                                                placeholder="Enter New Category"
                                                            />
                                                            :
                                                            null
                                                    }

                                                    <div className="form-check">
                                                        <Input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="invalidCheck"
                                                            onChange={() => setenableCode((prevEnableCode) => !prevEnableCode)}
                                                        />
                                                        <Label
                                                            className="form-check-label"
                                                            htmlFor="invalidCheck"
                                                        >{" "}
                                                            Add Code
                                                        </Label>
                                                    </div>
                                                    {
                                                        enableCode &&
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="code"
                                                                label="Code"
                                                                type="text"
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                    },
                                                                }}
                                                                onChange={()=>{setcodeError('');setcodeErrorEnabled(false)}}
                                                                placeholder="Enter Code"
                                                            />
                                                            {
                                                                codeErrorEnabled &&
                                                                <Label className="text-danger" style={{ margin: '5px 0 7px 0' }} htmlFor="autoSizingSelect">{codeError}</Label> 
                                                            }
                                                        </div>
                                                    }


                                                    <div className="my-3">
                                                        <button disabled={invalidTittle} className="btn btn-primary btn-block m-1" type="submit" style={{ marginRight: 5 }}>
                                                            Add Main Level
                                                        </button>
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                               
                            </div>
                            <div style={{ overflow: "auto", height: "90vh", }}>

                                <SortableTree
                                    treeData={treeData}
                                    onChange={handleTreeChange}
                                    onMoveNode={(object) => dispatch(dndNode(object))}
                                    canDrop={(object) => object.nextParent !== undefined && object.nextParent.type == 2 ? false : true}
                                    scaffoldBlockPxWidth={40}
                                    slideRegionSize={25}
                                    generateNodeProps={({ node, path }) => {
                                        const updatedPath = [...path];
                                        return {
                                            listIndex: 0,
                                            lowerSiblingCounts: [],
                                            rowdirection: "rtl",
                                            canDrop: node.type == 2 ? false : true,
                                            className: "icon-drag",
                                            icon: (<i className="mdi mdi-folder-multiple-plus font-size-18" style={{ margin: 5 }} ></i>),
                                            onClick: (event) => {
                                                if (event.target.className.includes("collapseButton") || event.target.className.includes("expandButton")) {
                                                } else {
                                                    dispatch(getNodeData(node));
                                                }
                                            },
                                            style: {
                                                display: 'flex',
                                                border: '1px solid #c3cacd',
                                            },
                                            title: (
                                                <div>
                                                    <div style={{ maxWidth: 450 }} key={`div-${node.id}`}>
                                                        {state.editcrud && state.id === node.id ? (
                                                            <div>
                                                                <Tooltip
                                                                    placement="bottom"
                                                                    target={`btn-${node.id}`}
                                                                    toggle={() => toggleToolTip(`btn-${node.id}`)}
                                                                >
                                                                    {node.title}
                                                                </Tooltip>
                                                                <Link
                                                                    to="#"
                                                                    id={`btn-${node.id}`}
                                                                    style={{ fontSize: 12, fontWeight: 400 }}
                                                                >
                                                                    {String(node.title).slice(0, 40) +
                                                                        (node.title?.length > 40 ? "..." : "")}
                                                                </Link>
                                                            </div>
                                                          
                                                        ) : (
                                                            <div>
                                                                <Tooltip
                                                                    placement="bottom"
                                                                    target={`btn-${node.id}`}
                                                                    toggle={() => toggleToolTip(`btn-${node.id}`)}
                                                                >
                                                                    {node.title}
                                                                </Tooltip>
                                                                <Link
                                                                    to="#"
                                                                    id={`btn-${node.id}`}
                                                                    style={{ fontSize: 12, fontWeight: 400 }}
                                                                >
                                                                    {String(node.title).slice(0, 40) +
                                                                        (node.title?.length > 40 ? "..." : "")}
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ),
                                            subtitle: (
                                                <div style={{ height: '100%', width: '100%', marginTop: 4, marginLeft: node.type == 1 || node.type == 2 ? 28 : 0 }} >
                                                    {node.cat_type === undefined ? node.node_positon !== node.subtitle ? node.subtitle + " (" + node.node_positon + ")" : node.subtitle : node.cat_type == "1" ? "Auditer" : node.cat_type == "2" ? "Reviewer" : node.cat_type == "3" ? "External Auditer" : "NA"}
                                                </div>),
                                            buttons: [
                                                <Row className="" key={node.id}>
                                                    <ul className="list-unstyled hstack gap-1 mb-0 justify-content-end">
                                                        <UncontrolledDropdown direction="end" isOpen={dropdownStatesEdit[node.id]} toggle={() => toggleDropdownEdit(node.id)}>
                                                            <DropdownToggle className="card-drop" tag="a">
                                                                <Link to="#" className="btn btn-sm btn-soft-primary" id={`viewtooltips-${node.id}`} onClick={() => { dispatch(editNode(updatedPath, node)); dispatch(setnodeInfo(node)); setenableCode(false) }}>
                                                                    <i className="mdi mdi-pencil-outline" />
                                                                    <UncontrolledTooltip placement="top" target={`viewtooltips-${node.id}`}>
                                                                        Edit Node
                                                                    </UncontrolledTooltip>
                                                                </Link>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-end" style={{ width: 220 }} id={"dp" + String(node.id)}>
                                                                <div className="px-4">
                                                                    <div className="mt-1" style={{ display: 'inline-flex' }}>Edit Node Under</div>
                                                                    <div title={state.getNodeInfo?.title} className="font-size-13 text-primary mt-2 px-2 py-0" style={{ display: 'inline-flex', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', cursor: 'pointer' }}>
                                                                        {truncateText(state.getNodeInfo?.title || '', 6)}
                                                                    </div>
                                                                    <div className="font-size-11 text-dark" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', cursor: 'pointer' }}>
                                                                        {truncateText(state.getNodeInfo?.subtitle || '', 6)}
                                                                    </div>
                                                                    <AvForm onValidSubmit={editCatName ? saveAllnodes : addCrudNode} >
                                                                        <div className="my-2">
                                                                            <AvField
                                                                                name="title"
                                                                                label="Name"
                                                                                type="text"
                                                                                required
                                                                                placeholder="Enter Name"
                                                                                value={nodeInfo?.title}
                                                                            />
                                                                            <div className='my-2'>
                                                                                <Label className="" htmlFor="autoSizingSelect">Category</Label>
                                                                                <Select
                                                                                    options={catListInfo.map((data, idx) => ({
                                                                                        label: (
                                                                                            <div>
                                                                                                {truncateText(data, 6)}
                                                                                                <i className="mdi mdi-pencil font-size-12 text-primary me-5"></i>
                                                                                            </div>
                                                                                        ),
                                                                                        value: data,
                                                                                    }))}
                                                                                    value={catListInfo.map(data => ({ label: data, value: data })).find((option) => option.value === nodeInfo?.subtitle)}
                                                                                    onChange={(e) => editCat(e)}
                                                                                />

                                                                                {createNewCat &&
                                                                                    <AvField
                                                                                        name="subtitle"
                                                                                        type="text"
                                                                                        value={getSubTitledd}
                                                                                        placeholder="Enter New Category"
                                                                                        required
                                                                                    />
                                                                                }
                                                                            </div>

                                                                            <div className="form-check">
                                                                                <Input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id="invalidCheck"
                                                                                    onChange={() => setenableCode((prevEnableCode) => !prevEnableCode)}
                                                                                    defaultChecked={nodeInfo?.code !== undefined ? true : false}
                                                                                />
                                                                                <Label className="form-check-label" htmlFor="invalidCheck">
                                                                                    {" "}Add Code
                                                                                </Label>
                                                                            </div>
                                                                            {(enableCode || nodeInfo?.code) &&
                                                                                <div className="mb-3">
                                                                                    <AvField
                                                                                        name="code"
                                                                                        label="Code"
                                                                                        type="text"
                                                                                        value={nodeInfo?.code}
                                                                                        validate={{
                                                                                            required: { value: true, errorMessage: "Enter Code" },
                                                                                        }}
                                                                                        placeholder="Enter Code"
                                                                                        onChange={() => { setcodeError(''); setcodeErrorEnabled(false) }}
                                                                                    />
                                                                                    {codeErrorEnabled &&
                                                                                        <Label className="text-danger" style={{ margin: '5px 0 7px 0' }} htmlFor="autoSizingSelect">{codeError}</Label>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="my-3">
                                                                            <button className="btn btn-primary btn-sm btn-block m-1" type="submit" style={{ marginRight: 5 }}>
                                                                                Edit
                                                                            </button>
                                                                        </div>
                                                                    </AvForm>
                                                                </div>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        {node.type === 0 && (
                                                            <UncontrolledDropdown direction="end" isOpen={dropdownStates[node.id]} toggle={() => toggleDropdown(node.id)}>
                                                                <DropdownToggle className="card-drop" tag="a">
                                                                    <Link to="#" className="btn btn-sm btn-soft-primary" id={`viewtooltip-${node.id}`} onClick={() => { dispatch(addNode(node, updatedPath, 0)); dispatch(setnodeInfo(node)); setenableCode(false);setinvalidTittle(false) }} >
                                                                        <i className="mdi mdi-file-tree" />
                                                                        <UncontrolledTooltip placement="top" target={`viewtooltip-${node.id}`}>
                                                                            Add Submenu
                                                                        </UncontrolledTooltip>
                                                                    </Link>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end " style={{ width: 220 }} id={"dp" + String(node.id)}>
                                                                    <div className="px-4">
                                                                        <div className="mt-1" style={{ display: 'inline-flex' }}>Add Node Under</div>
                                                                        <div title={nodeInfo?.title} className="font-size-13 text-primary mt-2 px-2 py-0" style={{ display: 'inline-flex', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', cursor: 'pointer' }}>
                                                                            {truncateText(nodeInfo?.title || '', 6)}
                                                                        </div>
                                                                        <div title={nodeInfo?.subtitle} className="font-size-11 text-dark" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', cursor: 'pointer' }}>
                                                                            {truncateText(nodeInfo?.subtitle || '', 6)}
                                                                        </div>
                                                                        <AvForm onValidSubmit={addCrudNode} >
                                                                            <div className="my-2">
                                                                                <AvField
                                                                                    name="title"
                                                                                    label="Name"
                                                                                    type="text"
                                                                                    required
                                                                                    placeholder="Enter Name"
                                                                                    onChange={(e)=>validateSubmenuName(e)}
                                                                                />
                                                                                {
                                                                                    invalidTittle &&
                                                                                    <div style={{ fontSize: 'smaller',width:"auto" }} className='text-danger'>Title name should not same for same level of node</div>
                                                                                }
                                                                                {
                                                                                    state.editcrud ?
                                                                                        <>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <div className="mb-3">
                                                                                                <Label className="" htmlFor="autoSizingSelect">Category</Label>
                                                                                                <AvInput
                                                                                                    type="select"
                                                                                                    name="subtitledd"
                                                                                                    label="Name"
                                                                                                    className="form-select"
                                                                                                    id="cat"
                                                                                                    ref={optionRef}
                                                                                                    onChange={(e) => selectCat(e)}
                                                                                                >
                                                                                                    <option value="0" defaultValue>Choose...</option>
                                                                                                    <option value="1" selected >Create New</option>
                                                                                                    {
                                                                                                        catListInfo.map((data, idx) => {
                                                                                                            return (
                                                                                                                <option value={data} selected key={idx}>{data}</option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </AvInput>
                                                                                            </div>
                                                                                            {
                                                                                                createNewCat ?
                                                                                                    <AvField
                                                                                                        name="subtitle"
                                                                                                        type="text"
                                                                                                        placeholder="Enter New Category"
                                                                                                        required
                                                                                                    />
                                                                                                    :
                                                                                                    null
                                                                                            }

                                                                                        </>

                                                                                }


                                                                                <div className="form-check">
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        id="invalidCheck"
                                                                                        onChange={() => setenableCode((prevEnableCode) => !prevEnableCode)}
                                                                                    />
                                                                                    <Label
                                                                                        className="form-check-label"
                                                                                        htmlFor="invalidCheck"
                                                                                    >{" "}
                                                                                        Add Code
                                                                                    </Label>
                                                                                </div>
                                                                                {
                                                                                    enableCode ?
                                                                                        <div className="mb-3">
                                                                                            <AvField
                                                                                                name="code"
                                                                                                label="Code"
                                                                                                type="text"
                                                                                                validate={{
                                                                                                    required: { value: true, errorMessage: "Enter Code" },
                                                                                                }}
                                                                                                onChange={() => { setcodeError(''); setcodeErrorEnabled(false) }}
                                                                                                placeholder="Enter Code"
                                                                                            />
                                                                                         {
                                                                                            codeErrorEnabled &&
                                                                                            <Label className="text-danger" style={{ margin: '5px 0 7px 0' }} htmlFor="autoSizingSelect">{codeError}</Label>
                                                                                        }
                                                                                        </div> : null
                                                                                }
                                                                            </div>
                                                                            <div className="my-3">
                                                                                <button className="btn btn-primary btn-sm btn-block m-1" type="submit" style={{ marginRight: 5 }}>
                                                                                Add Submenu
                                                                                </button>
                                                                                <button className='btn btn-danger btn-sm' onClick={()=>toggleDropdown(node.id)} type="submit" style={{ marginRight: 5 }}>
                                                                                    Close
                                                                                </button>
                                                                            </div>
                                                                        </AvForm>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        )}

                                                        <li>
                                                            <Popconfirm
                                                                description="Are you sure you want to change delete this?"
                                                                title="Warning"
                                                                okText="Yes"
                                                                cancelText="No"
                                                                onConfirm={() => {
                                                                    dispatch(deleteNode(node, path, "udp" + String(node.id), "dp" + String(node.id)))
                                                                }}
                                                                zIndex={10000}
                                                            >
                                                            <Link
                                                                to="#"
                                                                className="btn btn-sm btn-soft-danger"
                                                                id={`deletetooltip-${node.id}`}
                                                            >
                                                                <i className="mdi mdi-delete-outline" />
                                                                <UncontrolledTooltip placement="top" target={`deletetooltip-${node.id}`}>
                                                                    {node.type !== 2 && "Delete Menu"}
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                            </Popconfirm>
                                                        </li>
                                                        <div key={""}>
                                                            <UncontrolledDropdown >
                                                                <DropdownToggle
                                                                    className="card-drop"
                                                                    tag="a"
                                                                    onClick={() => { setviewUsers(true); dispatch(setnodeInfo(node));
                                                                        dispatch(retriveNodeUsers({
                                                                            id:node.id,
                                                                            h_id:node.h_id,
                                                                        }))
                                                                        // retriveUserInfo(node);
                                                                        // dispatch(setnodeUsers(node.unique_users))
                                                                        
                                                                    
                                                                    }}
                                                                >
                                                                    <Space size="middle">
                                                                        <Badge count={node?.unique_users !== undefined ? node.unique_users.length > 0 ? node.unique_users.length : 0 : 0} color="#556EE6">
                                                                            <i className="bx bx-user-pin font-size-20" title={"Users"} style={{ marginTop: '4px', marginLeft: '5px' }}
                                                                            ></i>
                                                                        </Badge>
                                                                    </Space>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="ms-4 " style={{ minWidth: "500px", zIndex: 2, padding: 10, marginLeft: '13px', marginTop: '-15px' }}>
                                                                    {viewUsers ? (
                                                                        <div className="mt-2">
                                                                            <div className="mt-2">
                                                                                Users of&nbsp;
                                                                                <span className="font-size-13 text-primary mt-2">
                                                                                    {nodeInfo.title}
                                                                                </span>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    Total Users:&nbsp;
                                                                                    <span className="font-size-13 text-danger mt-2">
                                                                                        {nodeUsers?.length == undefined ? 0 : nodeUsers?.length}
                                                                                    </span>
                                                                                    <div className="mt-2">
                                                                                        <button className="btn btn-primary btn-sm"
                                                                                            onClick={async() => {
                                                                                                await dispatch(setnodeInfo(node))
                                                                                               await dispatch(retriveUserList("1"))
                                                                                               await dispatch(setShowUserListDrawer(true))

                                                                                                // addNodeUser(treeData, node, 2, history, null, state.path);
                                                                                            }}
                                                                                        >Add User</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 text-end">
                                                                                <button className="btn btn-danger btn-sm"
                                                                                    onClick={() =>
                                                                                        deleteAllUsers(node)}
                                                                                >Delete All Users</button>
                                                                            </div>
                                                                            {
                                                                                console.log(state.nodeUsers,'state.nodeUsers?')
                                                                            }

                                                                            <div className="table-responsive mt-3" style={{ maxHeight: "300px" }}>
                                                                                <table className="table custom-table-style">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>S.No</th>
                                                                                            <th>Name</th>
                                                                                            <th>Role Name </th>
                                                                                            {/* <th>User Level</th> */}
                                                                                            <th>Action</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            state.nodeUsers?.map((ele,idx)=>{
                                                                                                return(
                                                                                                    <tr key={idx}>
                                                                                                         <td>{idx + 1}</td>
                                                                                                         <td>{ele.firstname}</td>
                                                                                                         <td>{ele.role}</td>
                                                                                                         <td>
                                                                                                            <i onClick={()=>{
                                                                                                                handleDeleteOne(ele._id)
                                                                                                            }} className='mdi mdi-delete font-size-18' style={{margin:"5px",cursor:"pointer"}}/>
                                                                                                            {/* {ele.role_name} */}
                                                                                                            </td>                                                                                                       
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}

                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </ul>
                                                </Row>
                                            ],
                                        };
                                    }}
                                />
                            </div>
                            {
                                console.log(state,'state.showUserListDrawer')
                            }
                            {/* <Drawer
                                open={true}
                                onClose={() => { dispatch(setShowUserListDrawer(false)) }}
                                direction="right"
                                size={'700px'}
                                zIndex={9999}
                                noOverlay={false}
                                style={drawerStyles}
                            >
                                <div
                                    style={{
                                        padding: 10,
                                        maxHeight: '100vh',
                                        overflow: "auto",
                                    }}
                                >
                          
                               <UserListForm
                               onClose={() => {
                                dispatch(setShowUserListDrawer(false))
                                }}
                               />

                            </div>
                            </Drawer> */}
                             <Offcanvas
                              direction="end"
                              zIndex={9999}
                              style={{width:"50%"}}
                             isOpen={state.showUserListDrawer} toggle={()=>{dispatch(setShowUserListDrawer(!state.showUserListDrawer))}}>
                                            <OffcanvasHeader toggle={()=>{dispatch(setShowUserListDrawer(!state.showUserListDrawer))}}>
                                                User List
                                            </OffcanvasHeader>
                                            <OffcanvasBody>
                                    <UserListForm
                                        onClose={() => {
                                            dispatch(setShowUserListDrawer(false))
                                        }}
                                        pageLimit={false}
                                    />
                                                {/* <UncontrolledDropdown className="mt-3">
                                                    <DropdownToggle color="primary" type="button" id="dropdownMenuButton">
                                                        Dropdown button <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <li><DropdownItem>Action</DropdownItem></li>
                                                        <li><DropdownItem>Another action</DropdownItem></li>
                                                        <li><DropdownItem>Something else here</DropdownItem></li>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown> */}
                                            </OffcanvasBody>
                                        </Offcanvas>


                        </div>
                    </div>
                    <footer
                        style={{
                            display: 'flex',
                            alignItems: "center",
                            height: 70,
                            background: "#fff",
                            width: "100%",
                            position: "fixed",
                            bottom: 0,
                            zIndex: 9998,
                            borderTop: "1px solid #dedede"
                        }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center", paddingLeft: 20 }}>
                            {
                                treeData.length > 0 &&
                                <>
                                    <div>
                                        <button
                                            className="btn btn-danger btn-block m-1"
                                            type="submit"
                                        onClick={() => saveHStructure()}
                                        disabled={state.publishInfo.saving}
                                        >
                                            {state.publishInfo.saving ? "Saving" : "Save"}
                                        </button>
                                    </div>
                                    <div style={{ marginRight: 10 }}>
                                        <button
                                            className="btn btn-success btn-block m-1"
                                            type="submit"
                                            onClick={() => publishHStructure(treeData, dispatch)}
                                        >
                                            Publish
                                        </button>
                                    </div>
                                </>
                            }


                            <div>
                                <UncontrolledAlert
                                    color="success"
                                    className="alert-dismissible fade show"
                                    role="alert"
                                    isOpen={state.publishInfo.successmsgshow}
                                >
                                    <i className="mdi mdi-check-all me-2"></i>{state.publishInfo.alertMsg}
                                </UncontrolledAlert>
                            </div>
                        </div>
                    </footer>
                </Container>
            ) : (
                <div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div className="spinner-border text-secondary m-1" role="status"> </div>
                        <div>Loading, Please wait.</div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default TreeStructure;