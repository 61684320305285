import React, { useEffect, useState } from 'react'
import { CardBody, Container, Row, Col, Card, Form, Input, Label, FormFeedback, Spinner } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useNavigate } from 'react-router-dom'
import { useFormik } from "formik";
import * as Yup from "yup";
import { retriveRoleData, createUserInfo, retriveUserInfo } from 'toolkitStore/IncidentReport/IR_UserSlice';
import { useSelector, useDispatch } from 'react-redux';
import { IRTvalidateDupName } from 'toolkitStore/IncidentReport/IR_OrgSlice';
import { setUserMailIdExist, setUserNumExist, setEditUserInfo,getLocInfo } from 'toolkitStore/IncidentReport/IR_UserSlice';
// import { FileOutlined, DeleteTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import {Tree} from 'antd'
import store from 'store';
import Multiselect from 'multiselect-react-dropdown';


const IRAddUser = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const authUser = JSON.parse(sessionStorage.getItem("authUser"))
  const [clientInfo, setClientInfo] = useState(JSON.parse(sessionStorage.getItem("client_info")))[0]
  const IR_UserSlice = useSelector(state => state.IR_UserSlice)
  const [userInfo, setUserInfo] = useState(null)
  const [enabelIncdType, setEnableIncdType] = useState(false)
  const [dataLoaded, setdataLoaded] = useState(sessionStorage.getItem("userId") ? false : true)
  const [submitLoad, setSubmitLoad] = useState(false)
  const [multiTreeNode, setmultiTreeNode] = useState([])
  const [checkedKeys,setcheckedKeys] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [nodeInfo,setNodeInfo]= useState([])
  const [selectedLocation, setSelectedLocation] = useState(false)


  // const [enableHierarchy,setenableHierarchy] = useState(false)
  console.log(IR_UserSlice,'IR_UserSlice')

  const validation = useFormik({
    initialValues: {
      firstname: "" || IR_UserSlice.editUserInfo?.firstname,
      email_id: "" || IR_UserSlice.editUserInfo?.email_id,
      countrycode: "" || IR_UserSlice.editUserInfo?.countrycode ,
      phone_number: ""|| IR_UserSlice.editUserInfo?.phone_number ,
      role: ""|| IR_UserSlice.editUserInfo?.role_id ,
      branch: "" || IR_UserSlice.editUserInfo?.branch_id,
      department: "" || IR_UserSlice.editUserInfo?.department_id,
      designation: "" || IR_UserSlice.editUserInfo?.designation,
    },
      
validationSchema: Yup.object({
  firstname: Yup.string().required("Name is required")
  .matches(/\S+/, "Name cannot be just spaces")
  .min(clientInfo.incd_rpt_validation.name_min, `Name must be at least ${clientInfo.incd_rpt_validation.name_min} characters`)
  
  ,
  role: Yup.string().required("Role name is required"),
  email_id: Yup.string().email("Invalid email format")
  .test(async function (value) {
    return !(await dispatch(IRTvalidateDupName(store.getState().IR_UserSlice.editUserInfo, "cln_adt_users", "email_id", value, setUserMailIdExist)));
  }),
  countrycode: Yup.string().test('countrycode-required', 'Country code is required if phone number is provided', function (value) {
    const { phone_number } = this.parent;
    if (phone_number && phone_number.length > 0) {
      return !!value && value !== 'Select';
    }
    return true;
  }
  ),
  phone_number: Yup.string().test('phone-required', 'Phone number is required if country code is provided', function (value) {
    const { countrycode } = this.parent;
    if (countrycode && countrycode !== 'Select') {
      return !!value ;
    }
    return true;
  }
  ).test(async function (value) {
    console.log(value,'value')
    return !(await dispatch(IRTvalidateDupName(store.getState().IR_UserSlice.editUserInfo, "cln_adt_users", "phone_number", value, setUserNumExist)));
  }),
  branch: Yup.string().required("Branch is Required"),
  department: Yup.string().required("Department is Required"),
  designation: Yup.string().nullable() 
  .notRequired()
  .test(
    'description-validation',
    `Designation must be at least ${clientInfo.incd_rpt_validation.name_min} characters and cannot be just spaces`,
    function (value) {
        if (!value) return true; // If no input, validation passes
        const trimmedValue = value.trim();
        return trimmedValue.length >= clientInfo.incd_rpt_validation.name_min; // Applies validation only when input exists
    }
),
}),
    onSubmit: async(values) => {
      var values = _.cloneDeep(values)
      if(checkedKeys.length >0){
        setSelectedLocation(false)
      setSubmitLoad(true)
      if (!values.phone_number && !values.email_id) {
        validation.setErrors({
          phone_number: "Enter either Phone number or Email.",
          email_id: "Enter either Phone number or Email.",
        });
        return; 
    }
      console.log(values, 'values',userInfo,authUser);

      values["username"]=values["email_id"]
      values["created_by"]=authUser.user_data._id
      values["clientId"]=authUser.client_info[0].clientId
      values["userPoolId"]=authUser.client_info[0].userPoolId
      values["fullNo"]=values.countrycode + values.phone_number
      var roleInfo = _.filter(IR_UserSlice.roleList,{_id : values.role})
      var branchInfo = _.filter(IR_UserSlice.branchData,{_id : values.branch})
      var deptInfo = _.filter(IR_UserSlice.deptData,{_id : values.department})
      console.log(roleInfo,'roleInfo');
      if(branchInfo.length >0){
        values["branch"]= branchInfo[0]["branch_name"]
        values["branch_id"]= branchInfo[0]["_id"]
      }
      if(deptInfo.length >0){
        values["department"]= deptInfo[0]["dept_name"]
        values["department_id"]= deptInfo[0]["_id"]
        
      }
      if(roleInfo.length>0){
      values["role"]= roleInfo[0]["role_name"]
      values["role_id"]= roleInfo[0]["_id"]
      }
      if(IR_UserSlice.editUserInfo !== null){
        values["_id"] = IR_UserSlice.editUserInfo._id
        if(IR_UserSlice.editUserInfo.phone_number === null && values.phone_number){
          values["update"]="phone_number"
        }
        else if(IR_UserSlice.editUserInfo.email_id === null && values.email_id){
          values["update"]="email"
        }
      }
      console.log('IR_UserSlice.editUserInfo,values',checkedKeys);
      if(checkedKeys.length > 0){
        var locInfo =[]
        multiTreeNode[0].locationInfo.map((ele, pos) => {
          if (checkedKeys.includes(ele._id)) {
            locInfo.push({
              node_id:ele.id,
              node_name:ele.hlevel_name,
              node_hlevel : ele.hlevel,
              node_hid : ele.h_id,
            })
          }
        })
        console.log(locInfo,'locInfo')
        values["location"]=locInfo
      }

       await dispatch(createUserInfo(values,navigate))
       setSubmitLoad(false)
      }
      else{
        setSelectedLocation(true)

      }


    }
  })









  useEffect(() => {
    const initialize = async () => {
      const userId = sessionStorage.getItem("userId");
      document.title = userId ? "Edit User | Incidentum" : "Create User | Incidentum";
      
      // Dispatch role data retrieval
      await dispatch(retriveRoleData());
      
      // Retrieve user information if userId exists
      if (userId) {
        const userInfo = await dispatch(retriveUserInfo(userId));
        
        if (userInfo.length > 0) {
          const user = userInfo[0];
          setUserInfo(user);
          dispatch(setEditUserInfo(user));
          validation.setValues(user);
  
          if (user.h_id?.length > 0) {
            const h_id = user.h_id[0];
            const LocationInfo = await dispatch(getLocInfo(h_id));
            
            const treeInfo = convertFlatDataToTreeData(LocationInfo); // Assuming this is synchronous
            const treeNodes = buildTreeNodes(treeInfo); // Assuming this is synchronous
            const keys = getAllKeys(treeNodes); // Assuming this is synchronous
  
            let checkedInfo = [];
            if (user.location?.length > 0) {
              const locationIds = _.map(user.location, "node_id");
              checkedInfo = LocationInfo.filter(loc => locationIds.includes(loc.id)).map(loc => loc._id);
            }
  
            const info = {
              h_id,
              treeNode: treeNodes,
              expandedKeys: keys,
              locationInfo: LocationInfo,
              checkedKeys: checkedInfo,
            };
            setcheckedKeys(checkedInfo)
            setmultiTreeNode([info]);
            setExpandedKeys(keys);
            setdataLoaded(true);
          }
        }
      } else {
        const { IR_UserSlice } = store.getState();
        const HData = IR_UserSlice.HData;
        
        if (HData.length > 0 && HData.length < 2) {
          const hDataId = HData[0]._id;
          const LocationInfo = await dispatch(getLocInfo(hDataId));
  
          const treeInfo = convertFlatDataToTreeData(LocationInfo); // Assuming this is synchronous
          const treeNodes = buildTreeNodes(treeInfo); // Assuming this is synchronous
          const keys = getAllKeys(treeNodes); // Assuming this is synchronous
  
          const info = {
            h_name: HData[0].hname,
            h_id: hDataId,
            treeNode: treeNodes,
            expandedKeys: keys,
            locationInfo: LocationInfo,
          };
  
          setmultiTreeNode([info]);
          setdataLoaded(true);
        }
      }
    };
  
    initialize();
  }, []);

  // useEffect(() => {
  //   const initialize = async () => {
  //     const userId = sessionStorage.getItem("userId");
  //     document.title = userId ? "Edit User | Incidentum" : "Create User | Incidentum";
  //     await dispatch(retriveRoleData())
  //     if (userId) {
  //       var userInfo = await dispatch(retriveUserInfo(userId))
  //       console.log(userInfo,'userInfo');
  //       if (userInfo.length > 0) {
  //         setUserInfo(userInfo[0])
  //         dispatch(setEditUserInfo(userInfo[0]))
  //         validation.setValues(userInfo[0])
  //         console.log(userInfo.h_id,'userInfo.h_id')
  //         if(userInfo[0].h_id?.length >0){
  //           var LocationInfo= await dispatch(getLocInfo(userInfo[0].h_id[0]))
  //           console.log(LocationInfo,'LocationInfo')
  //           var treeInfo = await convertFlatDataToTreeData(LocationInfo)
  //           var treeNodes =  await buildTreeNodes(treeInfo)
  //           var keys =  await getAllKeys(treeNodes)
           
  
  //           console.log(info,'LocationInfo')
  //           if(userInfo[0].location.length >0){
  //             var locationId = _.map(userInfo[0].location,"node_id")
  //             var checkedInfo =[]
  //             LocationInfo.map((ele, pos) => {
  //               if (locationId.includes(ele.id)) {
  //                 checkedInfo.push(ele._id)
  //               }
  //             })
  //             var info ={
  //               h_id : userInfo[0].h_id[0],
  //               treeNode : treeNodes,
  //               expandedKeys:keys,
  //               locationInfo : LocationInfo,
  //               checkedKeys : checkedInfo
  //             }
  //             setmultiTreeNode(info)
  //           setExpandedKeys(keys)

  //           }

  //           setmultiTreeNode([info])
  //           setdataLoaded(true)

  //         }

  //     }
  //   }
  //     else{
  //       console.log(IR_UserSlice,'IR_UserSlice',store.getState());
  //       if(store.getState().IR_UserSlice.HData.length >0 &&store.getState().IR_UserSlice.HData.length < 2){
  //         var LocationInfo= await dispatch(getLocInfo(store.getState().IR_UserSlice.HData[0]._id))
  //         var treeInfo = await convertFlatDataToTreeData(LocationInfo)
  //         var treeNodes =  await buildTreeNodes(treeInfo)
  //         var keys =  await getAllKeys(treeNodes)
  //         var info ={
  //           h_name : _.filter(store.getState().IR_UserSlice.HData,{_id : store.getState().IR_UserSlice.HData[0]._id})[0].hname,
  //           h_id : _.filter(store.getState().IR_UserSlice.HData,{_id : store.getState().IR_UserSlice.HData[0]._id})[0]._id,
  //           treeNode : treeNodes,
  //           expandedKeys:keys,
  //           locationInfo : LocationInfo
  //         }

  //         console.log(info,'LocationInfo')
  //         setmultiTreeNode([info])
  //        setdataLoaded(true)

  //       }
  //     }
  //   };

  //   initialize();
  // }, [])


  const getAllKeys = (nodes) => {
    let keys = [];
    nodes.forEach((node) => {
        keys.push(node.key);
        if (node.children) {
            keys = keys.concat(getAllKeys(node.children));
        }
    });
    return keys;
};



  const buildTreeNodes=(nodes)=>{
    return nodes.map((node) => {
      const { value, label, children, ep_level,code, ep_level_value, id, node_id,user_permission_acpln, parent, user_path,unique_users } = node;
      const hasChildren =  (children && children.length > 0) ;
      return {
        key: value,
        label: label,
        user_path: user_path,
        unique_users:unique_users,
        value,
        ep_level,
        ep_level_value,
        id,
        node_id,
        code:code,
        parent,
        children: hasChildren ? buildTreeNodes(children) : [],
        user_permission_acpln : user_permission_acpln,

        title: (
          <div // Use a div container for the entire node
            onClick={() => {
              // this.setState({ selectedKey: value });
            }
          } // Handle node click
            style={{
              position: 'relative',
              zIndex: 'auto',
              minHeight: '24px',
              margin: '0',
              padding: '0 4px',
              color: 'inherit',
              lineHeight: '24px',
              borderRadius: '6px',
              cursor: 'pointer',
              transition: 'all 0.2s, border 0s, line - height 0s, box - shadow 0s'
            }}
          >

              {/* <FileOutlined
                style={{
                  cursor: 'pointer',
                  color: '#556EE6',
                  opacity: 1,
                }}
              /> */}
             <span style={{ marginLeft: '4px', opacity: 1, fontWeight: 600 }}>
              {label}
            </span>
           
          </div>
        ),

      };
    });




  }



  const convertFlatDataToTreeData = (arr) => {

    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    console.log(arr,'arr')
    for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = {
            "value": arr[i]._id,
            "label": arr[i].hlevel_name,
            "children": arr[i].children,
            "id": arr[i].id,
            "parent": arr[i].parent,
            "node_id": arr[i]._id,
            "ep_level": arr[i].hlevel,
            "ep_level_value": arr[i].hlevel_value,
            "user_path": arr[i].user_path,
            "unique_users": arr[i].unique_users,
            "node_positon":arr[i].node_positon,
            "user_permission_acpln" : arr[i].user_permission_acpln,
            "code" : arr[i].hlevel_code ,
            "company_id" : arr[i].company_id ,
            "h_id":arr[i].h_id ,
            "_id":arr[i].h_id
          }

      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['children'] = []
    }
    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        if (mappedElem.parent) {
          if (mappedArr[mappedElem['parent']] !== undefined) {
            mappedArr[mappedElem['parent']]['children'].push(mappedElem);
          }
        }
        else {
          tree.push(mappedElem);
        }
      }
    }

    let update = () => obj => {
      if (obj.children.length > 0)
        obj.children.forEach(update());
      else
        obj.children = null
    };
    tree.forEach(update());
    return tree;
  }




  const handleChangeRole=(event)=>{
    validation.handleChange(event)
    var roleInfo = _.filter(IR_UserSlice.roleList,{_id : event.target.value})
    console.log(roleInfo,'roleInfo');
    if(roleInfo.length >0){
      var getIncidentManage = _.find(roleInfo[0].facilities,{id : 11}) 
      if(getIncidentManage !== undefined){
        setEnableIncdType(getIncidentManage.active)
      }
    }

  }


  const selectHierarchy=async(event)=>{
    var HData = _.cloneDeep(IR_UserSlice.HData)
    var hInfo = _.filter(HData,{_id : event.target.value})
    if (hInfo.length > 0) {
      var LocationInfo= await dispatch(getLocInfo(hInfo[0]._id))
      var treeInfo = await convertFlatDataToTreeData(LocationInfo)
      var treeNodes =  await buildTreeNodes(treeInfo)
      var keys =  await getAllKeys(treeNodes)
      var info ={
        h_name : hInfo[0].hname,
        h_id : hInfo[0]._id,
        treeNode : treeNodes,
        expandedKeys:keys,
        locationInfo : LocationInfo
      }

      console.log(info,'LocationInfo')
      setExpandedKeys(keys)
      setmultiTreeNode([info])
    }

  }


  const getAllChildKeys = (node) => {
    const keys = [];
    const traverse = (n) => {
      keys.push(n.key);
      if (n.children) {
        n.children.forEach(traverse);
      }
    };
    traverse(node);
    return keys;
  };

  const handleExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
};





if(dataLoaded){
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Add User" breadcrumbItem="Add User" />
          <Card>
            <CardBody>
              <Row>
                <Col md={12} className='text-end'>
                  <button className='btn btn-outline-secondary btn-sm' onClick={() => {
                    sessionStorage.removeItem("userId")
                    navigate("/manage-users");
                    dispatch(setEditUserInfo(null))
                  }}>Back</button>
                </Col>
              </Row>
              <Form 
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                }}
              >
                <Row className="my-4">
                  <div className="text-primary font-size-15">User Information</div>
                  <hr className="my-2 border-secondary opacity-50" />
                </Row>
              <Row>
                <Col md={4}>
                <Label className="form-label">Full Name :<span className='text-danger'>*</span></Label>
                    <Input
                      name={"firstname"}
                      type={"text"}
                      placeholder={"Enter the Full Name"}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstname || ""}
                      invalid={validation.touched.firstname && validation.errors.firstname ? true : false}
                    />
                    {validation.touched.firstname && validation.errors.firstname ? (
                      <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                    ) : null}
                </Col>
                <Col md={4}>
                <Label className="form-label">Email ID :<span className='text-danger'>*</span></Label>
                    <Input
                      name={"email_id"}
                      type={"text"}
                      placeholder={"Enter the Email ID"}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email_id || ""}
                      invalid={validation.touched.email_id && validation.errors.email_id ? true : false}
                      disabled={userInfo?.email_id}
                    />
                    {validation.touched.email_id && validation.errors.email_id ? (
                      <FormFeedback type="invalid">{validation.errors.email_id}</FormFeedback>
                    ) : null}
                    {
                      IR_UserSlice.userMailIdExist &&
                      <div style={{ fontSize: "smaller" }} className='text-danger'>Email ID already exist.</div>
                    }
                  </Col>
                </Row>
                <br />


                <Row className="align-items-center">
                  <Col md={12}>
                    <Row className="flex-wrap">
                    <Col md={2} className="pe-2">
                        <label>Phone Number: <span className="text-danger"> *</span></label>
                        <Row>
                          <Col md={12} className="d-flex flex-column"> 
                            <select
                              name="countrycode"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              className={`form-select ${validation.touched.countrycode && validation.errors.countrycode ? 'is-invalid' : ''}`}
                              defaultValue={IR_UserSlice.editUserInfo?.countrycode ? IR_UserSlice.editUserInfo?.countrycode: "Select"}
                              required
                            >
                              <option value="Select" disabled={true}>Select</option>
                              {
                                clientInfo.countries.map((c, idx) => (
                                  <option key={idx} value={c.code}>
                                    {c.code}{""}&nbsp;{c.label}
                                  </option>
                                ))
                              }
                            </select>

                            {validation.touched.countrycode && validation.errors.countrycode && (
                              <div className="invalid-feedback d-block">{validation.errors.countrycode}</div>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3} className="pe-2">
                        <label>&nbsp;</label>
                        <Input
                          name="phone_number"
                          type="number"
                          placeholder="Enter the Phone Number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone_number ?validation.values.phone_number:""  || ""}
                          invalid={validation.touched.phone_number && validation.errors.phone_number ? true : false}
                        />
                        {validation.touched.phone_number && validation.errors.phone_number ? (
                          <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                        ) : null}
                        {IR_UserSlice.userNumExist && (
                          <div style={{ fontSize: "smaller" }} className="text-danger">
                            Phone Number already assigned to another user.
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              {/* <br/> */}
              <Row>
                  <Col className='my-3' md={12}>
                    <Row className="flex-wrap">
                      <Col md={3} className="ms-1 pe-0 mt-1">
                        <label>Branch:<span className="text-danger"> *</span></label>
                        <select
                          name="branch"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={`form-select ${validation.touched.branch && validation.errors.branch ? 'is-invalid' : ''}`}
                          defaultValue={userInfo === null ? "Select" : userInfo?.branch_id}
                          required
                        >
                          <option value="Select" disabled={true}>Select Branch</option>
                          {
                            IR_UserSlice.branchData.map((b, idx) => (
                              <option key={idx} value={b._id}>
                                {b.branch_name}
                              </option>
                            ))
                          }
                        </select>
                        {validation.touched.branch && validation.errors.branch ? (
                          <FormFeedback type="invalid">{validation.errors.branch}</FormFeedback>
                        ) : null}
                      </Col>

                      <Col md={3} className="ms-1 pe-0 mt-1">
                        <label>Department:<span className="text-danger"> *</span></label>
                        <select
                          name="department"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={`form-select ${validation.touched.department && validation.errors.department ? 'is-invalid' : ''}`}
                          defaultValue={userInfo === null ? "Select" : userInfo?.department_id}

                          required
                        >
                          <option value="Select" disabled={true}>Select Department</option>
                          {
                            IR_UserSlice.deptData.map((d, idx) => (
                              <option key={idx} value={d._id}>
                                {d.dept_name}
                              </option>
                            ))
                          }
                        </select>
                        {validation.touched.department && validation.errors.department ? (
                          <FormFeedback type="invalid">{validation.errors.department}</FormFeedback>
                        ) : null}
                      </Col>

                      <Col md={4} className="ms-1 pe-0 mt-1">
                        <label>Designation: </label>
                        <Input
                          name={"designation"}
                          type={"text"}
                          placeholder={"Enter Designation"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.designation || ""}
                          invalid={validation.touched.designation && validation.errors.designation ? true : false}
                        />
                        {validation.touched.designation && validation.errors.designation ? (
                          <FormFeedback type="invalid">{validation.errors.designation}</FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              <Row>
                <Col md={2}>
                <Label className="form-label">Roles :<span className='text-danger'>*</span></Label>
                    <select name="role"
                      onChange={(e)=>{
                        handleChangeRole(e)
                        // validation.handleChange
                      }}
                      onBlur={validation.handleBlur}
                      className={`form-select ${validation.touched.role && validation.errors.role ? 'is-invalid' : ''}`}
                      defaultValue={userInfo === null ? "Select":userInfo?.role_id}
                      required>
                      <option value="Select" disabled={true}>Select</option>
                      {
                        IR_UserSlice.roleList.map((c, idx) => (
                          <option key={idx} value={c._id}>
                            {c.role_name}
                          </option>
                        ))
                      }
                    </select>
                </Col>
                  {
                    IR_UserSlice.HData.length > 1 &&
                    <Col md={4}>
                <Label className="form-label">Select Hierarchy :<span className='text-danger'>*</span></Label>
                        <select name="user_hierarchy"
                          className={`form-select`}
                          defaultValue={ userInfo === null ? "Select" : userInfo.h_id[0]}
                          onChange={selectHierarchy}
                          required>
                          <option value="Select" disabled={true}>Select</option>
                          {
                            IR_UserSlice.HData.map((c, idx) => (
                              <option key={idx} value={c._id}>
                                {c.hname}
                              </option>
                            ))
                          }
                        </select>
                    </Col>
                  }
              </Row>
                    <br/>
                    {
                    multiTreeNode.length > 0 ?
              <Row>
                  <h6>Choose Incident Location :<span className='text-danger'>*  </span>{selectedLocation &&<span className='font-size-12 text-danger'>Please choose the location</span>}</h6>
                      {
                      multiTreeNode?.map((ele, idx) => {
                        return (
                          <Col key={idx}>
                            <Tree
                              // style={{ borderColor: '#150101' }}
                              style={{ borderColor: '#150101', marginBottom: '10px', color: selectedLocation ? '#ff6666' : '', border: selectedLocation && '1px dashed #ff9999', borderRadius: '8px' }}
                              defaultExpandAll={true} 
                              treeData={ele.treeNode}
                              checkable
                              checkedKeys={ele.checkedKeys}
                              onCheck={(checked, event) => {
                                setSelectedLocation(false)
                                var multiTreeNodeinfo = _.cloneDeep(multiTreeNode)
                                var checkedKeysInfo = _.cloneDeep(checkedKeys)
                                if(event.checked){
                                checkedKeysInfo.push(event.node.key)
                                }
                                else{
                                  checkedKeysInfo= checkedKeysInfo.filter((ele)=>{
                                    return ele !== event.node.key
                                  })
                                }
                                console.log(checkedKeysInfo,'checked',checkedKeys)
                                multiTreeNodeinfo[idx]["checkedKeys"] = checkedKeysInfo
                                console.log(multiTreeNodeinfo,'multiTreeNodeinfo')
                                setmultiTreeNode(multiTreeNodeinfo)
                                setcheckedKeys(checkedKeysInfo)
                              }}
                              checkStrictly={true}
                              showLine={true}
                              showIcon={true}
                              expandedKeys={expandedKeys}
                              onExpand={handleExpand}


                            />
                          </Col>
                        )
                      })
                    }
                     
              </Row>
               :
               <div className="d-flex flex-column justify-content-center align-items-center">
                 {/* <div>Loading...</div>
                 <Spinner className="ms-2" color="primary" /> */}
               </div>
           }

              <Row>
              <Col>
                <div className="text-end">
                      <button
                        className="btn btn-danger me-2"
                        type="button"
                        onClick={()=>{
                          navigate("/manage-users")
                        }}
                      >
                        Cancel

                      </button>
                  <button type='submit' 
                  onClick={()=>{
                    checkedKeys.length > 0 ?
                    setSelectedLocation(false)
                    :
                    setSelectedLocation(true)

                  }}
                  
                  className="btn btn-success"
                  disabled={submitLoad}
                  >
                        {
                          submitLoad ? (
                            <>
                              <Spinner size="sm">...</Spinner>
                              <span>{' '}Submitting...</span>
                            </>
                          ) : (
                            <>Submit</>
                          )
                        }
                    
                  </button>
                </div>
              </Col>
            </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
else{
  console.log("Panel room")
  return null
}
}
export default IRAddUser
