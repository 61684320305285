import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Input, Label, FormFeedback, Spinner, Button, Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";
import { userAuthenticate, verifyShortName, setauthLoad, setInvalidCredentials, setaccActivationErr, confirmNewPwd, setInvalidErrMsg } from "toolkitStore/IncidentReport/IR_AuthSlice";
import { useDispatch, useSelector } from "react-redux";

const Login2 = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [alert, setAlert] = useState({ visible: false, message: "", color: "" });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitLoad, setsubmitLoad] = useState(false);

  const countryCodes = [
    { code: "+1", name: "USA" },
    { code: "+44", name: "UK" },
    { code: "+91", name: "India" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IrSlice = useSelector(state => state.IR_Slice);
  const authLoad = IrSlice.authLoad;

  document.title = "Login | Incidentum";

  useEffect(() => {
    dispatch(verifyShortName(navigate));
  }, []);

  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   if (/\S+@\S+\.\S+/.test(value)) {
  //     setIsPhone(false);
  //   } else if (/^\d+$/.test(value)) {
  //     setIsPhone(true);
  //   } else {
  //     setIsPhone(false);
  //   }
  //   validation.setFieldValue("username", value);
  // };

  // const newPwd = useFormik({
  // enableReinitialize: true,
  // initialValues: {
  // username: '',
  // new_password: '',
  // confrm_password: ''
  // },
  // validationSchema: Yup.object({
  // new_password: Yup.string().required("Please Enter Your New Password"),
  // confrm_password: Yup.string().required("Please Enter Your Confirm Password"),
  // }),
  // onSubmit: async (values) => {
  // var short_name = sessionStorage.getItem("short_name");
  // values["session"] = IrSlice.pwdSession;
  // values["username"] = validation.values.username;
  // values["short_name"] = short_name;
  // dispatch(confirmNewPwd(values));
  // }
  // });


  const handleInputChange = (e) => {
    let value = e.target.value;
    if (/\S+@\S+\.\S+/.test(value)) {
      setIsPhone(false);
    } else if (/^\d+$/.test(value)) {
      setIsPhone(true);
      if (value.length > 10) {
        value = value.slice(0, 10);  // Limit to 10 characters
      }
    } else {
      setIsPhone(false);
    }
    validation.setFieldValue("username", value);
  };


  const newPwd = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      new_password: '',
      confrm_password: ''
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required("Please Enter Your New Password"),
      confrm_password: Yup.string()
        .required("Please Enter Your Confirm Password")
        .oneOf([Yup.ref('new_password'), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      setsubmitLoad(true)
      var short_name = sessionStorage.getItem("short_name");
      values["session"] = IrSlice.pwdSession; // Assuming this is defined
      values["username"] = isPhone ? countryCode + String(validation.values.username) :validation.values.username; // Assuming validation is defined
      values["short_name"] = short_name;
      values["user_info"] = validation.values.username;
      // values["user_info"] = validation.values.username;


     await dispatch(confirmNewPwd(values)); // Assuming confirmNewPwd is defined
     setsubmitLoad(false)

    }
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      values["db_name"] = IrSlice.clientData.database_name;
      values["database_url"] = IrSlice.clientData.database_url;
      values["short_name"] = IrSlice.clientData.short_name;
      values["country_code"]=countryCode
      dispatch(setauthLoad(true));
      var authInfo = await dispatch(userAuthenticate(values, navigate));
      if (authInfo) {
        dispatch(setInvalidCredentials(false));
        dispatch(setaccActivationErr(false));
        dispatch(setInvalidErrMsg(""));
        const endDate = new Date(authInfo.client_info[0].client_configure.end_date);
        const today = new Date();
        const endDateDateOnly = endDate.toISOString().split('T')[0];
        const todayDateOnly = today.toISOString().split('T')[0];
        const isTodayOrBefore = endDateDateOnly < todayDateOnly;
        if (authInfo.role_status === true) {
          if (isTodayOrBefore || authInfo.client_info[0].status === false || authInfo.user_data.status === false) {
            Swal.fire({
              icon: 'warning',
              title: 'Expired!',
              text: 'Your company package has expired. Please contact Admin for extension!',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            });
          } else {
            sessionStorage.setItem("authUser", JSON.stringify(authInfo));
            sessionStorage.setItem('db_info', JSON.stringify(authInfo.db_info));
            sessionStorage.setItem('client_info', JSON.stringify(authInfo.client_info));
            navigate("/dashboard");
          }
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Inactivated!',
            text: 'Your role status has been inactivated. Please contact Admin!',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        }
      } else {
        // Handle failed authentication
        setAlert({ visible: true, message: "Invalid Username or Password", color: "danger" });

        setTimeout(() => {
          setAlert({ visible: false, message: "Invalid Username or Password", color: "danger" });
        }, 5000);
      }
    }
  });

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block card-logo">
                        <img src={logodark} alt="" height="18" className="logo-dark-element" />
                        <img src={logolight} alt="" height="18" className="logo-light-element" />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back!</h5>
                        <p className="text-muted">Sign in to continue to Incident Report.</p>
                      </div>


                      {
                        IrSlice.showVerifyPwd ?
                          <div className="mt-4">
                            <Form className="form-horizontal"
                              onSubmit={(e) => {
                                e.preventDefault();
                                newPwd.handleSubmit();
                                return false;
                              }}
                            >
                              <div className="mb-3">
                                <Label className="form-label">Username :</Label>
                                <Input
                                  name="username"
                                  className="form-control"
                                  placeholder="Enter Email ID"
                                  type="username"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.username || ""}
                                  disabled={validation.values.username}
                                  invalid={validation.touched.username && validation.errors.username ? true : false}
                                />
                                {validation.touched.username && validation.errors.username ? (
                                  <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">New Password :</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Input
                                    name="new_password"
                                    className="form-control"
                                    placeholder="Enter New Password"
                                    type={showNewPassword ? "text" : "password"}
                                    onChange={newPwd.handleChange}
                                    onBlur={newPwd.handleBlur}
                                    value={newPwd.values.new_password || ""}
                                    invalid={newPwd.touched.new_password && newPwd.errors.new_password ? true : false}
                                    autoComplete='new-password'
                                  />
                                  <button
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    className="btn text-dark"
                                    style={{ background: 'gainsboro' }}
                                    type="button"
                                    id="password-addon"
                                  >
                                    <i className={showNewPassword ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i>
                                  </button>

                                  {newPwd.touched.new_password && newPwd.errors.new_password ? (
                                    <FormFeedback type="invalid">{newPwd.errors.new_password}</FormFeedback>
                                  ) : null}
                                </div>



                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Confirm Password :</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Input
                                    name="confrm_password"
                                    className="form-control"
                                    placeholder="Enter Confirm Password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    onChange={newPwd.handleChange}
                                    onBlur={newPwd.handleBlur}
                                    value={newPwd.values.confrm_password || ""}
                                    invalid={newPwd.touched.confrm_password && newPwd.errors.confrm_password ? true : false}
                                    autoComplete='new-password'
                                  />
                                  <button
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    className="btn text-dark"
                                    style={{ background: 'gainsboro' }}
                                    type="button"
                                    id="confirm-password-addon"
                                  >
                                    <i className={showConfirmPassword ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i>
                                  </button>
                                  {newPwd.touched.confrm_password && newPwd.errors.confrm_password ? (
                                    <FormFeedback type="invalid">{newPwd.errors.confrm_password}</FormFeedback>
                                  ) : null}
                                </div>

                              </div>

                              <div className="mt-3 text-end">
                                <Button disabled={submitLoad || IrSlice.updateMsg} color="primary" type="submit">
                                  {
                                    submitLoad ? (
                                      <>
                                        <Spinner size={"sm"}>...</Spinner>
                                        <span>{' '}Submitting...</span>
                                      </>
                                    ) : (
                                      <>Submit</>
                                    )}
                                  
                                </Button>
                              </div>
                              {
                                IrSlice.updateMsg &&
                                <div className="alert alert-success text-center mb-4" role="alert">Password updated successfully.</div>

                              }
                            </Form>

                          </div>
                          :
                          <div className="mt-4">
                            <Form
                              className="form-horizontal"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <div className="mb-3">
                                <Label className='m-0 fw-bold'>E-MAIL / MOBILE NUMBER</Label>
                                <div className="font-size-14 text-secondary text-opacity-75 mb-3">Enter your mobile number or e-mail to proceed</div>

                                <div className={`${isPhone ? 'd-flex' : ''}`} >
                                  {isPhone &&
                                    <Input
                                      type="select"
                                      className="form-control w-auto me-1"
                                      value={countryCode}
                                      onChange={(e) => setCountryCode(e.target.value)}
                                    >
                                      {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                          {country.name} ({country.code})
                                        </option>
                                      ))}
                                    </Input>
                                  }
                                  <Input
                                    name="username"
                                    className="form-control"
                                    placeholder="Enter Email ID / Phone Number"
                                    type={`${isPhone ? 'number' : 'text'}`}
                                    onChange={handleInputChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                    invalid={validation.touched.username && validation.errors.username ? true : false}
                                    min={isPhone ? "0" : undefined}
                                    // max={isPhone ? "10" : undefined}
                                  />
                                  {validation.touched.username && validation.errors.username ? (
                                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                  ) : null}
                                </div>

                                {/* {!isPhone ? (
                                  <Input
                                    name="username"
                                    className="form-control"
                                    placeholder="Enter email"
                                    type="text"
                                    onChange={handleInputChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                    invalid={validation.touched.username && validation.errors.username ? true : false}
                                  />
                                ) : (
                                  <div className="d-flex">
                                    <Input
                                      type="select"
                                      className="form-control w-auto me-1"
                                      value={countryCode}
                                      onChange={(e) => setCountryCode(e.target.value)}
                                    >
                                      {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                          {country.name} ({country.code})
                                        </option>
                                      ))}
                                    </Input>
                                    <Input
                                      name="username"
                                      className="form-control"
                                      placeholder="Enter phone number"
                                      type="text"
                                      onChange={handleInputChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.username || ""}
                                      invalid={validation.touched.username && validation.errors.username ? true : false}
                                    />
                                  </div>
                                )}
                                {validation.touched.username && validation.errors.username ? (
                                  <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                ) : null} */}

                              </div>

                              <div className="mb-3">
                                <div className="float-end">
                                  <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
                                </div>
                                <Label className="form-label">Password</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type={passwordShow ? "text" : "password"}
                                    placeholder="Enter Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={validation.touched.password && validation.errors.password ? true : false}
                                  />
                                  <button onClick={() => setPasswordShow(!passwordShow)} className="btn text-dark" style={{ background: 'gainsboro' }} type="button" id="password-addon">
                                    <i className="mdi mdi-eye-outline"></i>
                                  </button>
                                </div>
                                {validation.touched.password && validation.errors.password ? (
                                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                ) : null}
                              </div>

                              {/* Error Messages with Alert */}
                              <Alert color={alert.color} isOpen={alert.visible} toggle={() => setAlert({ ...alert, visible: false })}>
                                {alert.message}
                              </Alert>
                              {IrSlice.invalidErrMsg !== "" && (
                                <Alert color="danger" isOpen={true}>
                                  {IrSlice.invalidErrMsg}
                                </Alert>
                              )}
                              {IrSlice.invalidCredentials && (
                                <Alert color="danger" isOpen={true}>
                                  Invalid Username or Password
                                </Alert>
                              )}
                              {IrSlice.accActivationErr && (
                                <Alert color="danger" isOpen={true}>
                                  Your account is not activated.
                                </Alert>
                              )}

                              <div className="mt-3 d-grid">
                                <button className="btn btn-primary btn-block" type="submit" disabled={authLoad}>
                                  {authLoad ? (
                                    <>
                                      <Spinner size="sm">...</Spinner>
                                      <span>{' '}Authenticating</span>
                                    </>
                                  ) : (
                                    <>Log In</>
                                  )}
                                </button>
                              </div>
                            </Form>
                          </div>
                      }
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} designed and developed by eParampara</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login2;






















// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { Col, Container, Form, Row, Input, Label, FormFeedback, Spinner,Button } from "reactstrap";
// import { useNavigate } from "react-router-dom";
// // Formik validation
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import Swal from "sweetalert2";

// // import images
// import logodark from "../../assets/images/logo-dark.png";
// import logolight from "../../assets/images/logo-light.png";
// import CarouselPage from "./CarouselPage";
// import {
//   userAuthenticate, verifyShortName, setauthLoad, setInvalidCredentials
//   , setaccActivationErr,
//   confirmNewPwd,
//   setInvalidErrMsg
// } from "toolkitStore/IncidentReport/IR_AuthSlice";
// import { useDispatch, useSelector } from "react-redux";

// const Login2 = () => {
//   const [passwordShow, setPasswordShow] = useState(false);
//   // const [authLoad,setauthLoad] = useState(false)
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const IrSlice = useSelector(state => state.IR_Slice)
//   const authLoad = IrSlice.authLoad

//   document.title = "Login | Inicident Report Admin & Dashboard Template";

//   useEffect(() => {
//     dispatch(verifyShortName(navigate))

//   }, [])


//   const newPwd = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       username: '',
//       new_password: '',
//       confrm_password :''
//     },
//     validationSchema: Yup.object({
//       new_password: Yup.string().required("Please Enter Your New Password"),
//       confrm_password: Yup.string().required("Please Enter Your Confirm Password"),
//     }),
//     onSubmit: async (values) => {
//       console.log(values,'values');
//       var short_name = sessionStorage.getItem("short_name")
//       values["session"]= IrSlice.pwdSession
//       values["username"]=validation.values.username
//       values["short_name"]=short_name
//       dispatch(confirmNewPwd(values))

//     }

//   })

//   // Form validation 
//   const validation = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       username: '',
//       password: '',
//     },
//     validationSchema: Yup.object({
//       username: Yup.string().required("Please Enter Your Username"),
//       password: Yup.string().required("Please Enter Your Password"),
//     }),
//     onSubmit: async (values) => {
//       console.log(values, 'values',IrSlice)
//       values["db_name"] = IrSlice.clientData.database_name
//       values["database_url"] = IrSlice.clientData.database_url
//       values["short_name"] = IrSlice.clientData.short_name
//       dispatch(setauthLoad(true))
//       var authInfo = await dispatch(userAuthenticate(values, navigate))
//       console.log(authInfo, 'authInfo')
//       if (authInfo) {
//         dispatch(setInvalidCredentials(false))
//         dispatch(setaccActivationErr(false))
//         dispatch(setInvalidErrMsg(""))
//         const endDate = new Date(authInfo.client_info[0].client_configure.end_date);
//         const today = new Date();
//         const endDateDateOnly = endDate.toISOString().split('T')[0];
//         const todayDateOnly = today.toISOString().split('T')[0];
//         const isTodayOrBefore = endDateDateOnly < todayDateOnly;
//         if (authInfo.role_status === true) {
//           if (isTodayOrBefore || authInfo.client_info[0].status === false || authInfo.user_data.status === false) {
//             Swal.fire({
//               icon: 'warning',
//               title: 'Expired!',
//               text: 'Your company package has been Expired , For Extends Please Contact Admin !',
//               confirmButtonColor: '#3085d6',
//               confirmButtonText: 'OK'
//             }).then((result) => {
//             })
//           }
//           else if (isTodayOrBefore == false) {
//             sessionStorage.setItem("authUser", JSON.stringify(authInfo));
//             sessionStorage.setItem('db_info', JSON.stringify(authInfo.db_info))
//             sessionStorage.setItem('client_info', JSON.stringify(authInfo.client_info))
//             navigate("/dashboard")
//           }
//         }
//         else {
//           Swal.fire({
//             icon: 'warning',
//             title: 'Inactivate!',
//             text: 'Your Role Status has been Inactivated , For Activation Please Contact Admin !',
//             confirmButtonColor: '#3085d6',
//             confirmButtonText: 'OK'
//           }).then((result) => {

//           })



//         }
//       }

//     }
//   });
//   return (
//     <React.Fragment>
//       <div>
//         <Container fluid className="p-0">
//           <Row className="g-0">
//             <CarouselPage />

//             <Col xl={3}>
//               <div className="auth-full-page-content p-md-5 p-4">
//                 <div className="w-100">
//                   <div className="d-flex flex-column h-100">
//                     <div className="mb-4 mb-md-5">
//                       <Link to="/dashboard" className="d-block card-logo">
//                         <img
//                           src={logodark}
//                           alt=""
//                           height="18"
//                           className="logo-dark-element"
//                         />
//                         <img
//                           src={logolight}
//                           alt=""
//                           height="18"
//                           className="logo-light-element"
//                         />
//                       </Link>
//                     </div>
//                     <div className="my-auto">
//                       <div>
//                         <h5 className="text-primary">Welcome Back !</h5>
//                         <p className="text-muted">
//                           Sign in to continue to Incident Report.
//                         </p>
//                       </div>
//                       {
//                         IrSlice.showVerifyPwd ?
//                         <div className="mt-4">
//                         <Form className="form-horizontal"
//                           onSubmit={(e) => {
//                             e.preventDefault();
//                             newPwd.handleSubmit();
//                             return false;
//                           }}
//                         >
//                           <div className="mb-3">
//                             <Label className="form-label">Username :</Label>
//                             <Input
//                               name="username"
//                               className="form-control"
//                               placeholder="Enter Email ID"
//                               type="username"
//                               onChange={validation.handleChange}
//                               onBlur={validation.handleBlur}
//                               value={validation.values.username || ""}
//                               disabled={validation.values.username}
//                               invalid={
//                                 validation.touched.username && validation.errors.username ? true : false
//                               }
//                             />
//                             {validation.touched.username && validation.errors.username ? (
//                               <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
//                             ) : null}
//                           </div>
                         
//                               <div className="mb-3">
//                                 <Label className="form-label">New Password :</Label>
//                                 <Input
//                                   name="new_password"
//                                   className="form-control"
//                                   placeholder="Enter New Password"
//                                   type="password"
//                                   onChange={newPwd.handleChange}
//                                   onBlur={newPwd.handleBlur}
//                                   value={newPwd.values.new_password || ""}
//                                   invalid={
//                                     newPwd.touched.new_password && newPwd.errors.new_password ? true : false
//                                   }
//                                   autoComplete='new-password'
//                                 />
                                
//                                 {newPwd.touched.new_password && newPwd.errors.new_password ? (
//                                   <FormFeedback type="invalid">{newPwd.errors.new_password}</FormFeedback>
//                                 ) : null}
//                               </div>
//                               <div className="mb-3">
//                                 <Label className="form-label">Confirm Password :</Label>
//                                 <Input
//                                   name="confrm_password"
//                                   className="form-control"
//                                   placeholder="Enter Confirm Password"
//                                   type="password"
//                                   onChange={newPwd.handleChange}
//                                   onBlur={newPwd.handleBlur}
//                                   value={newPwd.values.confrm_password || ""}
//                                   invalid={
//                                     newPwd.touched.confrm_password && newPwd.errors.confrm_password ? true : false
//                                   }
//                                   autoComplete='new-password'
//                                 />
                                
//                                 {newPwd.touched.confrm_password && newPwd.errors.confrm_password ? (
//                                   <FormFeedback type="invalid">{newPwd.errors.confrm_password}</FormFeedback>
//                                 ) : null}
//                               </div>

//                           <div className="mt-3 text-end">
//                             <Button
//                               color="primary"
//                               type="submit"
//                             >
//                               Submit
//                             </Button>
//                           </div>

//                         </Form>
//                       </div>
//                       :
//                       <div className="mt-4">
//                       <Form className="form-horizontal"
//                         onSubmit={(e) => {
//                           e.preventDefault();
//                           validation.handleSubmit();
//                           return false;
//                         }}
//                       >
//                         <div className="mb-3">
//                           <Label className="form-label">Username</Label>
//                           <Input
//                             name="username"
//                             className="form-control"
//                             placeholder="Enter username"
//                             type="text"
//                             onChange={validation.handleChange}
//                             onBlur={validation.handleBlur}
//                             value={validation.values.username || ""}
//                             invalid={
//                               validation.touched.username && validation.errors.username ? true : false
//                             }
//                           />
//                           {validation.touched.username && validation.errors.username ? (
//                             <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
//                           ) : null}
//                         </div>

//                         <div className="mb-3">
//                           <div className="float-end">
//                             <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
//                           </div>
//                           <Label className="form-label">Password</Label>
//                           <div className="input-group auth-pass-inputgroup">
//                             <Input
//                               name="password"
//                               value={validation.values.password || ""}
//                               type={passwordShow ? "text" : "password"}
//                               placeholder="Enter Password"
//                               onChange={validation.handleChange}
//                               onBlur={validation.handleBlur}
//                               invalid={
//                                 validation.touched.password && validation.errors.password ? true : false
//                               }
//                             />
//                             <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
//                               <i className="mdi mdi-eye-outline"></i></button>
//                           </div>
//                           {validation.touched.password && validation.errors.password ? (
//                             <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
//                           ) : null}
//                         </div>
//                         {
//                           IrSlice.invalidErrMsg !== "" &&
//                           <div className="text-danger" style={{ fontSize: 'smaller' }}>{IrSlice.invalidErrMsg}</div>
//                         }
//                         {
//                           IrSlice.invalidCredentials &&
//                           <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}> Invalid Username or Password</div>
//                         }
//                         {
//                           IrSlice.accActivationErr &&
//                           <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Your account is not activated.</div>
//                         }
//                         <div className="mt-3 d-grid">
//                           <button
//                             className="btn btn-primary btn-block "
//                             type="submit"
//                             disabled={authLoad}
//                           >
//                             {authLoad ?
//                               <>
//                                 <Spinner size="sm">
//                                   ...
//                                 </Spinner>
//                                 <span>
//                                   {' '}Authenticating
//                                 </span>
//                               </>
//                               :
//                               <>
//                                 Log In
//                               </>
//                             }

//                           </button>
//                         </div>


//                       </Form>
//                     </div>
//                       }
                     





//                       {/* <div className="mt-4">
//                         <Form className="form-horizontal"
//                           onSubmit={(e) => {
//                             e.preventDefault();
//                             validation.handleSubmit();
//                             return false;
//                           }}
//                         >
//                           <div className="mb-3">
//                             <Label className="form-label">Username</Label>
//                             <Input
//                               name="username"
//                               className="form-control"
//                               placeholder="Enter username"
//                               type="text"
//                               onChange={validation.handleChange}
//                               onBlur={validation.handleBlur}
//                               value={validation.values.username || ""}
//                               invalid={
//                                 validation.touched.username && validation.errors.username ? true : false
//                               }
//                             />
//                             {validation.touched.username && validation.errors.username ? (
//                               <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
//                             ) : null}
//                           </div>

//                           <div className="mb-3">
//                             <div className="float-end">
//                               <Link to="/auth-recoverpw-2" className="text-muted">Forgot password?</Link>
//                             </div>
//                             <Label className="form-label">Password</Label>
//                             <div className="input-group auth-pass-inputgroup">
//                               <Input
//                                 name="password"
//                                 value={validation.values.password || ""}
//                                 type={passwordShow ? "text" : "password"}
//                                 placeholder="Enter Password"
//                                 onChange={validation.handleChange}
//                                 onBlur={validation.handleBlur}
//                                 invalid={
//                                   validation.touched.password && validation.errors.password ? true : false
//                                 }
//                               />
//                               <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
//                                 <i className="mdi mdi-eye-outline"></i></button>
//                             </div>
//                             {validation.touched.password && validation.errors.password ? (
//                               <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
//                             ) : null}
//                           </div>
//                           {
//                             IrSlice.invalidErrMsg !== "" &&
//                             <div className="text-danger" style={{ fontSize: 'smaller' }}>{IrSlice.invalidErrMsg}</div>
//                           }
//                           {
//                             IrSlice.invalidCredentials &&
//                             <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}> Invalid Username or Password</div>
//                           }
//                           {
//                             IrSlice.accActivationErr &&
//                             <div className="text-danger mt-1" style={{ fontSize: 'smaller' }}>Your account is not activated.</div>
//                           }
//                           <div className="mt-3 d-grid">
//                             <button
//                               className="btn btn-primary btn-block "
//                               type="submit"
//                               disabled={authLoad}
//                             >
//                               {authLoad ?
//                                 <>
//                                   <Spinner size="sm">
//                                     ...
//                                   </Spinner>
//                                   <span>
//                                     {' '}Authenticating
//                                   </span>
//                                 </>
//                                 :
//                                 <>
//                                   Log In
//                                 </>
//                               }

//                             </button>
//                           </div>


//                         </Form>
//                       </div> */}
//                     </div>

//                     <div className="mt-4 mt-md-5 text-center">
//                       <p className="mb-0">
//                         © {new Date().getFullYear()} designed and developed by{" "}
//                         eParampara
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Login2;
