import { createSlice } from "@reduxjs/toolkit";
import { put, post, get, del } from "helpers/api_helper";


const initialState = {
    aplnRequestLoading: false,
    aplnRequestError: null,
    roleData: [],
    editroleData : null,
}

// const authUser = JSON.parse(sessionStorage.getItem("authUser"))

const IrRoleSlice = createSlice({

    name :"IrRoleSlice",
    initialState : initialState,
    reducers:{
        setAplnRequestLoading:(state,action)=>{
            state.aplnRequestLoading = action.payload
        },
        setAplnRequestError:(state,action)=>{
            state.aplnRequestError = action.payload
        },
        setRoleData:(state,action)=>{
            state.roleData = action.payload
        },
        seteditRoleData:(state,action)=>{
            state.editroleData = action.payload
        }
    }
})



export const retriveRoleData =()=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))

            const responseData = await post("ir-role/ir-retrive-roles", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
            })
            console.log(responseData,'encrypted_db_url')
            if(responseData.status === 200){
                dispatch(setRoleData(responseData.data.data))
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))
            }

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
            
        }
    }

}




export const retriveRoleInfo =(_id)=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
    
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))


            const responseData = await post("ir-role/ir-retrive-role-info", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                role_id:_id
            })
            console.log(responseData,'encrypted_db_url')
            if(responseData.status === 200){
                // dispatch(setRoleData(responseData.data.data))
                return responseData.data.data
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))
            }

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
            
        }
    }

}



export const createRoleData =(values)=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))

            const authUser = JSON.parse(sessionStorage.getItem("authUser"))


            const responseData = await post("ir-role/ir-create-roles", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                role_info :values
            })
            console.log(responseData,'encrypted_db_url')
            if(responseData.status === 200){
                // dispatch(setRoleData(responseData.data.data))
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))
            }

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
            
        }
    }

}



export const {
    setAplnRequestError,
    setAplnRequestLoading,
    seteditRoleData,
    setRoleData,
} = IrRoleSlice.actions

export default IrRoleSlice.reducer