import { createSlice } from "@reduxjs/toolkit";
import { put, post, get, del } from "helpers/api_helper";


const initialState = {
    aplnRequestLoading: false,
    aplnRequestError: null,
    branchData: [],
    departMentData:[],
    editBranchInfo : null,
    editDeptInfo : null,
    branchNameExist : false,
    deptNameExist: false,
    roleNameExist : false,
    incdTypeNameExist: false,
    sevrtTypeNameExist : false,
    locationNameExist : false,
    branchCodeExist : false
}


const IrOrgSlice = createSlice({
    name: "IrOrgSlice",
    initialState: initialState,
    reducers: {
        setAplnRequestLoading: (state, action) => {
            state.aplnRequestLoading = action.payload
        },
        setAplnRequestError: (state, action) => {
            state.aplnRequestError = action.payload
        },
        setBranchData: (state, action) => {
            state.branchData = action.payload
        },
        setDepartmentData:(state,action)=>{
            state.departMentData = action.payload
        },
        seteditBranchInfo:(state,action)=>{
            state.editBranchInfo = action.payload
        },
        seteditDeptInfo:(state,action)=>{
            state.editDeptInfo = action.payload
        },
        setBranchNameExist:(state,action)=>{
            state.branchNameExist = action.payload
        },
        setDepartmentExist:(state,action)=>{
            state.deptNameExist = action.payload
        },
        setRoleNameExist:(state,action)=>{
            state.roleNameExist = action.payload
        },
        setIncdTypNameExist:(state,action)=>{
            state.incdTypeNameExist = action.payload
        },
        setSevrtTypNameExist:(state,action)=>{
            state.sevrtTypeNameExist = action.payload
        },
        setLocNameExist:(state,action)=>{
            state.locationNameExist = action.payload
        },
        setBranchCodeExist:(state,action)=>{
            state.branchCodeExist = action.payload
        },

    }
})


export const retriveBranchInfo = () => {

    return async dispatch => {
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-org/retrive-branch", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
            })
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(setBranchData(responseData.data.data))
            }
            else {
                dispatch(setAplnRequestLoading(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }

    }

}



export const retriveDeptInfo = () => {

    return async dispatch => {
        var authUser = JSON.parse(sessionStorage.getItem("authUser"))


        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-org/retrive-dept", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData');
            if (responseData.status === 200) {
                dispatch(setDepartmentData(responseData.data.data))
            }
            else {
                dispatch(setAplnRequestLoading(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }

    }

}



export const createBranchInfo = (values) => {

    return async dispatch => {
        var authUser = JSON.parse(sessionStorage.getItem("authUser"))


        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-org/create-branch", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                branch_info: values
            })
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(retriveBranchInfo())
            }
            else {
                dispatch(setAplnRequestLoading(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }

    }

}




export const createDeptInfo = (values) => {

    return async dispatch => {
        var authUser = JSON.parse(sessionStorage.getItem("authUser"))


        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-org/create-dept", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                dept_info: values
            })
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                // dispatch(retriveBranchInfo())
                dispatch(retriveDeptInfo())
            }
            else {
                dispatch(setAplnRequestLoading(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }

    }

}



export const deleteBranchInfo = (values) => {

    return async dispatch => {
        var authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-org/delete-branch", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                branch_id: values._id
            })
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(retriveBranchInfo())
            }
            else {
                dispatch(setAplnRequestLoading(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }

    }

}




export const deleteDeptInfo = (values) => {

    return async dispatch => {
        var authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-org/delete-dept", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                dept_id: values._id
            })
            console.log(responseData,'responseData');
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(retriveDeptInfo())
            }
            else {
                dispatch(setAplnRequestLoading(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }

    }

}


export const IRTvalidateDupName=(editInfo,dynamic_cln,dynamic_key,dynamic_value,dynamicReducer)=>{

    return async dispatch =>{

        try {
        var authUser = JSON.parse(sessionStorage.getItem("authUser"))

            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-severt-type/irt-dup-name-validation",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                db_name : authUser.db_info.db_name,
                dynamic_cln : dynamic_cln,
                dynamic_key : dynamic_key,
                editInfo : editInfo,
                dynamic_value : dynamic_value
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData');
            if(responseData.status === 200){
                dispatch(dynamicReducer(responseData.data.data.length >0 ? true : false))
                // return responseData.data.data
            }
            
        } catch (error) {
            console.log(error,'error')
            dispatch(setAplnRequestLoading(error))
            
        }


    }



}






export const {
    setAplnRequestError,
    setAplnRequestLoading,
    seteditBranchInfo,
    setBranchNameExist,
    setBranchData,
    setDepartmentData,
    seteditDeptInfo,
    setDepartmentExist,
    setRoleNameExist,
    setIncdTypNameExist,
    setSevrtTypNameExist,
    setLocNameExist,
    setBranchCodeExist
} = IrOrgSlice.actions

export default IrOrgSlice.reducer