import { createSlice } from "@reduxjs/toolkit";
import { put, post, get, del } from "helpers/api_helper";
import { setnodeUsers, setShowUserListDrawer } from "./htree";

const initialState = {
    aplnRequestLoading: false,
    aplnRequestError: null,
    locationData :[],
    editLocInfo: null,
    locTreeInfo :[],
    locUserList:[]
}

// const authUser = JSON.parse(sessionStorage.getItem("authUser"))


const IrLocationSlice = createSlice({
    name :"IrLocationSlice",
    initialState:initialState,
    reducers:{
        setAplnRequestLoading: (state, action) => {
            state.aplnRequestLoading = action.payload
        },
        setAplnRequestError: (state, action) => {
            state.aplnRequestError = action.payload
        },
        setLocationData:(state,action)=>{
            state.locationData = action.payload
        },
       setEditLocInfo:(state,action)=>{
        state.editLocInfo = action.payload
       },
       setLocTreeInfo:(state,action)=>{
        state.locTreeInfo = action.payload
       },
       setLocUserList:(state,action)=>{
        state.locUserList = action.payload
       }
    }
})


export const retriveLocationData=()=>{

  return async dispatch =>{
const authUser = JSON.parse(sessionStorage.getItem("authUser"))


    try {
        dispatch(setAplnRequestLoading(true))
        const responseData = await post("ir-loc/retrive-loc-list",{
            encrypted_db_url : authUser.db_info.encrypted_db_url,
            created_by : authUser.user_data._id
        })
        console.log(responseData,'responseData');
        dispatch(setAplnRequestLoading(false))
        if(responseData.status === 200){
            dispatch(setLocationData(responseData.data.data))
        }
        else{
        dispatch(setAplnRequestError(responseData.data.message))
        }
        
    } catch (error) {
        console.log(error,'error');
        dispatch(setAplnRequestError(error))
    }
  }
}



export const addNodeUsers=(selectedId,nodeInfo,mode)=>{

    return async dispatch =>{
const authUser = JSON.parse(sessionStorage.getItem("authUser"))
 
        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-loc/add-flat-loc-users",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                selectedId : selectedId,
                nodeInfo ,
                mode
            })
            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))
            dispatch(setShowUserListDrawer(false))
            dispatch(retriveNodeUsers(nodeInfo))


            
        } catch (error) {
        dispatch(setAplnRequestError(error))
            
        }
    }
}



export const deleteLoction =(h_id)=>{
 
    return async dispatch =>{
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-loc/delete-loc-info",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                loc_id : h_id
            })
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                dispatch(retriveLocationData())
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))
            
        }
    }
}


export const retriveNodeUsers=(nodeInfo)=>{

    return async dispatch =>{
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-loc/retrive-flat-loc-users",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                nodeInfo 
            })
            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))

            if(responseData.status === 200){
                dispatch(setnodeUsers(responseData.data.data.length > 0 ? responseData.data.data:[]))
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))
            }

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
            
        }
    }
}



export const createLocationInfo=(values)=>{
    console.log(values,'values')

return async dispatch =>{
const authUser = JSON.parse(sessionStorage.getItem("authUser"))


    try {
        dispatch(setAplnRequestLoading(true))
        const responseData = await post("ir-loc/create-loc-info",{
            encrypted_db_url : authUser.db_info.encrypted_db_url,
            loc_info : values

        })
        console.log(responseData,'responseData')
        dispatch(setAplnRequestLoading(false))
        if(responseData.status === 200){
            dispatch(retriveLocationData())
        }


        
    } catch (error) {
        console.log(error,'error');
        dispatch(setAplnRequestError(error))
        
    }
}

}



export const retriveUserList = (_id,mode) => {

    return async dispatch => {

const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-loc/retrive-users-list", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                mode:mode,
                user_id : _id
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData');
            if(responseData.status === 200){
                dispatch(setLocUserList(responseData.data.data))
            }
            else{
             dispatch(setAplnRequestError(responseData.data.message))
                
            }
        } catch (error) {
            dispatch(setAplnRequestError(error))

        }
    }


}


// export const retriveHData = (_id) => {

//     return async dispatch => {

//         try {
//             dispatch(setAplnRequestLoading(true))
//             const responseData = await post("ir-loc/get-loc-tree", {
//                 loc_id: _id,
//                 encrypted_db_url: authUser.db_info.encrypted_db_url,

//             })
//             dispatch(setAplnRequestLoading(false))
//             console.log(responseData,'responseData');
//             if(responseData.status === 200){
//                 if(responseData.data.data.length >0){
//                 dispatch(responseData.data.data[0].hstructure)
//                 }
//             }


//         } catch (error) {

//         }


//     }


// }




export const {
    setAplnRequestError,
    setAplnRequestLoading,
    setLocationData,
    setEditLocInfo,
    setLocTreeInfo,
    setLocUserList
} = IrLocationSlice.actions

export default IrLocationSlice.reducer


