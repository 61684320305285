import { createSlice } from "@reduxjs/toolkit";
import { put, post, get, del } from "helpers/api_helper";

const initialState={
    aplnRequestLoading: false,
    aplnRequestError: null,
    severityType:[],
    sevrtyAutoMateId:'',
    editSevrtyType:null
}
const authUser = JSON.parse(sessionStorage.getItem("authUser"))

const IrSevrtyTypeSlice= createSlice({
    name: "IrSevrtyTypeSlice",
    initialState: initialState,
    reducers: {
        setAplnRequestLoading: (state, action) => {
            state.aplnRequestLoading = action.payload
        },
        setAplnRequestError: (state, action) => {
            state.aplnRequestError = action.payload
        },
        setSeverityType :(state,action)=>{
            state.severityType = action.payload
        },
        setSevrtyAutoMateId:(state,action)=>{
            state.sevrtyAutoMateId = action.payload
        },
        seteditSevrtyType:(state,action)=>{
            state.editSevrtyType = action.payload
        }
    }
})



export const retriveSevrtType =()=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-severt-type/retrive-severt-list",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                created_by : authUser.user_data._id
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                dispatch(setSeverityType(responseData.data.data))
                dispatch(setSevrtyAutoMateId(responseData.data.nextId))
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))

            }
            
        } catch (error) {
            dispatch(setAplnRequestLoading(error))
            
        }

    }
}


export const crudSevrtType =(values)=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-severt-type/crud-severt-info",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                severity_info : values
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                dispatch(retriveSevrtType())
            }
            else{
            dispatch(setAplnRequestLoading(responseData.data.message))

            }
            
        } catch (error) {
            dispatch(setAplnRequestLoading(error))
            
        }

    }
}



export const deleteSevrtType=(values)=>{

    return async dispatch => {

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-severt-type/delete-severt", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                sevrt_id: values._id
            })
            console.log(responseData,'responseData');
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(retriveSevrtType())
            }
            else {
                dispatch(setAplnRequestLoading(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }

    }


}



export const {
    setAplnRequestError,
    setAplnRequestLoading,
    setSeverityType,
    setSevrtyAutoMateId,
seteditSevrtyType} = IrSevrtyTypeSlice.actions

export default IrSevrtyTypeSlice.reducer

