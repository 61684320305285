import React from 'react'
import { useEffect,useState,useMemo } from 'react'
import { CardBody, Container, Row, Col, Card ,Spinner,Badge,UncontrolledTooltip} from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { retriveLocationData ,setEditLocInfo,setLocationData,createLocationInfo,deleteLoction } from 'toolkitStore/IncidentReport/IR_LocationSlice'
import { useSelector,useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import TableContainer from './Components/TableContainer'
import ModalComponent from './Components/Modal'
import { useFormik } from "formik";
import * as Yup from "yup";
import { setLocNameExist } from 'toolkitStore/IncidentReport/IR_OrgSlice'
import store from 'store'
import { IRTvalidateDupName } from 'toolkitStore/IncidentReport/IR_OrgSlice'
import Swal from 'sweetalert2'
import { getHdata, publishHStructure, saveTreeData } from '../../../toolkitStore/IncidentReport/htree'



const IRLocation = () => {
    const IR_Location = useSelector(state => state.IR_LocationSlice)
    const IR_OrgSlice = useSelector(state => state.IR_OrgSlice)
    const [authUser, setAuthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")));
    const [clientInfo, setclientInfo] = useState(JSON.parse(sessionStorage.getItem("client_info")))[0];
    const [modal, setModal] = useState(false);

    const dispatch = useDispatch()
    console.log(IR_Location,'IR_Location')

    useEffect(()=>{
        console.log("object");
        dispatch(retriveLocationData())
    },[])

    const validation = useFormik({
        initialValues: {
            hname: "" ,
        },
        validationSchema: Yup.object({
            hname: Yup.string().required("Location Name is required")
            .test(async function (value) {
                console.log(store.getState(),'values');
                return !(await dispatch(IRTvalidateDupName(store.getState().IR_LocationSlice.editLocInfo, "cln_ir_h_levels", "hname", value, setLocNameExist)));
                
            })
            .matches(/\S+/, "Location name cannot be just spaces")
            .min(clientInfo.incd_rpt_validation.name_min, `Location name must be at least ${clientInfo.incd_rpt_validation.name_min } characters`),
        }),
        onSubmit: async(values) => {
            console.log(values, 'values')
            values["created_by"] = authUser.user_data._id
            values["company_id"] = authUser.user_data.company_id
            values["company_name"] = clientInfo.client_name
            if(IR_Location.editLocInfo !== null){
                values["_id"] = IR_Location.editLocInfo._id
            }
            await dispatch(createLocationInfo(values))
            dispatch(retriveLocationData())
            dispatch(setEditLocInfo(null));
            dispatch(setLocationData([]));
            setModal(false)

        },
    });


    const completedStatus = (data) => {
        var labels = []
        data == false ? labels.push({ status: "not published", color: "#303335", badgeClass: "danger" }) :
            data == true ? labels.push({ status: "published", color: "#fbae17", badgeClass: "success" }) :
                labels.push({ status: "not published", color: "#303335", badgeClass: "danger" })
        return labels
    }


    const onClickDelete=(item)=>{

        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: `Do you want to delete this Location ?`,
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await dispatch(deleteLoction(item._id))
                dispatch(retriveLocationData())
                dispatch(setEditLocInfo(null));
                dispatch(setLocationData([]));

            }


        })

    }


    const columns = useMemo(
        () => [
            {
                accessor: "hname",
                Header: "Severiy Type Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.hname}
                        </div>

                    )
                }
            },
            {
                accessor: 'publish_status',
                Header: 'Status',
                isSort: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    var itemStatus = completedStatus(item.publish_status)[0]
                    return (
                        <>
                            <div className="d-flex gap-3">
                                <Badge className={"font-size-12 badge-soft-" + itemStatus.badgeClass}
                                    color={itemStatus.color}
                                    pill
                                >
                                    {itemStatus.status}
                                </Badge>
                            </div>
                        </>
                    )
                }
            },

            {
                accessor: "createdAt",
                Header: "Created on",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="">
                            {new Intl.DateTimeFormat("en-GB", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }).format(new Date(item?.createdAt))}
                        </div>

                    )
                }
            },
            {
                accessor: "Action",
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="/location-info" 
                                onClick={()=>{
                                    sessionStorage.setItem("hInfo",JSON.stringify(item));

                                }}  
                                className="btn btn-sm btn-soft-primary" id={`viewtooltip-${cellProps.row.original.id}`}>
                                    <i className="mdi mdi-eye-outline" />
                                </Link>
                            </li>
                            <UncontrolledTooltip placement="top" target={`viewtooltip-${cellProps.row.original.id}`}>
                                View
                            </UncontrolledTooltip>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        dispatch(setEditLocInfo(item))
                                        setModal(true)
                                        validation.setValues(item)
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        onClickDelete(item)
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className={`btn btn-sm btn-soft-${item.publish_status === '1' ? 'warning' : 'success'}`}
                                    onClick={() => { handlePublish(item) }}
                                    id={`publishtooltip-${item._id}`}
                                >
                                    <i className={`mdi ${item.publish_status === '1' ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline'}`} />
                                </Link>
                            </li>
                            <UncontrolledTooltip placement="top" target={`publishtooltip-${item._id}`}>
                                {item.publish_status === '1' ? 'Unpublish' : 'Publish'}
                            </UncontrolledTooltip>
                        </ul>
                    )

                }
            }

        ]
    )

    const handlePublish = async (item) => {
        sessionStorage.removeItem("hInfo")
        sessionStorage.setItem("hInfo", JSON.stringify(item));

        const response = await dispatch(getHdata());
        console.log('response', response)
        const treeData = response.hstructure;
        const totalHLength = response.lastGeneratedId;

        if (treeData?.length > 0) {
            Swal.fire({
                icon: 'question',
                title: 'Are you sure?',
                // text: `Do you want to ${item.publish_status === '0' ? 'publish' : 'unpublish'} this Location?`,
                text: `Do you want to ${item.publish_status === '0' ? 'publish' : 'unpublish'} ${item.hname} ?`,
                showCancelButton: true,
                confirmButtonColor: '#2ba92b',
                confirmButtonText: 'Yes',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No'
            }).then(async (result) => {
                if (!result.isConfirmed) return;
                try {
                    if (item.publish_status === '0') {
                        console.log('Tree Data:', treeData);
                        await publishHStructure(treeData, dispatch);
                    } else {
                        console.log('Unpublish Location');
                        await saveTreeData(treeData, totalHLength, dispatch);
                        await dispatch(retriveLocationData());
                    }

                    dispatch(retriveLocationData());
                    dispatch(setEditLocInfo(null));
                    dispatch(setLocationData([]));
                } catch (error) {
                    console.error('Error during publish:', error);
                }
            });
        } else {
            console.log('No Tree Data available to publish.');
        }
    };


    const fields = [
        { label: "Location Name :", name: "hname", placeholder: "Enter Location Name", mandatory: true, type: "text",name_exist : IR_OrgSlice.locationNameExist , message : IR_OrgSlice.locationNameExist ? "Location Name already exist" :""  },
    ];


  return (
    <React.Fragment>
    <div className="page-content">
        <Container fluid={true}>
        <Breadcrumbs title="Incident Location" breadcrumbItem="Incident Location" />

            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                                  <div className="d-flex align-items-center">
                                      <h5 className="mb-0 card-title flex-grow-1">Location List</h5>
                                      <div className="flex-shrink-0">
                                          <Link
                                              onClick={() => {
                                                dispatch(setEditLocInfo(null)); validation.resetForm();
                                                  setModal(true);
                                              }}
                                              className="btn btn-primary me-1">Add New Location</Link>
                                          <Link to="#!" className="btn btn-light me-1" onClick={() => {
                                            dispatch(setLocationData([]))
                                            dispatch(retriveLocationData())

                                          }}><i className="mdi mdi-refresh"></i></Link>
                                      </div>
                                  </div>
                        </CardBody>
                        {
                            IR_Location.locationData.length >0 ?
                            <CardBody>
                                <TableContainer
                                columns={columns}
                                data={IR_Location.locationData}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                isJobListGlobalFilter={false}
                                isPagination={true}
                                iscustomPageSizeOptions={true}
                                isShowingPageLength={true}
                                customPageSize={5}
                                tableClass="table-bordered align-middle nowrap mt-2"
                                paginationDiv="col-sm-12 col-md-7"
                                pagination="pagination justify-content-end pagination-rounded"
                                 />

                            </CardBody>
                                :
                                IR_Location.locationData.length === 0 && IR_Location.aplnRequestLoading === false ?
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    No Data
                                </div>
                                :
                                <CardBody>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <div>Loading...</div>
                                    <Spinner className="ms-2" color="primary" />
                                </div>
                            </CardBody>
                        }
                        {
                            modal&&
                            <ModalComponent
                            modal={modal}
                            toggle={() => { setModal(!modal) }}
                            onSubmit={validation.handleSubmit}
                            validation={validation}
                            fields={fields}
                            backDrop={"static"}
                            modalHeader={IR_Location.editLocInfo !== null ? "Edit Location Name" : "Add Location Name"}
                            btnName={IR_Location.editLocInfo !== null ? "Update" : "Save"}

                            />
                        }

                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
</React.Fragment>
  )
}

export default IRLocation
