import { createSlice } from "@reduxjs/toolkit";
import { put, post, get, del } from "helpers/api_helper";

const initialState={
    aplnRequestLoading: false,
    aplnRequestError: null,
    userList:[],
    roleList:[],
    // userMailIdExist : false,
    userMailIdExist : false,
    userNumExist : false,

    branchData: [],
    deptData: [],
    incdType:[],
    editUserInfo : null,
    HData :[]

}

// const authUser = JSON.parse(sessionStorage.getItem("authUser"))


const IR_UserSlice= createSlice({
    name: "IR_UserSlice",
    initialState: initialState,
    reducers: {
        setAplnRequestLoading: (state, action) => {
            state.aplnRequestLoading = action.payload
        },
        setAplnRequestError: (state, action) => {
            state.aplnRequestError = action.payload
        },
        setUserList: (state, action) => {
            state.userList = action.payload
        },
        setRoleList: (state, action) => {
            state.roleList = action.payload
        },
        setUserMailIdExist: (state, action) => {
            state.userMailIdExist = action.payload
        },
        setUserNumExist: (state, action) => {
            state.userNumExist = action.payload
        },
        setBranchData: (state, action) => {
            state.branchData = action.payload
        },
        setHData: (state, action) => {
            state.HData = action.payload
        },
        setDeptData: (state, action) => {
            state.deptData = action.payload
        },
        setIncdType: (state, action) => {
            state.incdType = action.payload
        },
        setEditUserInfo:(state,action)=>{
            state.editUserInfo = action.payload
        }
    }
})


export const retriveUserList=()=>{

    return async dispatch =>{
    var authUser = JSON.parse(sessionStorage.getItem("authUser"))
  
        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-user/retrive-user-list",{
                created_by : authUser.user_data._id,
                encrypted_db_url : authUser.db_info.encrypted_db_url,
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData');
            if(responseData.status === 200){
                dispatch(setUserList(responseData.data.data))
                dispatch(setRoleList(responseData.data.roles))
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestError(error))
        }


    }

}


export const validateRequired=()=>{

    return async dispatch =>{
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-user/validate-required-user-creation", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
            })
            console.log(responseData,'responseData')
            dispatch(setAplnRequestLoading(false))
            if(responseData.status === 200){
                return responseData
            }
            dispatch(setAplnRequestLoading(false))
            
        } catch (error) {
            dispatch(setAplnRequestError(error))
            
        }


    }

}



export const retriveUserInfo =(user_id)=>{

    return async dispatch =>{
    const authUser = JSON.parse(sessionStorage.getItem("authUser"))


        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-user/retrive-user-info", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                user_id
            })
            console.log(responseData,'encrypted_db_url')
            if(responseData.status === 200){
                return responseData.data.data
                // dispatch(setRoleList(responseData.data.data))
            }
            else{
                // dispatch(setAplnRequestError(responseData.data.message))
            }

            
        } catch (error) {
            console.log(error,'error');
            dispatch(setAplnRequestError(error))

        }

    }
}




const convertFlatDataToTreeData = (arr) => {

    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;
    console.log(arr,'arr')
    for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = {
            "value": arr[i]._id,
            "label": arr[i].hlevel_name,
            "children": arr[i].children,
            "id": arr[i].id,
            "parent": arr[i].parent,
            "node_id": arr[i]._id,
            "ep_level": arr[i].hlevel,
            "ep_level_value": arr[i].hlevel_value,
            "user_path": arr[i].user_path,
            "unique_users": arr[i].unique_users,
            "node_positon":arr[i].node_positon,
            "user_permission_acpln" : arr[i].user_permission_acpln,
            "code" : arr[i].hlevel_code ,
            "company_id" : arr[i].company_id ,
            "h_id":arr[i].h_id ,
            "_id":arr[i].h_id
          }

      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['children'] = []
    }
    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        if (mappedElem.parent) {
          if (mappedArr[mappedElem['parent']] !== undefined) {
            mappedArr[mappedElem['parent']]['children'].push(mappedElem);
          }
        }
        else {
          tree.push(mappedElem);
        }
      }
    }

    let update = () => obj => {
      if (obj.children.length > 0)
        obj.children.forEach(update());
      else
        obj.children = null
    };
    tree.forEach(update());
    return tree;
  }

export const getLocInfo = (h_id) => {

    return async dispatch => {
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("incident-report/get-flat-hstructure",{
                h_id : h_id,
                company_id : authUser.client_info[0].company_id,
                encrypted_db_url : authUser.db_info.encrypted_db_url,
            })
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                return responseData.data.data
                // convertFlatDataToTreeData(responseData.data.data)
            }
            dispatch(setAplnRequestLoading(false))
            

        } catch (error) {
            
        }

    }

}


export const retriveRoleData =()=>{

    return async dispatch =>{
    const authUser = JSON.parse(sessionStorage.getItem("authUser"))


        try {
            dispatch(setAplnRequestLoading(true))

            const responseData = await post("ir-role/ir-retrive-roles", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
            })
            console.log(responseData,'encrypted_db_url')
            if(responseData.status === 200){
                dispatch(setRoleList(responseData.data.data))
                dispatch(setBranchData(responseData.data.branchInfo))
                dispatch(setDeptData(responseData.data.deptInfo))
                dispatch(setIncdType(responseData.data.incd_type))
                dispatch(setHData(responseData.data.locInfo))
                // if(responseData.data.locInfo.length >0 ){
                // //    await dispatch(getLocInfo(responseData.data.locInfo[0]._id))
                // }
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))
            }

            
        } catch (error) {
            dispatch(setAplnRequestError(error))
            
        }
    }

}


export const createUserInfo =(values,navigate)=>{
    console.log(values,'values');
    return async dispatch =>{
    const authUser = JSON.parse(sessionStorage.getItem("authUser"))


        try {
            dispatch(setAplnRequestLoading(true))

            const responseData = await post("ir-user/crud-user-info", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                user_info : values
            })
            console.log(responseData,'encrypted_db_url')
            if(responseData.status === 200){
                if(navigate !== undefined){
                navigate("/manage-users")
                }
                dispatch(retriveUserList())
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))
            }

            
        } catch (error) {
            console.log(error,'error');
            dispatch(setAplnRequestError(error))
            
        }
    }

}









export const {
    setAplnRequestError,
    setAplnRequestLoading,
    setUserList,
    setRoleList,
    setUserMailIdExist,
    setBranchData,
    setDeptData,
    setIncdType,
    setHData,
    setEditUserInfo,
    setUserNumExist
} = IR_UserSlice.actions

export default IR_UserSlice.reducer
