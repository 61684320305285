import { createSlice } from "@reduxjs/toolkit";
import { put, post, get, del } from "helpers/api_helper";

const initialState={
    aplnRequestLoading: false,
    aplnRequestError: null,
    incdentType:[],
    incdAutoMateId:'',
    editIncdType:null
}

const authUser = JSON.parse(sessionStorage.getItem("authUser"))

const IrIncdTypeSlice= createSlice({
    name: "IrIncdTypeSlice",
    initialState: initialState,
    reducers: {
        setAplnRequestLoading: (state, action) => {
            state.aplnRequestLoading = action.payload
        },
        setAplnRequestError: (state, action) => {
            state.aplnRequestError = action.payload
        },
        setIncidentType :(state,action)=>{
            state.incdentType = action.payload
        },
        setIncdAutomateId:(state,action)=>{
            state.incdAutoMateId = action.payload
        },
        seteditIncdType:(state,action)=>{
            state.editIncdType = action.payload
        }
    }
})


export const retriveIncdType =()=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-incd-type/retrive-incidenttype-list",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                created_by : authUser.user_data._id
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                dispatch(setIncidentType(responseData.data.data))
                dispatch(setIncdAutomateId(responseData.data.nextId))
            }
            else{
                dispatch(setAplnRequestError(responseData.data.message))

            }
            
        } catch (error) {
            dispatch(setAplnRequestLoading(error))
            
        }

    }
}


export const crudIncidentType =(values)=>{

    return async dispatch =>{

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-incd-type/crud-incidenttype-info",{
                encrypted_db_url : authUser.db_info.encrypted_db_url,
                incident_info : values
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData,'responseData')
            if(responseData.status === 200){
                dispatch(retriveIncdType())
            }
            else{
            dispatch(setAplnRequestLoading(responseData.data.message))

            }
            
        } catch (error) {
            dispatch(setAplnRequestLoading(error))
            
        }

    }
}



export const deleteIncdType=(values)=>{

    return async dispatch => {

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-incd-type/delete-incidenttype", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                incd_id: values._id
            })
            console.log(responseData,'responseData');
            dispatch(setAplnRequestLoading(false))
            if (responseData.status === 200) {
                dispatch(retriveIncdType())
            }
            else {
                dispatch(setAplnRequestLoading(responseData.data.message))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }

    }


}






export const {
    setAplnRequestError,
    setAplnRequestLoading,
    setIncidentType,
    setIncdAutomateId,
    seteditIncdType
} = IrIncdTypeSlice.actions

export default IrIncdTypeSlice.reducer
