import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Form, Label, Input, FormFeedback } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";
import { useState } from "react";
import { userForgetPwd,setNewForgetPwd } from "../../toolkitStore/IncidentReport/IR_AuthSlice";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import AuthCode from "react-auth-code-input"




const ForgetPassword2 = () => {
  const dispatch = useDispatch()
  //meta title
  const [isPhone, setIsPhone] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [showPassword, setShowpassword] = useState(false)
  const [otp, setOtp] = useState('')
  const [userName, setuserName] = useState('')
  const [showSuccess,setShowSuccess]=useState(false)
  const [otpErr,setotpErr]=useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false);



  document.title="Forget Password | Incidentum";


  const countryCodes = [
    { code: "+1", name: "USA" },
    { code: "+44", name: "UK" },
    { code: "+91", name: "India" },
  ];


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      new_password: '',

    },
    validationSchema: () => {
      let schema = Yup.object().shape({
        username: Yup.string().required("Please Enter Your Email ID"),
      });
  
      if (showPassword) {
        schema = schema.shape({
          new_password: Yup.string().required("Please Enter Your New Password"),
        });
      }
  
      return schema;
    },
    onSubmit: async(values) => {
      console.log(values,'values');
      values["new_password"] = validation.values.new_password
      values["confirmationCode"] = otp
      values["short_name"]= sessionStorage.getItem('short_name')
      // if(isPhone){
      //   values["username"] = countryCode + values.username
      // }
      console.log(values,'values');
      values["isPhone"]= isPhone
      values["countryCode"]= countryCode
      if (showPassword === false ) {
        var responseInfo = await dispatch(userForgetPwd(values))
        console.log(responseInfo,'responseInfo');
        if(responseInfo.status === 200 &&responseInfo.data.code ==="200" ){
          setShowpassword(true)
          setuserName(data.data.username)
        }
        else if(responseInfo.data.data.error.code === "LimitExceededException"){
          Swal.fire({
            icon: 'warning',
            title: 'Exceed !',
            text: 'You have exceeded more than the Limit',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
          })
        }
        else{
          Swal.fire({
            icon: 'warning',
            title: 'Invalid !',
            text: 'Invalid Username not found',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
          })
        }
      }
      if (showPassword === true && values.new_password !== '' && values.confirmationCode !== undefined) {
        var responseInfo = await dispatch(setNewForgetPwd(values))
        console.log(responseInfo,'responseInfo');
        if(responseInfo.status=== 200){
          setShowSuccess(true)

        }
        else if( responseInfo.data.error.error.code === "CodeMismatchException"){
          Swal.fire({
            icon: 'warning',
            title: 'Invalid !',
            text: 'Invalid OTP you have entered,Please verify the OTP sent through email',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
          })

        }
      }

    }
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/\S+@\S+\.\S+/.test(value)) {
      setIsPhone(false);
    } else if (/^\d+$/.test(value)) {
      setIsPhone(true);
    } else {
      setIsPhone(false);
    }
    validation.setFieldValue("username", value);
  };





  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
    {
      !showSuccess &&
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Reset Password</h5>
                        <p className="text-muted">Re-Password with Incidentum.
                        </p>
                      </div>

                      <div className="mt-4">

                        {/* <div className="alert alert-success text-center mb-4" role="alert">
                          Enter your Email and instructions will be sent to you!
                        </div> */}

                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            {/* <Label className="form-label">Email</Label> */}
                            <Label className='m-0 fw-bold'>E-MAIL / MOBILE NUMBER :</Label>
                            {/* {!isPhone ? (
                                  <Input
                                    name="username"
                                    className="form-control"
                                    placeholder="Enter E-MAIL / MOBILE NUMBER"
                                    type="text"
                                    onChange={handleInputChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                    invalid={validation.touched.username && validation.errors.username ? true : false}
                                  />
                                ) : (
                                  <div className="d-flex">
                                    <Input
                                      type="select"
                                      className="form-control w-auto me-1"
                                      value={countryCode}
                                      onChange={(e) => setCountryCode(e.target.value)}
                                    >
                                      {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                          {country.name} ({country.code})
                                        </option>
                                      ))}
                                    </Input>
                                    <Input
                                      name="username"
                                      className="form-control"
                                      placeholder="Enter phone number"
                                      type="text"
                                      onChange={handleInputChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.username || ""}
                                      invalid={validation.touched.username && validation.errors.username ? true : false}
                                    />
                                  </div>
                                )}

                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}  */}

{/* {!isPhone ? (
                                <Input
                                  name="username"
                                  className="form-control"
                                  placeholder="Enter E-MAIL / MOBILE NUMBER"
                                  type="text"
                                  onChange={handleInputChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.username || ""}
                                  invalid={validation.touched.username && validation.errors.username ? true : false}
                                />
                              ) : (
                                <div className="d-flex">
                                  <Input
                                    type="select"
                                    className="form-control w-auto me-1"
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                  >
                                    {countryCodes.map((country) => (
                                      <option key={country.code} value={country.code}>
                                        {country.name} ({country.code})
                                      </option>
                                    ))}
                                  </Input>
                                  <Input
                                    name="username"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    type="text"
                                    onChange={handleInputChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                    invalid={validation.touched.username && validation.errors.username ? true : false}
                                  />
                                </div>
                              )}

                              {validation.touched.username && validation.errors.username ? (
                                <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                              ) : null} */}


<div className={`${isPhone ? 'd-flex' : ''}`} >
                                  {isPhone &&
                                    <Input
                                      type="select"
                                      className="form-control w-auto me-1"
                                      value={countryCode}
                                      onChange={(e) => setCountryCode(e.target.value)}
                                    >
                                      {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                          {country.name} ({country.code})
                                        </option>
                                      ))}
                                    </Input>
                                  }
                                  <Input
                                    name="username"
                                    className="form-control"
                                    placeholder="Enter Email ID / Phone Number"
                                    type={`${isPhone ? 'number' : 'text'}`}
                                    onChange={handleInputChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                    invalid={validation.touched.username && validation.errors.username ? true : false}
                                    min={isPhone ? "0" : undefined}
                                    // max={isPhone ? "10" : undefined}
                                  />
                                  {validation.touched.username && validation.errors.username ? (
                                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                  ) : null}
                                </div>


                                {
                                  showPassword &&
                                  <>
                                    <div style={{ position: "relative" }} className="mt-3">
                                      <h3 className="fontInter header-subtitle">Enter OTP</h3>

                                    </div>
                                    <p>We have sent one time password to <span className="text-primary">{userName}</span>. Never share your OTP with anyone.</p>
                                    <div className="text-center otpBox">
                                      <AuthCode
                                        characters={4}
                                        onChange={(otp) => {
                                          setOtp(otp);
                                          setotpErr(false); // Reset the error state when OTP changes
                                        }}
                                        className="form-control form-control-lg text-center otpInput"
                                        pattern="[0-9]*"
                                        required
                                        inputStyle={{
                                          padding: '7px',
                                          borderRadius: '0.4rem',
                                          fontSize: '1rem',
                                          textAlign: 'center',
                                          marginRight: '7px',
                                          border: '1px solid rgb(206, 212, 218)',
                                          textTransform: 'uppercase',
                                        }}
                                      />
                                    </div>
                                    {
                                      otpErr &&
                                      <label className="text-danger" style={{ fontSize: 'smaller' }}>Please Enter the OTP </label>
                                    }
                                    <br />
                                    <div className="mb-3">
                                      <Label className="form-label">New Password :</Label>
                                      {/* <Input
                                        name="new_password"
                                        className="form-control"
                                        placeholder="Enter New Password"
                                        type="password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.new_password || ""}
                                        invalid={
                                          validation.touched.new_password && validation.errors.new_password ? true : false
                                        }
                                        autoComplete='new-password'
                                      />

                                      {validation.touched.new_password && validation.errors.new_password ? (
                                        <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                                      ) : null} */}

<div className="input-group auth-pass-inputgroup">
                                        <Input
                                          name="new_password"
                                          className="form-control"
                                          placeholder="Enter New Password"
                                          type={showNewPassword ? "text" : "password"}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.new_password || ""}
                                          invalid={
                                            validation.touched.new_password && validation.errors.new_password ? true : false
                                          }
                                          autoComplete='new-password'
                                        />
                                        <button
                                          onClick={() => setShowNewPassword(!showNewPassword)}
                                          className="btn text-dark"
                                          style={{ background: 'gainsboro' }}
                                          type="button"
                                          id="password-addon"
                                        >
                                          <i className={showNewPassword ? "mdi mdi-eye-off" : "mdi mdi-eye-outline"}></i>
                                        </button>

                                        {validation.touched.new_password && validation.errors.new_password ? (
                                          <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                                        ) : null}
                                      </div>

                                    </div>
                                  </>
                                }
                          </div>
                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </Button>
                          </div>

                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Skote. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                    </div> */}
                       <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} designed and developed by eParampara</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
    }


            {
            showSuccess &&
            <Col xl={3}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <a href="/" className="d-block auth-logo">
                      {/* <img
                        src={auditvista_logo}
                        alt=""
                        height="30"
                        className="logo-dark-element"
                      /> */}
                    </a>
                  </div>
                  <div className="my-auto">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Success !</h4>
                        <title>Password changed successfully</title>
                        <p className="text-muted">
                        You can now log in to your account using your new password.
                        </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Back to Login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()}{" "} Incidentum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>



          }


          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword2;
