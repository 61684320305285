import React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { CardBody, Container, Row, Col, Card, Spinner, UncontrolledTooltip } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { retriveIncdType, setIncidentType, crudIncidentType,deleteIncdType, seteditIncdType } from 'toolkitStore/IncidentReport/IR_IncdTypeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import TableContainer from './Components/TableContainer'
import ModalComponent from './Components/Modal'
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from 'sweetalert2';
import { IRTvalidateDupName,setIncdTypNameExist } from 'toolkitStore/IncidentReport/IR_OrgSlice'
import store from 'store'


const IRIncdTypes = () => {
    const dispatch = useDispatch()
    const IR_IncdTypes = useSelector(state => state.IR_IncdTypeSlice)
    const [modal, setModal] = useState(false);
    const [authUser, setAuthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")));
    const [clientInfo, setclientInfo] = useState(JSON.parse(sessionStorage.getItem("client_info")))[0]
    const IR_OrgSlice =useSelector(state => state.IR_OrgSlice)
    console.log(IR_OrgSlice,'IR_OrgSlice',IR_IncdTypes)

    useEffect(() => {
        console.log("effect")
        dispatch(retriveIncdType())
    }, [])



    const validation = useFormik({
        initialValues: {
            incd_type_name: "" || IR_IncdTypes.editIncdType?.incd_type_name,
            incd_type_descrption: "" ||IR_IncdTypes.editIncdType?.incd_type_descrption,
            incd_type_id: IR_IncdTypes.incdAutoMateId
        },
        validationSchema: Yup.object({
            incd_type_name: Yup.string().required("Incident Type Name is required").test(async function (value) {
                console.log(store.getState(),'store');
                return !(await dispatch(IRTvalidateDupName(store.getState().IR_IncdTypeSlice.editIncdType, "cln_ir_incident_type_infos", "incd_type_name", value, setIncdTypNameExist)));

            })
            .matches(/\S+/, "Incident Type cannot be just spaces")
            .min(clientInfo.incd_rpt_validation.name_min, `Incident name must be at least ${ clientInfo.incd_rpt_validation.name_min } characters`),
            incd_type_descrption : Yup.string()
            .nullable() // Allows the field to be null
            .notRequired() // Field is not mandatory
            .test(
                'description-validation',
                'Description must be at least 10 characters and cannot be just spaces',
                function (value) {
                    if (!value) return true; // If no input, validation passes
                    const trimmedValue = value.trim();
                    return trimmedValue.length >= clientInfo.incd_rpt_validation.desc_min; // Applies validation only when input exists
                }
            ),

        
        }),
        onSubmit: (values) => {
            values["created_by"] = authUser.user_data._id
            console.log(values, 'values')
            if(IR_IncdTypes.editIncdType !== null){
                values["_id"]=IR_IncdTypes.editIncdType._id
            values["incd_type_id"]=IR_IncdTypes.editIncdType.incd_type_id
            }
            else{
                values["incd_type_id"]=IR_IncdTypes.incdAutoMateId 
            }
            dispatch(crudIncidentType(values))
            setModal(false)
        },
    });



    const onClickDelete = (item) => {

        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: `Do you want to delete this Incident Type ?`,
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                 dispatch(deleteIncdType(item))
            }


        })


    }




    const columns = useMemo(
        () => [
            {
                accessor: "incd_type_name",
                Header: "Incident Type Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.incd_type_name}
                        </div>

                    )
                }
            },
            {
                accessor: "createdAt",
                Header: "Created on",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="">
                            {new Intl.DateTimeFormat("en-GB", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }).format(new Date(item.createdAt))}
                        </div>

                    )
                }
            },
            {
                accessor: "Action",
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        dispatch(seteditIncdType(item))
                                        setModal(true)
                                        validation.setValues(item)
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        onClickDelete(item)
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    )

                }
            }

        ]
    )

    const fields = [
        { label: "Incident Type Name :", name: "incd_type_name", placeholder: "Incident Type Name", mandatory: true, type: "text" ,name_exist : IR_OrgSlice.incdTypeNameExist , message : IR_OrgSlice.incdTypeNameExist ? "Incident Type already exist" :""  },
        { label: "Description :", name: "incd_type_descrption", placeholder: "Enter Description", mandatory: false, type: "textarea" },
    ];



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Incident Types" breadcrumbItem="Incident Types" />

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Incident Types</h5>
                                        <div className="flex-shrink-0">
                                            <Link
                                                onClick={() => {
                                                    setModal(true);
                                                    dispatch(seteditIncdType(null))
                                                    validation.resetForm()
                                                }}
                                                className="btn btn-primary me-1">Add New Incident Type</Link>
                                            <Link to="#!" className="btn btn-light me-1" onClick={() => {
                                                dispatch(setIncidentType([]));
                                                dispatch(retriveIncdType())

                                            }}><i className="mdi mdi-refresh"></i></Link>
                                        </div>
                                    </div>
                                </CardBody>
                                {
                                    IR_IncdTypes.incdentType.length > 0 ?

                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={IR_IncdTypes.incdentType}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                isPagination={true}
                                                iscustomPageSizeOptions={true}
                                                isShowingPageLength={true}
                                                customPageSize={5}
                                                tableClass="table-bordered align-middle nowrap mt-2"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination justify-content-end pagination-rounded"
                                            />

                                        </CardBody>
                                        :

                                        <CardBody>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>

                                        </CardBody>
                                }
                                {
                                    modal &&
                                    <ModalComponent
                                        modal={modal}
                                        toggle={() => { setModal(!modal) }}
                                        onSubmit={validation.handleSubmit}
                                        validation={validation}
                                        fields={fields}
                                        backDrop={"static"}
                                        modalHeader={IR_IncdTypes.editIncdType !== null ? "Edit Incident Type" : "Add Incident Type"}
                                        btnName={IR_IncdTypes.editIncdType !== null ? "Update" : "Save"}
                                    />
                                }

                            </Card>

                        </Col>

                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default IRIncdTypes
