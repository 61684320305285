import React, { useEffect, useMemo, useState } from 'react'
import {
    Row, Col, FormGroup, Spinner, Button, Card, CardBody, Container, Alert, Label, Form, Progress,
    Input,
    FormFeedback
} from "reactstrap";
import { useDispatch } from 'react-redux';
import { retriveUserList, setLocUserList,addNodeUsers } from 'toolkitStore/IncidentReport/IR_LocationSlice';
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from 'react-redux';
import TableContainer from './TableContainer';
import store from 'store';

const UserListForm = (props) => {

    const dispatch = useDispatch()
    const IrLocationSlice = useSelector(state => state.IR_LocationSlice)
    const state = useSelector(state => state.IncidentHtreeData);
    const [dataLoaded,setDataLoaded] = useState(true)
    console.log(state,'state',store.getState());

    const incdType = (state.incident_type || []).map(item => ({ inc_id: item._id, inc_name: item.incd_type_name }));
    const [selectedValues, setSelectedValues] = useState([]);

    const handleSelect = (selectedList, rowId) => {
        setSelectedValues(prevSelectedValues => ({
            ...prevSelectedValues,
            [rowId]: selectedList 
        }));
    };

    const handleRemove = (selectedList, rowId) => {
        setSelectedValues(prevSelectedValues => ({
            ...prevSelectedValues,
            [rowId]: selectedList 
        }));
    };



    useEffect(() => {
        console.log("object");
        // var user_id = _.map(state.nodeUsers,"_id")
        if(state.nodeUsers.length >0){
            state.nodeUsers.map((ele,idx)=>{
                handleSelectOne(ele)
            })
        }

    }, [])

    const handleSelectAll = () => {
        const locUserList = _.cloneDeep(store.getState().IR_LocationSlice.locUserList)

        const allSelected = locUserList.every(row => row.selected);
        const updatedRows = locUserList.map(row => ({
            ...row,
            selected: !allSelected
        }));
        console.log(updatedRows, 'updatedRows')
        dispatch(setLocUserList(updatedRows))
        setDataLoaded(false)
        setTimeout(() => {
        setDataLoaded(true)
        }, 200);

    };


    const handleSelectOne = (row) => {
        // const locUserList = _.cloneDeep(IrLocationSlice.locUserList)
        const locUserList = _.cloneDeep(store.getState().IR_LocationSlice.locUserList)

        var getIdx = _.findIndex(locUserList, { _id: row._id })
        console.log(getIdx, 'getIdx');
        if (getIdx !== -1) {
            locUserList[getIdx]["selected"] = !row.selected
        }
        console.log(locUserList, 'locUserList', getIdx);
        dispatch(setLocUserList(locUserList))
        setDataLoaded(false)
        setTimeout(() => {
        setDataLoaded(true)
        }, 200);

    }



    const columns =
        [
            {
                Header: ({ rows }) => <div className="form-check font-size-16" >
                    <input className="form-check-input"
                        // onClick={handleSelectAll}
                        onChange={() => {
                            handleSelectAll()
                        }}
                        type="checkbox" checked={rows.length > 0 && rows.every((row) => row.original.selected)} id="checkAll" />
                        {
                            console.log(rows,'rows')
                        }
                    <label className="form-check-label" htmlFor="checkAll"></label>
                </div>,
                accessor: '#',
                width: '20px',
                filterable: true,
                Cell: ({ row }) => (
                    <div className="form-check font-size-16" >
                        <input className="form-check-input" checked={row.original.selected} onChange={(e) => {
                            handleSelectOne(row.original)
                        }} type="checkbox" id="checkAll" />
                        <label className="form-check-label" htmlFor="checkAll"></label>
                    </div>
                )
            },
            {
                accessor: "firstname",
                Header: "First Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.firstname}
                        </div>

                    )
                }
            },
            {
                accessor: "role_name",
                Header: "Role Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.role_name ? item.role_name : "-"}
                        </div>

                    )
                }
            },
            {
                accessor: "email_id",
                Header: "Email ID",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.email_id ? item.email_id : "-"}
                        </div>

                    )
                }
            },
            {
                accessor: "inc_type",
                Header: "Incident Type",
                filterable: true,
                Cell: ({ row }) => {
                    const item = row.original;
                    return (
                        <div>
                            <Multiselect
                                disable={!item.selected}
                                options={incdType}
                                selectedValues={selectedValues[item._id] || []}
                                onSelect={(selectedList) => handleSelect(selectedList, item._id)}
                                onRemove={(selectedList) => handleRemove(selectedList, item._id)}
                                displayValue="inc_name"
                                placeholder={selectedValues[item._id]?.length > 0 ? '' : 'Select incident type'}
                                avoidHighlightFirstOption={true}
                            />
                        </div>
                    );
                }
            }
        ]


    const addUserNode = () => {
        // const locUserList = _.cloneDeep(IrLocationSlice.locUserList)
        const locUserList = _.cloneDeep(store.getState().IR_LocationSlice.locUserList)

        // var selectedInfo = _.filter(locUserList, { selected: true })

        const selectedUsers = locUserList
            .filter(user => user.selected)
            .map(user => ({
                ...user, 
                incidentTypes: selectedValues[user._id] || [] 
            }));
    

        console.log('selectedInfo',selectedValues,selectedUsers)
        // var selectedId = _.map(selectedInfo, "_id")
        // console.log(selectedId, 'selectedId');
        dispatch(addNodeUsers(selectedUsers,state.nodeInfo,"1"))

    }

if(dataLoaded){
    return (
        <React.Fragment>
            <Container fluid>
                <Card >
                    <CardBody className="pt-0">
                        <Row >
                            <Col >
                                <div className="p-2 mb-3">
                                    {
                                        IrLocationSlice.locUserList.length > 0 ?
                                            <TableContainer
                                                columns={columns}
                                                data={IrLocationSlice.locUserList}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                isPagination={true}
                                                iscustomPageSizeOptions={props.pageLimit}
                                                isShowingPageLength={true}
                                                customPageSize={5}
                                                tableClass="table-bordered align-middle nowrap mt-2"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination justify-content-end pagination-rounded"
                                            />
                                            :
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                    }

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button onClick={() => {
                                    addUserNode()
                                }} className='btn btn-success'>Submit</button>

                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </React.Fragment>
    )
}
else{
    return null
}
}

export default UserListForm
