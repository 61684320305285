import React from 'react'
import { useEffect,useState,useMemo } from 'react'
import { CardBody, Container, Row, Col, Card,Spinner,UncontrolledTooltip } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux'
import { crudSevrtType, deleteSevrtType, retriveSevrtType, seteditSevrtyType, setSeverityType } from 'toolkitStore/IncidentReport/IR_SeveritySlice'
import { useSelector } from 'react-redux'
import TableContainer from './Components/TableContainer'
import ModalComponent from './Components/Modal'
import store from 'store'
import { IRTvalidateDupName,setSevrtTypNameExist } from 'toolkitStore/IncidentReport/IR_OrgSlice'



const IRSeverity = () => {
    const dispatch = useDispatch()
    const IR_SeveritySlice = useSelector(state => state.IR_SeveritySlice)
    const IR_OrgSlice = useSelector(state => state.IR_OrgSlice)
    const [modal, setModal] = useState(false);
    const [authUser, setAuthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")));
    console.log(IR_SeveritySlice,'IR_SeveritySlice',IR_OrgSlice);


    useEffect(() => {
        console.log("effect")
        dispatch(retriveSevrtType())
    }, [])


    const onClickDelete = (item) => {

        Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: `Do you want to delete this Severity Type ?`,
            showCancelButton: true,
            confirmButtonColor: '#2ba92b',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                 dispatch(deleteSevrtType(item))
            }
        })
    }


    const validation = useFormik({
        initialValues: {
            severity_name: "",
            severity_descrption: "",
            severity_id: IR_SeveritySlice.sevrtyAutoMateId
        },
        validationSchema: Yup.object({
            severity_name: Yup.string().required("Severity Type Name is required").test(async function (value) {
                console.log(store.getState(),'values');
                return !(await dispatch(IRTvalidateDupName(store.getState().IR_SeveritySlice.editSevrtyType, "cln_ir_severity_type_infos", "severity_name", value, setSevrtTypNameExist)));
                
            }),
        }),
        onSubmit: (values) => {
            console.log(values, 'values')
            values["created_by"] = authUser.user_data._id
            values["severity_id"] = IR_SeveritySlice.sevrtyAutoMateId
            if(IR_SeveritySlice.editSevrtyType !== null){
                values["_id"]=IR_SeveritySlice.editSevrtyType._id
                values["severity_id"]=IR_SeveritySlice.editSevrtyType.severity_id
            }
            else{
                values["severity_id"]=IR_SeveritySlice.sevrtyAutoMateId
            }
            dispatch(crudSevrtType(values))
            setModal(false)


        },
    });


    const columns = useMemo(
        () => [
            {
                accessor: "severity_name",
                Header: "Severiy Type Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.severity_name}
                        </div>

                    )
                }
            },
            {
                accessor: "createdAt",
                Header: "Created on",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="">
                            {new Intl.DateTimeFormat("en-GB", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }).format(new Date(item.createdAt))}
                        </div>

                    )
                }
            },
            {
                accessor: "Action",
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        dispatch(seteditSevrtyType(item))
                                        setModal(true)
                                        validation.setValues(item)
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        onClickDelete(item)
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    )

                }
            }

        ]
    )



    const fields = [
        { label: "Severity Type Name :", name: "severity_name", placeholder: "Severity Type Name", mandatory: true, type: "text",name_exist : IR_OrgSlice.sevrtTypeNameExist , message : IR_OrgSlice.sevrtTypeNameExist ? "Severity Type already exist" :""  },
        { label: "Description :", name: "severity_descrption", placeholder: "Enter Description", mandatory: false, type: "textarea" },
    ];


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Severity Types" breadcrumbItem="Severity Types" />

                    <Row>
                        <Col md={12}>
                            <Card>

                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Severity Types</h5>
                                        <div className="flex-shrink-0">
                                            <Link
                                                onClick={() => {
                                                    setModal(true);
                                                    dispatch(seteditSevrtyType(null))
                                                    validation.resetForm()
                                                }}
                                                className="btn btn-primary me-1">Add New Severity Type</Link>
                                            <Link to="#!" className="btn btn-light me-1" onClick={() => {
                                                dispatch(setSevrtTypNameExist(false))
                                                dispatch(setSeverityType([]));
                                                dispatch(retriveSevrtType())

                                            }}><i className="mdi mdi-refresh"></i></Link>
                                        </div>
                                    </div>
                                </CardBody>
                                {
                                    IR_SeveritySlice.severityType.length > 0 ?

                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={IR_SeveritySlice.severityType}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                isPagination={true}
                                                iscustomPageSizeOptions={true}
                                                isShowingPageLength={true}
                                                customPageSize={5}
                                                tableClass="table-bordered align-middle nowrap mt-2"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination justify-content-end pagination-rounded"
                                            />

                                        </CardBody>
                                        :

                                        <CardBody>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>
                                        </CardBody>
                                }
                                 {
                                    modal &&
                                    <ModalComponent
                                        modal={modal}
                                        toggle={() => { setModal(!modal) }}
                                        onSubmit={validation.handleSubmit}
                                        validation={validation}
                                        fields={fields}
                                        backDrop={"static"}
                                        modalHeader={IR_SeveritySlice.editSevrtyType !== null ? "Edit Severity Type" : "Add Severity Type"}
                                        btnName={IR_SeveritySlice.editSevrtyType !== null ? "Update" : "Save"}
                                    />
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default IRSeverity
