import { createSlice } from "@reduxjs/toolkit";
import { put, post, get, del } from "helpers/api_helper";

const initialState = {
    aplnRequestLoading: false,
    aplnRequestError: null,
    clientData: null,
    authLoad: false,
    accActivationErr: false,
    invalidCredentials: false,
    invalidErrMsg: "",
    userFacilities: [],
    showVerifyPwd: false,
    pwdSession : null,
    updateMsg : false

}

const IrSlice = createSlice({
    name: "IncdReportSlice",
    initialState: initialState,
    reducers: {
        setAplnRequestLoading: (state, action) => {
            state.aplnRequestLoading = action.payload
        },
        setAplnRequestError: (state, action) => {
            state.aplnRequestError = action.payload
        },
        setClientData: (state, action) => {
            state.clientData = action.payload
        },
        setauthLoad: (state, action) => {
            state.authLoad = action.payload
        },
        setaccActivationErr: (state, action) => {
            state.accActivationErr = action.payload
        },
        setInvalidCredentials: (state, action) => {
            state.invalidCredentials = action.payload
        },
        setInvalidErrMsg: (state, action) => {
            state.invalidErrMsg = action.payload
        },
        setUserFacilities: (state, action) => {
            state.userFacilities = action.payload
        },
        setshowVerifyPwd:(state,action)=>{
            state.showVerifyPwd = action.payload
        },
        setPwdSession:(state,action)=>{
            state.pwdSession = action.payload
        },
        setUpdtMsg:(state,action)=>{
            state.updateMsg = action.payload
        }

    }
})


export const retriveUserFacilities = () => {

    return async dispatch => {

        try {
            dispatch(setAplnRequestLoading(true))
            const authUser = JSON.parse(sessionStorage.getItem("authUser"))
            const responseData = await post("ir-auth/get-role-facilities", {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                user_data: authUser.user_data
            })
            dispatch(setAplnRequestLoading(false))
            console.log(responseData, 'responseData')
            if (responseData.status === 200 && responseData.data.data.length > 0) {
                dispatch(setUserFacilities(responseData.data.data[0].facilities))
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))
        }


    }
}




export const verifyShortName = (navigate) => {

    return async dispatch => {

        try {
            dispatch(setAplnRequestLoading(true))
            const reponseData1 = await post("ir-auth/fetch-client-info",{short_name:"novac"})
            console.log(reponseData1,'reponseData1');

            const reponseData = await post("ir-auth/find-short-name")
            dispatch(setAplnRequestLoading(false))
            console.log(reponseData, 'reponseData')
            if (reponseData.status === 200 && reponseData.data.data) {
                dispatch(setClientData(reponseData.data.data))
                sessionStorage.setItem('short_name', reponseData.data.data.short_name)
                navigate("/login")
            }

            else {
                sessionStorage.clear()
                navigate("/url-not-found")
            }

        } catch (error) {
            dispatch(setAplnRequestLoading(error))

        }
    }

}


export const userAuthenticate = (values, navigate) => {

    return async dispatch => {

        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-auth/login-authenticate", values)
            console.log(responseData, 'responseData');
            dispatch(setAplnRequestLoading(false))
            dispatch(setauthLoad(false))
            if (responseData.status === 200 && (responseData.data.message === "new password required" || responseData.data.user_data !== undefined)) {
                if(responseData.data.message === "new password required"){
                    dispatch(setshowVerifyPwd(true))
                    dispatch(setPwdSession(responseData.data.data.Session))
                }
                else{
                    dispatch(setshowVerifyPwd(false))
                    return responseData.data;
                }
            }
            else if (responseData.data.error === "404db") {
                dispatch(setaccActivationErr(true))
                dispatch(setInvalidCredentials(false))

                setTimeout(() => {
                    dispatch(setaccActivationErr(false))
                }, 3000);

                return null
            }
            else if (responseData.data.code=== 204) {
                if (Object.keys(responseData.data.error).length === 0) {
                    dispatch(setInvalidCredentials(true))
                    dispatch(setaccActivationErr(false))
                    setTimeout(() => {
                        dispatch(setInvalidCredentials(false))
                    }, 3000);
                    return null
                }
                else {
                    dispatch(setInvalidErrMsg(responseData.data.message))
                    setTimeout(() => {
                        dispatch(setInvalidErrMsg(""))
                    }, 3000);
                }
            }
            else {
                navigate("/login")
                return null
            }


        } catch (error) {
            console.log(error,'error');
            dispatch(setAplnRequestLoading(error))
        }
    }

}


export const userForgetPwd=(values)=>{

    return async dispatch =>{
 
        try {
            console.log(values,'values');
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-auth/forget-password", values)
            console.log(responseData, 'responseData');
            dispatch(setAplnRequestLoading(false))
            return responseData
        } catch (error) {
            console.log(error,'error');
            dispatch(setAplnRequestLoading(error))
        }
    }
}



export const setNewForgetPwd=(values)=>{

    return async dispatch =>{
 
        try {
            console.log(values,'values');
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-auth/confirm-reset-password", values)
            console.log(responseData, 'responseData');
            dispatch(setAplnRequestLoading(false))
            return responseData
        } catch (error) {
            console.log(error,'error');
            dispatch(setAplnRequestLoading(error))
        }
    }
}


export const confirmNewPwd=(values)=>{

    return async dispatch =>{
       
        try {
            dispatch(setAplnRequestLoading(true))
            const responseData = await post("ir-auth/confrm-new-pwd", values)
            dispatch(setAplnRequestLoading(false))
            if(responseData.status === 200){
                dispatch(setUpdtMsg(true))
                setTimeout(() => {
                    dispatch(setUpdtMsg(false))
                    dispatch(setshowVerifyPwd(false))

                }, 3000);
            }
            
        } catch (error) {
            dispatch(setAplnRequestLoading(error))
            
        }
    }


}








export const {
    setAplnRequestError,
    setAplnRequestLoading,
    setClientData,
    setauthLoad,
    setInvalidErrMsg,
    setaccActivationErr,
    setInvalidCredentials,
    setUserFacilities,
    setshowVerifyPwd,
    setPwdSession,
    setUpdtMsg
} = IrSlice.actions
export default IrSlice.reducer




