import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { useDispatch, useSelector } from "react-redux";
import { retriveUserFacilities } from "toolkitStore/IncidentReport/IR_AuthSlice";
import { withTranslation } from "react-i18next";
import _ from "lodash";

const SidebarContent = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const IrSlice = useSelector((state) => state.IR_Slice);
  const menuData = IrSlice.userFacilities;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(retriveUserFacilities())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user facilities", error);
        setLoading(false);
      });
  }, [dispatch]);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    let parent = item.parentElement;

    while (parent && parent.id !== "side-menu") {
      parent.classList.add("mm-show");
      parent.classList.add("mm-active");
      parent = parent.parentElement;
    }
    scrollElement(item);
  }, []);

  const removeActivation = (items) => {
    for (let item of items) {
      item.classList.remove("active");
      let parent = item.parentElement;

      while (parent && parent.id !== "side-menu") {
        parent.classList.remove("mm-active", "mm-show");
        parent = parent.parentElement;
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    const ul = document.getElementById("side-menu");

    if (!ul) {
      return;
    }

    const items = ul.getElementsByTagName("a");

    removeActivation(items);

    for (let item of items) {
      if (pathName === item.pathname) {
        activateParentDropdown(item);
        break;
      }
    }
  }, [path.pathname, activateParentDropdown]);

  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };

  useEffect(() => {
    if (!loading && menuData.length > 0) {
      const metis = new MetisMenu("#side-menu");

      return () => {
        metis.dispose();
      };
    }
  }, [loading, menuData]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menuData &&
              menuData.map((item, index) => {
                if (item.type === "group" && item.active === true) {
                  const getSubMenu = _.filter(menuData, { parent: item.id, active: true });
                  return (
                    <li key={index}>
                      <Link to={"/" + item.url} className="has-arrow">
                        <i className={item.icon}></i>
                        <span>{props.t(item.menu)}</span>
                      </Link>
                      {getSubMenu.length > 0 && (
                        <ul className="sub-menu">
                          {getSubMenu.map((subItem, ind) => (
                            <li key={"sbmnu" + ind}>
                              <Link to={"/" + subItem.url}>{props.t(subItem.menu)}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                } else if (item.parent === null && item.type === "menu" && item.active === true) {
                  return (
                    <li key={"nll" + index}>
                      <Link to={"/" + item.url}>
                        <i className={item.icon}></i>
                        <span>{props.t(item.menu)}</span>
                      </Link>
                    </li>
                  );
                }
              })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(SidebarContent);











//  14-oct-2024
// import React, { useEffect, useRef, useCallback } from "react";
// import { useLocation } from "react-router-dom";
// import PropTypes from "prop-types";


// // //Import Scrollbar
// import SimpleBar from "simplebar-react";

// // MetisMenu
// import MetisMenu from "metismenujs";
// import withRouter from "components/Common/withRouter";
// import { Link } from "react-router-dom";
// import { retriveUserFacilities } from "toolkitStore/IncidentReport/IR_AuthSlice";
// import { useDispatch ,useSelector} from "react-redux";

// //i18n
// import { withTranslation } from "react-i18next";

// const SidebarContent = props => {
//   const ref = useRef();
//   const dispatch = useDispatch()
//   const IrSlice = useSelector(state => state.IR_Slice)
//   const menuData = IrSlice.userFacilities

//   useEffect(()=>{
//     console.log("side bar")
//     dispatch(retriveUserFacilities())
    
//   },[])

//   const activateParentDropdown = useCallback((item) => {
//     item.classList.add("active");
//     const parent = item.parentElement;
//     const parent2El = parent.childNodes[1];

//     if (parent2El && parent2El.id !== "side-menu") {
//       parent2El.classList.add("mm-show");
//     }

//     if (parent) {
//       parent.classList.add("mm-active");
//       const parent2 = parent.parentElement;

//       if (parent2) {
//         parent2.classList.add("mm-show"); // ul tag

//         const parent3 = parent2.parentElement; // li tag

//         if (parent3) {
//           parent3.classList.add("mm-active"); // li
//           parent3.childNodes[0].classList.add("mm-active"); //a
//           const parent4 = parent3.parentElement; // ul
//           if (parent4) {
//             parent4.classList.add("mm-show"); // ul
//             const parent5 = parent4.parentElement;
//             if (parent5) {
//               parent5.classList.add("mm-show"); // li
//               parent5.childNodes[0].classList.add("mm-active"); // a tag
//             }
//           }
//         }
//       }
//       scrollElement(item);
//       return false;
//     }
//     scrollElement(item);
//     return false;
//   }, []);

//   const removeActivation = (items) => {
//     for (var i = 0; i < items.length; ++i) {
//       var item = items[i];
//       const parent = items[i].parentElement;

//       if (item && item.classList.contains("active")) {
//         item.classList.remove("active");
//       }
//       if (parent) {
//         const parent2El =
//           parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
//             ? parent.childNodes[1]
//             : null;
//         if (parent2El && parent2El.id !== "side-menu") {
//           parent2El.classList.remove("mm-show");
//         }

//         parent.classList.remove("mm-active");
//         const parent2 = parent.parentElement;

//         if (parent2) {
//           parent2.classList.remove("mm-show");

//           const parent3 = parent2.parentElement;
//           if (parent3) {
//             parent3.classList.remove("mm-active"); // li
//             parent3.childNodes[0].classList.remove("mm-active");

//             const parent4 = parent3.parentElement; // ul
//             if (parent4) {
//               parent4.classList.remove("mm-show"); // ul
//               const parent5 = parent4.parentElement;
//               if (parent5) {
//                 parent5.classList.remove("mm-show"); // li
//                 parent5.childNodes[0].classList.remove("mm-active"); // a tag
//               }
//             }
//           }
//         }
//       }
//     }
//   };

//   const path = useLocation();
//   const activeMenu = useCallback(() => {
//     const pathName = path.pathname;
//     let matchingMenuItem = null;
//     const ul = document.getElementById("side-menu");
//     const items = ul.getElementsByTagName("a");
//     removeActivation(items);

//     for (let i = 0; i < items.length; ++i) {
//       if (pathName === items[i].pathname) {
//         matchingMenuItem = items[i];
//         break;
//       }
//     }
//     if (matchingMenuItem) {
//       activateParentDropdown(matchingMenuItem);
//     }
//   }, [path.pathname, activateParentDropdown]);

//   useEffect(() => {
//     ref.current.recalculate();
//   }, []);

//   useEffect(() => {
//     new MetisMenu("#side-menu");
//     activeMenu();
//   }, [menuData]);

//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: 'smooth' });
//     activeMenu();
//   }, [activeMenu]);

//   function scrollElement(item) {
//     if (item) {
//       const currentPosition = item.offsetTop;
//       if (currentPosition > window.innerHeight) {
//         ref.current.getScrollElement().scrollTop = currentPosition - 300;
//       }
//     }
//   }

//   return (
//     <React.Fragment>
//       <SimpleBar className="h-100" ref={ref}>
//         <div id="sidebar-menu">
//           <ul className="metismenu list-unstyled" id="side-menu">


//             {
//               menuData && menuData.map((item, index) => {
//                 if (item.type === "group" && item.active === true) {
//                   var getSubMenu = _.filter(menuData, { parent: item.id , active : true })
//                   return (
//                     <li key={index}>
//                       <Link to={"/" + item.url} className="has-arrow ">
//                         <i className={item.icon}></i>
//                         <span>{props.t(item.menu)}</span>
//                       </Link>
//                       {
//                         getSubMenu.length > 0 && <ul className="sub-menu">
//                           {
//                             getSubMenu.map((subItem, ind) => {
//                               return (

//                                 <li key={"sbmnu" + ind}>
//                                   <Link to={"/" + subItem.url}>{props.t(subItem.menu)}</Link>
//                                 </li>

//                               )
//                             })

//                           }
//                         </ul>
//                       }
//                     </li>
//                   )
//                 }
//                 else if (item.parent === null && item.type === "menu" && item.active === true) {
//                   return <li key={"nll" + index}>
//                     <Link to={"/" + item.url} >
//                       <i className={item.icon}></i>
//                       <span>{props.t(item.menu)}</span>
//                     </Link>
//                   </li>
//                 }
//               })
//             }

//           </ul>
//         </div>
//       </SimpleBar>
//     </React.Fragment>
//   );
// };

// SidebarContent.propTypes = {
//   location: PropTypes.object,
//   t: PropTypes.any,
// };

// export default withRouter(withTranslation()(SidebarContent));
