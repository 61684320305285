// import React from 'react';
// import { useEffect, useState, useHistory } from 'react';
// import {
//   Row,
//   Col,
//   Card,
//   CardBody,
//   FormGroup,
//   Button,
//   CardTitle,
//   CardSubtitle,
//   Label,
//   Input,
//   Container,
//   FormFeedback,
//   Form,
//   // Tag
// } from "reactstrap";
// import { Switch, Checkbox } from 'antd'
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import { _ } from 'core-js';
// import Swal from 'sweetalert2';
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import axios from 'axios'

// var urlSocket = axios.create({
//   baseURL: 'https://auditvista.com/audit-vista-v2-api/',
//   mode: 'no-cors',
//   headers: {
//     'Access-Control-Allow-Origin': '*',
//     'Content-Type': 'application/json',
//     Authorization: `${process.env.REACT_APP_APIKEY}`,
//   },
//   timeout: 0,
// })
// const propTypes = {};

// const defaultProps = {};

// const IRAddRoles = (props) => {
//   const [screenInfo, setScreenInfo] = useState([])
//   const [editData, setEditData] = useState({})
//   const [userInfo, setUserInfo] = useState({})
//   const [readCheckAll, setReadCheckAll] = useState(false)
//   const [writeCheckAll, setWriteCheckAll] = useState(false)
//   const [RoleStatus, setRoleStatus] = useState(true)
//   const [dbUrl, setDbUrl] = useState('')
//   const [roleNameErr, setRoleNameErr] = useState(false)
//   const [userConfig, setUserConfig] = useState({
//     approver: false,
//     report_viewer: false,
//   })

//   const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))

//   const [oncreate, setOncreate] = useState(true)
//   const [protect, setProtect] = useState({})

//   const get_user_facilities = async (user_data, db_info) => {
//     try {
//       const response = await urlSocket.post('cog/get-role-facilities', {
//         encrypted_db_url: db_info.encrypted_db_url,
//         user_data: user_data,
//       });
//       if (response.data.response_code === 500) {
//         return response.data.data[0].facilities; // Return the response instead of 'facilities'
//       }
//     } catch (error) {
//       return null; 
//     }
//   };


//   useEffect(() => {

//     var data = JSON.parse(sessionStorage.getItem('authUser'))
//     var role_id = sessionStorage.getItem('role_id')
//     setUserInfo(data.user_data)
//     var protect_routes = {}
//     var user_facilities
//     var encrypted_db_url = data.db_info.encrypted_db_url
//     setDbUrl(encrypted_db_url)
//     if (protect_routes !== undefined) {
//       try {
//         urlSocket.post('cog/get-screen-facilities', { encrypted_db_url: encrypted_db_url, user_id: userInfo._id }).then(async (response) => {
//           console.log(response, 'response')
//           var facility_array = response.data.data[0].facilities
//           console.log(facility_array, 'facility_array', data.facility_data);
//           user_facilities = await get_user_facilities(authUser.user_data, authUser.db_info)
//           console.log(user_facilities, 'protect_routes')

//           facility_array.map((data, idx) => {
//             if (data.facility_id == "1" || data.facility_id == "11" || data.facility_id == "12") {
//               facility_array[idx].read_write_checked = true
//             }
//           })

//           const updatedArr = facility_array.map(item => {
//             const match = user_facilities.find(el => el.facility_id === item.facility_id);
//             if (!match) {
//               return {
//                 ...item,
//                 read_checked: false,
//                 read_write_checked: false
//               };
//             }
//             return item;
//           });
//           console.log(updatedArr, 'updatedArr');

//           if (response.data.response_code === 500) {
//             if (role_id !== undefined && role_id !== null && role_id !== '') {
//               setOncreate(false)
//               get_role_info(role_id, data.db_info.encrypted_db_url, response.data.data[0].facilities)
//               setProtect(protect_routes)
//             }
//             else {
//               setOncreate(true)
//               setScreenInfo(updatedArr)
//             }
//           }
//         })

//       } catch (error) {

//       }
//     }
//     else {
//       window.location.href = '/dashboard'
//     }
//   }, [])


//   const get_role_info = (data, encrypted_db_url, state_data) => {

//     try {
//       urlSocket.post('cog/get-role-info', { role_id: data, encrypted_db_url: encrypted_db_url, user_id: userInfo._id }).then((response) => {
//         console.log(response, 'response')
//         if (response.data.response_code === 500) {
//           setEditData(response.data.data[0])
//           response.data.data[0].role_status === true ? setRoleStatus(true) : setRoleStatus(false)
//           var facilities = response.data.data[0].facilities
//           const userConfigInfo = _.cloneDeep(userConfig)
//           userConfigInfo["approver"] = response.data.data[0].approver
//           userConfigInfo["report_viewer"] = response.data.data[0].report_viewer
//           console.log(userConfigInfo, 'userConfigInfo');
//           setUserConfig(userConfigInfo)
//           console.log(state_data, 'state_data', facilities)
//           state_data.map((data, idx) => {
//             facilities.map((ele, idx1) => {
//               if (data.id === ele.id) {
//                 state_data[idx] = facilities[idx1]
//               }
//             })
//           })
//           const updatedArr = state_data.map(item => {
//             const match = facilities.find(el => el.facility_id === item.facility_id);
//             if (!match) {
//               return {
//                 ...item,
//                 read_checked: false,
//                 read_write_checked: false
//               };
//             }
//             return item;
//           });
//           setScreenInfo(updatedArr)
//         }
//       })
//     } catch (error) {

//     }
//   }



//   const checkRolenameExist = (value) => {
//     var obj_data = {}
//     obj_data["role_name"] = value
//     obj_data["encrypted_db_url"] = dbUrl
//     obj_data["user_id"] = userInfo._id
//     if (Object.keys(editData).length > 0) {
//       obj_data["_id"] = editData._id
//     }
//     if (value !== '' && value !== undefined) {
//       try {
//         urlSocket.post('cog/check-rolename-exist', obj_data).then((response) => {
//           if (response.data.response_code === 500 && response.data.data.length > 0) {
//             setRoleNameErr(true)
//           }
//           else {
//             setRoleNameErr(false)

//           }
//         })

//       } catch (error) {

//       }
//     }

//   }


//   const validation = useFormik({
//     enableReinitialize: true,
//     initialValues: {
//       role_name: '' || editData.role_name
//     },
//     validationSchema: Yup.object({
//       role_name: Yup.string().required("Please Enter Role Name")
//     }),
//     onSubmit: (values) => {
//       if (!values.role_name.trim()) {
//         Swal.fire({
//           icon: 'error',
//           title: 'Error!',
//           text: 'Role name cannot be empty.',
//           confirmButtonColor: '#d33',
//           confirmButtonText: 'OK'
//         });
//         return;
//       }

//       values["role_status"] = RoleStatus;
//       values["encrypted_db_url"] = dbUrl;

//       const validate_checkbox = _.filter(screenInfo, element => {
//         return element.read_checked === true || element.read_write_checked === true;
//       });

//       values["facilities"] = validate_checkbox;
//       values["_id"] = editData._id;

//       if (roleNameErr === false) {
//         Swal.fire({
//           icon: 'question',
//           title: 'Are you sure?',
//           text: 'Do you want to save this role?',
//           showCancelButton: true,
//           confirmButtonColor: '#2ba92b',
//           confirmButtonText: 'Yes',
//           cancelButtonColor: '#d33',
//           cancelButtonText: 'No'
//         }).then((result) => {
//           if (result.isConfirmed) {
//             saveRole(values);
//           } else {
//             // Handle cancel action
//           }
//         });
//       }

//       if (validate_checkbox.length < 1) {
//         Swal.fire({
//           icon: 'warning',
//           title: 'Warning!',
//           text: 'Minimum 1 Screen Information should be selected.',
//           confirmButtonColor: '#3085d6',
//           confirmButtonText: 'OK'
//         }).then((result) => {
//           // Handle OK action
//         });
//       }
//     }
//   });



//   const saveRole = (data) => {
//     data["user_id"] = userInfo._id
//     data["created_by"] = userInfo._id
//     data["approver"] = userConfig.approver
//     data["report_viewer"] = userConfig.report_viewer
//     console.log(data, 'data')
//     try {

//       urlSocket.post('cog/crud-roles', data).then((response) => {

//         if (response.data.response_code === 500) {
//           props.history.push('/mroles')
//         }
//       })

//     } catch (error) {

//     }

//   }



//   const onchange_switch = (e, name, idx) => {
//     var screen_info = [...screenInfo]
//     var dup_screen = []
//     var dup_screen_1 = []
//     if (name == 'read_write_checked') {
//       screen_info[idx].read_write_checked = e.target.checked
//       screen_info[idx].read_checked = false
//       setWriteCheckAll(false)
//       if (e.target.checked === false) {
//         screen_info[idx].read_write_checked = false

//         setReadCheckAll(false)
//         // setWriteCheckAll(false)

//       }

//       _.filter(screen_info, e => {
//         if (e.read_write_checked === true) {
//           dup_screen.push(e)
//           // console.log(dup_screen.length)
//         }
//       })
//       if (dup_screen.length == screen_info.length) {
//         setReadCheckAll(true)
//       }
//       setScreenInfo(screen_info)
//     }
//     if (name == 'read_checked') {
//       screen_info[idx].read_checked = e.target.checked
//       setScreenInfo(screen_info)
//       if (e.target.checked === false) {
//         setWriteCheckAll(false)
//       }
//       _.filter(screen_info, e => {
//         if (e.read_checked === true) {
//           dup_screen_1.push(e)
//           // console.log(dup_screen.length)
//         }
//       })
//       if (dup_screen_1.length == screen_info.length) {
//         setWriteCheckAll(true)
//       }

//     }
//     if (name == 'read_write_check_all') {
//       if (e.target.checked === true) {
//         screen_info.map((data, idx) => {
//           // if (data.facility_id !== "1") {
//           screen_info[idx].read_write_checked = true
//           screen_info[idx].read_checked = false
//           // }
//         })
//         setReadCheckAll(true)
//         setWriteCheckAll(false)
//       }
//       if (e.target.checked === false) {
//         screen_info.map((data, idx) => {
//           if (data.facility_id !== "1" || data.facility_id !== "11" || data.facility_id !== "12") {
//             screen_info[idx].read_write_checked = false
//           }
//           // screen_info[idx].write_checked = false
//         })
//         setReadCheckAll(false)
//         setWriteCheckAll(false)

//       }
//       setScreenInfo(screen_info)
//     }
//     if (name == 'read_check_all') {
//       if (e.target.checked === true) {
//         screen_info.map((data, idx) => {
//           screen_info[idx].read_checked = true
//         })
//         setWriteCheckAll(true)

//       }
//       if (e.target.checked === false) {
//         screen_info.map((data, idx) => {
//           screen_info[idx].read_checked = false
//         })
//         setWriteCheckAll(false)

//       }
//       setScreenInfo(screen_info)


//     }




//   }

//   const switch_change = (e, name) => {
//     setRoleStatus(e)
//   }

//   const gotoBack = () => {
//     sessionStorage.removeItem('role_id')
//     props.history.push('/mroles')
//   }


//   const handleRoleNameBlur = async () => {
//     const value = validation.values.role_name;
//     if (value) {
//       try {
//         await checkRolenameExist(value);
//         // setRoleNameErr(true); // Role name exists
//       } catch (error) {
//         // setRoleNameErr(false); // Role name doesn't exist
//       }
//     }
//   };

//   const handleInputChange = (event) => {
//     setRoleNameErr(false); // Reset the roleNameErr state
//     validation.handleChange(event);
//   };


//   const handleKeyDown = async () => {
//     const value = validation.values.role_name;
//     if (value) {
//       try {
//         await checkRolenameExist(value);
//         setRoleNameErr(true); // Role name exists
//       } catch (error) {
//         setRoleNameErr(false); // Role name doesn't exist
//       }

//     }
//   };



//   return (
//     <div className='page-content'>

//       {/* <MetaTags><title>AuditVista | {oncreate ? 'Create Roles' : 'Edit Roles'}</title></MetaTags> */}

//       <Container fluid>

//         <Breadcrumbs
//           title="Create Role"
//           breadcrumbItem="Roles"
//           isBackButtonEnable={true}
//           gotoBack={() => gotoBack()}
//         />

//         <Form className="needs-validation"
//           onSubmit={(e) => {
//             e.preventDefault();
//             validation.handleSubmit();
//             return false;
//           }}
//         >

//           <Card style={{ marginBottom: 6 }}>
//             <CardBody>

//               <Row>
//                 <Col md={4}>
//                   <Label className='font-size-15'>{oncreate ? "New Role Name" : "Role Name"}<label className='text-danger'>*</label></Label>
//                   <FormGroup >
//                     <Input
//                       name="role_name"
//                       placeholder="Please Enter the Role Name"
//                       type="text"
//                       className="form-control"
//                       onChange={handleInputChange}
//                       onBlur={handleRoleNameBlur}
//                       onKeyDown={handleKeyDown}
//                       value={validation.values.role_name || ""}
//                       invalid={
//                         validation.touched.role_name && validation.errors.role_name ? true : false
//                       }
//                     />
//                     {
//                       roleNameErr &&
//                       <label style={{ fontSize: 'smaller' }} className='text-danger'>Role Name already exist</label>
//                     }
//                     {validation.touched.role_name && validation.errors.role_name ? (
//                       <FormFeedback type="invalid">{validation.errors.role_name}</FormFeedback>
//                     ) : null}
//                   </FormGroup>
//                 </Col>
//                 {
//                   Object.keys(editData).length !== 0 &&
//                   <Col md={8} style={{ textAlign: 'right', alignSelf: 'center' }}>
//                     <Label>Status :</Label>
//                     &nbsp;&nbsp;&nbsp;<Switch value={RoleStatus}
//                       checked={RoleStatus ? true : false}
//                       onChange={(e) => { switch_change(e, 'role_status') }} />
//                   </Col>
//                 }
//               </Row>

//               <Row>
//                 <Col md={4}>
//                   <Label>Approver</Label>&nbsp;
//                   <input onChange={(event) => {
//                     console.log(event.target.checked, 'event')
//                     const userConfigInfo = _.cloneDeep(userConfig)
//                     userConfigInfo["approver"] = event.target.checked
//                     setUserConfig(userConfigInfo)
//                   }}
//                     checked={userConfig.approver}
//                     defaultChecked={userConfig.approver} type='checkbox' />
//                   &nbsp;
//                   <Label>Report Viewer</Label>&nbsp;
//                   <input type='checkbox'
//                     onChange={(event) => {
//                       console.log(event.target.checked, 'event')
//                       const userConfigInfo = _.cloneDeep(userConfig)
//                       userConfigInfo["report_viewer"] = event.target.checked
//                       setUserConfig(userConfigInfo)
//                     }}
//                     checked={userConfig.report_viewer}
//                     defaultChecked={userConfig.report_viewer} />

//                 </Col>



//               </Row>

//             </CardBody>
//           </Card>

//           <Card>
//             <CardBody>

//               <Label className='font-size-15'>Please select the screen for the Role<span className='text-danger'>*</span></Label>

//               <table className="table" >
//                 <tr className="header-row " style={{ borderBottom: '1px solid #dedede' }}>
//                   <th width="70%" className='py-3'>Screens</th>
//                   <th width="10%" className='py-3'><span><Checkbox name='read_check_all' checked={readCheckAll} onClick={(e) => {
//                     console.log(e.target.checked, 'checked');
//                     onchange_switch(e, "read_write_check_all")
//                   }} /></span>Read {'&'} Write</th>
//                   <th width="10%" className='py-3'><span>
//                     <Checkbox name='write_check_all' disabled={readCheckAll === false ? false : true} checked={writeCheckAll} onClick={(e) => {
//                       console.log(e.target.checked, 'checked')
//                       onchange_switch(e, "read_check_all")
//                     }} />
//                   </span>Read</th>
//                   <th width="10%" className='py-3'><span>
//                     <Checkbox onClick={(e) => {
//                       console.log(e.target.checked, 'checked')
//                     }}
//                     />
//                   </span>Approve</th>
//                 </tr>
//                 <tbody>

//                   {
//                     screenInfo?.map((ele, idx) => {
//                       if (ele.type === "group" && ele.active) {
//                         const getSubMenu = screenInfo.filter(
//                           (subItem) => subItem.parent === ele.id && subItem.active
//                         );

//                         return (
//                           <React.Fragment key={idx}>
//                             <tr className="table-row-hover">
//                               <td className="text-primary">{ele.menu}</td>
//                             </tr>
//                             {getSubMenu.map((subItem, subIdx) => (
//                               <tr key={"sbmnu" + subIdx} className="table-row-hover">
//                                 <td className="text-primary" style={{ paddingLeft: '20px' }}>
//                                   <span style={{ color: '#060606' }}>└─</span> {subItem.menu}
//                                 </td>
//                                 <td>{
//                                   ele.permission.includes("write") ?
//                                     <td><Checkbox disabled={ele.facility_id == "1" || ele.facility_id == "11" || ele.facility_id == "12"} checked={ele.read_write_checked === true} onClick={(e) => { onchange_switch(e, "read_write_checked", idx) }} /></td>
//                                     // <input type='checkbox' disabled={ele.id == 0 || ele.id == 9 || ele.id == 10 || ele.write} checked={ele.id == 0 || ele.id == 9 || ele.id == 10 } />
//                                     :
//                                     null
//                                 }</td>
//                                 <td>{
//                                   ele.permission.includes("read") ?
//                                     <td><Checkbox checked={ele.read_checked === true} disabled={ele.read_write_checked === true} onClick={(e) => { onchange_switch(e, "read_checked", idx) }} /></td>
//                                     :
//                                     null
//                                 }</td>
//                                 <td>{
//                                   ele.permission.includes("approve") ?
//                                     <td><Checkbox /></td>

//                                     :
//                                     null
//                                 }</td>
//                               </tr>
//                             ))}
//                           </React.Fragment>
//                         );
//                       } else if (ele.parent === null && ele.type === "menu" && ele.active) {
//                         return (
//                           <tr key={"nll" + idx} className="table-row-hover">
//                             <td className="text-primary">{ele.menu}</td>
//                             <td>{
//                               ele.permission.includes("write") ?
//                                 <td><Checkbox disabled={ele.facility_id == "1" || ele.facility_id == "11" || ele.facility_id == "12"} checked={ele.read_write_checked === true} onClick={(e) => { onchange_switch(e, "read_write_checked", idx) }} /></td>
//                                 :
//                                 null
//                             }</td>
//                             <td>{
//                               ele.permission.includes("read") ?
//                                 <td><Checkbox checked={ele.read_checked === true} disabled={ele.read_write_checked === true} onClick={(e) => { onchange_switch(e, "read_checked", idx) }} /></td>

//                                 :
//                                 null
//                             }</td>
//                             <td>{
//                               ele.permission.includes("approve") ?
//                                 <td><Checkbox /></td>

//                                 :
//                                 null
//                             }</td>
//                           </tr>
//                         );
//                       }
//                     })
//                   }
//                 </tbody>
//               </table>
//               <Row>
//                 <Col md={12} className='text-end'>
//                   <Button className='me-2' color='danger' onClick={() => props.history.push('/mroles')}>Cancel</Button>
//                   <Button color={oncreate ? 'success' : 'dark'}
//                     disabled={protect.read_checked === true ? true : false}
//                   >{oncreate ? "Create Role" : "Update Role"}</Button>
//                 </Col>
//               </Row>

//             </CardBody>
//           </Card>
//         </Form>
//       </Container>
//     </div>
//   )

// }

// IRAddRoles.propTypes = propTypes;
// IRAddRoles.defaultProps = defaultProps;
// // #endregion

// export default IRAddRoles;






















import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Col, Container, Card, CardBody, Row, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Switch, Checkbox } from 'antd'
import Swal from 'sweetalert2';
import { createRoleData, retriveRoleInfo } from 'toolkitStore/IncidentReport/IR_RoleSlice';
import { IRTvalidateDupName,setRoleNameExist } from 'toolkitStore/IncidentReport/IR_OrgSlice';
import { useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store from '../../../store';


const IRAddRoles = () => {
  const [authUser, setauthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
  const [clientInfo, setclientInfo] = useState(JSON.parse(sessionStorage.getItem("client_info")))[0]
  const [facilities, setFacilities] = useState([])
  const [readAll, setReadAll] = useState(false)
  const [writeAll, setWriteAll] = useState(false)
  const [approvell, setApproveAll] = useState(false)
  const [editData,seteditData] = useState(null)
  const dispatch = useDispatch()
  const IR_OrgSlice = useSelector(state => state.IR_OrgSlice)
  // const navigate = useNavigate()
  console.log(IR_OrgSlice,'IR_OrgSlice')



  useEffect(() => {
    console.log("Add roles")
    var sessionInfo = sessionStorage.getItem("role_id")
    if (sessionInfo) {
      async function retriveFun() {
        var facilityInfo = await dispatch(retriveRoleInfo(sessionInfo))
        console.log(facilityInfo, 'facilityInfo')
        if (facilityInfo.length > 0) {
          setFacilities(facilityInfo[0].facilities)
          var writeAll = _.filter(facilityInfo[0].facilities, { write: true })
          console.log(writeAll, 'writeAll', facilityInfo[0].facilities);
          if (writeAll.length === facilityInfo[0].facilities.length) {
            setWriteAll(true)
          }
          seteditData(facilityInfo[0])
          // validation.setFieldValue("role_name", facilityInfo[0].role_name)

        }
      }
      retriveFun()

    }
    else {
      setFacilities(authUser.client_info[0].facilities)
    }
  }, [])


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      role_name: ''||editData?.role_name,
    },
    validationSchema: Yup.object({
      role_name: Yup.string().required("Please Enter Your Role name").test(async function (value) {
          console.log(value,'value',store.getState())
        return !(await dispatch(IRTvalidateDupName(editData,"cln_ir_roles","role_name",value,setRoleNameExist)));

      })
      .matches(/\S+/, "Role name cannot be just spaces")
      .min(clientInfo.incd_rpt_validation.name_min, `Role name must be at least ${ clientInfo.incd_rpt_validation.name_min } characters`)

      ,
    }),
    onSubmit: (values) => {
      console.log(values, 'values')
      var checkedRoles =facilities.filter((ele)=>{
        if(ele.read || ele.write || ele.approve){
          return ele
        }
      })
      console.log(checkedRoles,'checkedRoles',facilities)
      const finalData = _.map(facilities, (ele) => {
        const matchedItem = _.find(checkedRoles, { id: ele.id });
      
        if (matchedItem) {
          return ele; // If there's a match, return the element as is
        }
        else if(ele.id === 0 || ele.id === 9 || ele.id === 10){
          return { ...ele, active: true };
        }
        else {
          return { ...ele, active: false }; // If no match, set active to false
        }
      });

      console.log(finalData,'finalData')

      if(IR_OrgSlice.roleNameExist === false){
        values["facilities"] = finalData
        values["created_by"] = authUser.user_data._id
        values["role_status"] = true
      Swal.fire({
        icon: 'question',
        title: 'Are you sure?',
        text: 'Do you want to save this role?',
        showCancelButton: true,
        confirmButtonColor: '#2ba92b',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          if(editData !== null){
            values["_id"] = editData._id
          }
          dispatch(createRoleData(values))
          window.location.replace('/manage-roles')
          // saveRole(values);
        } else {
          // Handle cancel action
        }
      });
    }
    }
  });


  const onCheck = (event, name, id) => {

    const facilityInfo = _.cloneDeep(facilities)
    console.log(event, name, id, 'event,name,index)');
    if (name === "writeAll") {
      facilityInfo.map((ele, idx) => {
        facilityInfo[idx]["writeAll"] = event.target.checked
        facilityInfo[idx]["write"] = event.target.checked
        facilityInfo[idx]["readAll"] = event.target.checked
        facilityInfo[idx]["read"] = false

      })
    }
    if (name === "readAll") {
      facilityInfo.map((ele, idx) => {
        facilityInfo[idx]["readAll"] = event.target.checked
        facilityInfo[idx]["read"] = event.target.checked

      })
    }
    if (name === "read") {
      var getIdx = _.findIndex(facilityInfo, { id: id })
      console.log(getIdx, 'getIdx');
      if (getIdx !== -1) {
        facilityInfo.map((ele, idx) => {
          if (getIdx === idx) {
            facilityInfo[idx]["read"] = event.target.checked
            facilityInfo[idx]["active"] = event.target.checked
          }
        })
      }
    }
    if (name === "write") {
      var getIdx = _.findIndex(facilityInfo, { id: id })
      console.log(getIdx, 'getIdx');
      if (getIdx !== -1) {
        facilityInfo.map((ele, idx) => {
          if (getIdx === idx) {
            facilityInfo[idx]["read"] = false
            facilityInfo[idx]["write"] = event.target.checked
            facilityInfo[idx]["active"] = event.target.checked

          }
        })
      }
      setReadAll(false)
    }

    if (name === "approve") {
      var getIdx = _.findIndex(facilityInfo, { id: id })
      console.log(getIdx, 'getIdx');
      if (getIdx !== -1) {
        facilityInfo.map((ele, idx) => {
          if (getIdx === idx) {
            facilityInfo[idx]["approve"] = event.target.checked
            facilityInfo[idx]["active"] = event.target.checked

          }
        })
      }
    }


    if (name === "approveAll") {
      facilityInfo.map((ele, idx) => {
        facilityInfo[idx]["approve"] = event.target.checked
        facilityInfo[idx]["active"] = event.target.checked

      })
    }


    console.log(facilityInfo, 'facilityInfo');
    setFacilities(facilityInfo)



  }




  return (
    <React.Fragment>
      <div className="page-content">

        <Container fluid={true}>
          <Breadcrumbs title="Add Roles" breadcrumbItem="Add Roles" />
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <Form className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Label className="form-label">Role Name :<span className='text-danger'>*</span></Label>
                    <Input
                      name="role_name"
                      className="form-control"
                      placeholder="Enter Role name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.role_name || ""}
                      invalid={
                        validation.touched.role_name && validation.errors.role_name ? true : false
                      }
                    />
                    {validation.touched.role_name && validation.errors.role_name ? (
                      <FormFeedback type="invalid">{validation.errors.role_name}</FormFeedback>
                    ) : null}
                    {
                      IR_OrgSlice.roleNameExist &&
                      <div className='text-danger' style={{ fontSize: 'smaller' }}>
                      Role Name already exist
                    </div>
                    }
                  </Form>

                </Col>
                <Col md={6} className='text-end'>
                  <Link to={"/manage-roles"}><button className='btn btn-outline-primary' onClick={() => {
                    // window.location.href=""
                    dispatch(setRoleNameExist(false))
                    sessionStorage.removeItem("role_id")
                  }}>Back</button></Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Label className="form-label">Please Select the Access Screen for this Role :<span className='text-danger'>*</span></Label>


              <table className="table" >
                <tr className="header-row " style={{ borderBottom: '1px solid #dedede' }}>
                  <th width="70%" className='py-3'>Screens</th>
                  <th width="10%" className='py-3'><Checkbox name='read' disabled={writeAll} checked={readAll} onClick={(e) => {
                    onCheck(e, "readAll")
                    setReadAll(e.target.checked)
                  }} />Read</th>
                  <th width="10%" className='py-3'><Checkbox name='writeAll' onClick={(e) => {
                    setWriteAll(e.target.checked);
                    setReadAll(false)
                    onCheck(e, "writeAll")
                  }}
                    checked={writeAll}

                  />Write</th>
                  <th width="10%" className='py-3'><Checkbox
                    checked={approvell}
                    onClick={(e) => {
                      onCheck(e, "approveAll")
                      setApproveAll(e.target.checked)


                    }} />Approve</th>
                </tr>
                <tbody>
                  {
                    facilities?.map((ele, idx) => {
                      if (ele.type === "group") {
                        const getSubMenu = facilities.filter(
                          (subItem) => subItem.parent === ele.id 
                        );

                        return (
                          <React.Fragment key={idx}>
                            <tr className="table-row-hover">
                              <td className="text-primary">{ele.menu}</td>
                            </tr>
                            {getSubMenu.map((subItem, subIdx) => (
                              <tr key={"sbmnu" + subIdx} className="table-row-hover">
                                <td className="text-primary" style={{ paddingLeft: '20px' }}>
                                  <span style={{ color: '#060606' }}>└─</span> {subItem.menu}
                                </td>
                                <td>{
                                  ele.permission.includes("read") ?
                                    <td><Checkbox onClick={(e) => {
                                      onCheck(e, "read", subItem.id)
                                    }} checked={ele.id == 0 || ele.id == 9 || ele.id == 10 || subItem.read} disabled={ele.id == 0 || ele.id == 9 || ele.id == 10 || subItem.write} /></td>
                                    :
                                    null
                                }</td>
                                <td>{
                                  ele.permission.includes("write") ?
                                    <td><Checkbox
                                      onClick={(e) => {
                                        onCheck(e, "write", subItem.id)
                                      }}
                                      checked={subItem.write} /></td>
                                    :
                                    null
                                }</td>
                                <td>{
                                  ele.permission.includes("approve") ?
                                    <td><Checkbox
                                      onClick={(e) => {
                                        onCheck(e, "approve", subItem.id)
                                      }}
                                      checked={subItem.approve}
                                    /></td>
                                    :
                                    null
                                }</td>
                              </tr>
                            ))}
                          </React.Fragment>
                        );
                      } else if (ele.parent === null && ele.type === "menu") {
                        return (
                          <tr key={"nll" + idx} className="table-row-hover">
                            <td className="text-primary">{ele.menu}</td>
                            <td>{
                              ele.permission.includes("read") ?
                                <td><Checkbox
                                  onClick={(e) => {
                                    onCheck(e, "read", ele.id)
                                  }}
                                  checked={ele.id == 0 || ele.id == 9 || ele.id == 10 || ele.read} disabled={ele.id == 0 || ele.id == 9 || ele.id == 10 || ele.write} /></td>
                                :
                                null
                            }</td>
                            <td>{
                              ele.permission.includes("write") ?
                                <td><Checkbox
                                  onClick={(e) => {
                                    onCheck(e, "write", ele.id)
                                  }} checked={ele.write ? true : false} /></td>
                                :
                                null
                            }</td>
                            <td>{
                              ele.permission.includes("approve") ?
                                <td><Checkbox
                                  onClick={(e) => {
                                    onCheck(e, "approve", ele.id)
                                  }}
                                  checked={ele.approve}

                                /></td>
                                :
                                null
                            }</td>
                          </tr>
                        );
                      }
                    })
                  }
                </tbody>
              </table>
              <Row>
                <Col md={12} className='text-end'>
                  <button type='submit' onClick={validation.handleSubmit} className='btn btn-success'>Submit</button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>

  )
}
export default IRAddRoles



