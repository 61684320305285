import { createSlice } from '@reduxjs/toolkit';
import { changeNodeAtPath, addNodeUnderParent, getFlatDataFromTree, getNodeAtPath, removeNodeAtPath, getVisibleNodeCount, getTreeFromFlatData } from 'react-sortable-tree';
import { post } from 'helpers/api_helper';
import store from '../../store'

const getNodeKey = ({ treeIndex }) => treeIndex;


const treeDataSlice = createSlice({
    name: 'IncidentHtreeData',
    initialState: {
        treeData: [],
        crud: false,
        editcrud: false,
        crudStatus: 0,
        path: [],
        getNodeInfo: null,
        getCode: null,
        getTitle: null,
        type: null,
        id: null,
        parent: null,
        menuName: '',
        totalHLength: 0,
        mainToggle: false,
        nodeCount: 1,
        publishInfo: {
            saving: false,
            successmsgshow: false,
            alertMsg: ""
        },
        nodeInfo: null,
        nodeUsers: [],
        showUserListDrawer: false,
        incident_type: [],

       
    },
    reducers: {
        setTreeData: (state, action) => {
            console.log(action.payload, 'action.payload')
            state.treeData = action.payload;
        },
        setShowUserListDrawer:(state,action)=>{
            state.showUserListDrawer = action.payload
        },
        setState: (state, action) => {
            Object.assign(state, action.payload);
        },
        setTotalHLength: (state, action) => {
            state.totalHLength = action.payload;
        },
        setMenuName: (state, action) => {
            state.menuName = action.payload;
        },
        setPublishInfo: (state, action) => {
            state.publishInfo.saving = action.payload.saving
            state.publishInfo.successmsgshow = action.payload.successmsgshow
            state.publishInfo.alertMsg = action.payload.alertMsg
        },
        setnodeInfo: (state, action) => {
            state.nodeInfo = action.payload
        },
        setnodeUsers: (state, action) => {
            state.nodeUsers = action.payload
        },
        setIncidentType: (state, action) => {
            state.incident_type = action.payload;
        },
     
    },
});

export const { setTreeData, setState, setTotalHLength, setPublishInfo, setMenuName, setnodeInfo, setnodeUsers,setShowUserListDrawer,setIncidentType } = treeDataSlice.actions;


// Function to save tree and flat data
export const saveTreeData = async (treeData, totalHLength, dispatch) => {
    var flatData = await saveHStructure(treeData)
    console.log('saveTreeData', treeData, flatData, totalHLength)
    saveData(flatData, totalHLength, dispatch)
};


// export const getHdata = () => {
//     console.log('///')
//     return dispatch => {

//         const authUser = JSON.parse(sessionStorage.getItem("authUser"))
//         const hInfo = JSON.parse(sessionStorage.getItem("hInfo"))

//         try {

//             post("webhstre/incd-gethstructure", {
//                 info: {
//                     _id: hInfo._id,
//                     company_id: hInfo.company_id,
//                     encrypted_db_url: authUser.db_info.encrypted_db_url,
//                 }
//             }).then(async (response) => {
//                 console.log(response, 'response')
//                 if (response.data.response_code === 500) {
//                     var FlatData = response.data.data.hstructure
//                     console.log(FlatData, 'treeData')
//                     dispatch(setTreeData(FlatData))
//                     dispatch(setTotalHLength(response.data.data.lastGeneratedId))

//                 }
//             })

//         } catch (error) {
//             console.log(error, 'error')
//         }
//     }
// }


export const getHdata = () => {
    return async (dispatch) => {
        console.log('Fetching hierarchical data...');

        const authUser = JSON.parse(sessionStorage.getItem("authUser"));
        const hInfo = JSON.parse(sessionStorage.getItem("hInfo"));

        if (!authUser || !hInfo) {
            console.error('Auth user or hierarchical info not available.');
            return null;
        }

        try {
            const response = await post("webhstre/incd-gethstructure", {
                info: {
                    _id: hInfo._id,
                    company_id: hInfo.company_id,
                    encrypted_db_url: authUser.db_info.encrypted_db_url,
                }
            });

            console.log(response, 'API Response');

            if (response.data.response_code === 500) {
                const FlatData = response.data.data.hstructure;
                console.log(FlatData, 'treeData');

                // Dispatch actions to set the data in state
                dispatch(setTreeData(FlatData));
                dispatch(setTotalHLength(response.data.data.lastGeneratedId));
                dispatch(setIncidentType(response.data.incd_type))
                // Return the FlatData properly so it can be awaited
                return response.data.data;
            } else {
                console.error('Failed to fetch data, response code:', response.data.response_code);
                return null;
            }
        } catch (error) {
            console.error('Error fetching hierarchical data:', error);
            return null;
        }
    };
};


export const incAddNodeUser = (treeData, nodeInfo, type, history, cat_type, path) => {
    var flatData = getFlatDataFromTree(
        {
            treeData: treeData,
            getNodeKey: getNodeKey,
            ignoreCollapsed: false,
        })

    console.log(flatData, 'flatData', nodeInfo)


    var node = {
        node: nodeInfo,
        path,
        totalHLength: flatData.length,
        cat_type: cat_type,
    }
    var treeData = {
        treeData: treeData,
    }
    var flat_data = {
        flat_data: _.map(flatData, 'node')
    }

    console.log(node, 'node---------->')
    sessionStorage.removeItem("nodeInfo");
    sessionStorage.setItem("nodeInfo", JSON.stringify(node));


    history.push('/incdinfo-mpusr')



}


export const IncidentaddUsers = async (userNodeInfo) => {

    // var treeData =[]
    var HtreeData = store.getState().incdreportslice
    var treeData = [...HtreeData.dupTreeData.treeData]
    const index = userNodeInfo.nodeInfo.flatData.findIndex(node => node.id === userNodeInfo.nodeInfo.node.id);
    if (index !== -1) {
        userNodeInfo.nodeInfo.flatData[index]["unique_users"] = userNodeInfo.nodeInfo.node.unique_users;
        userNodeInfo.nodeInfo.flatData[index]["user_path"] = userNodeInfo.nodeInfo.node.user_path;
    }
    console.log(index, 'index', userNodeInfo)
    var treeData = await getTreeFromFlatDataAsync(userNodeInfo.nodeInfo.flatData)
    console.log(treeData, 'treeData')
    IncidentsaveTreeData(treeData, HtreeData.dupTreeData.lastGeneratedId, store.dispatch)


}


export const IncidentsaveTreeData = async (treeData, totalHLength, dispatch) => {
    var flatData = await saveHStructure(treeData)
    console.log('saveTreeData', treeData, flatData, totalHLength)
    IncidentsaveData(treeData, totalHLength, dispatch)
};


export const IncidentsaveData = (treeData, totalHLength, dispatch) => {
    const hInfo = JSON.parse(sessionStorage.getItem("hInfo"))
    const authUser = JSON.parse(sessionStorage.getItem("authUser"))
    dispatch(setPublishInfo({
        saving: true,
        successmsgshow: false,
        alertMsg: ''
    }))
    var HtreeData = store.getState().incdreportslice
    console.log(HtreeData.duphTreeData, 'HtreeData.duphTreeData')

    try {
        post("webhstre/incd-savehstructure",
            {
                info: {
                    _id: hInfo._id,
                    encrypted_db_url: authUser.db_info.encrypted_db_url,
                    company_id: hInfo.company_id,
                    flatData: treeData,
                    lastGeneratedId: totalHLength
                }
            }
        ).then((response) => {
            console.log(response, 'response')
            publishHStructure(treeData, dispatch)
        })

    } catch (error) {

    }


}



export const addUsers = async (userNodeInfo) => {

    // var treeData =[]
    var HtreeData = store.getState().IncidentHtreeData
    var treeData = [...HtreeData.treeData]
    const index = userNodeInfo.nodeInfo.flatData.findIndex(node => node.id === userNodeInfo.nodeInfo.node.id);
    if (index !== -1) {
        userNodeInfo.nodeInfo.flatData[index] = userNodeInfo.nodeInfo.node;
    }
    console.log(index, 'index', userNodeInfo)
    var treeData = await getTreeFromFlatDataAsync(userNodeInfo.nodeInfo.flatData)
    console.log(treeData, 'treeData')
    store.dispatch(setTreeData(treeData))
    // dispatch();
    saveTreeData(treeData, store.getState().totalHLength, store.dispatch)
    store.dispatch(setnodeUsers(userNodeInfo.nodeInfo.node.unique_users))
    store.dispatch(setnodeInfo(userNodeInfo.nodeInfo.node))


}


export const publishHStructure = async (treeData, dispatch) => {

    var explicitData = await treeDataToFlat(treeData)
    var flatData = _.map(explicitData, 'node')
    console.log(flatData, 'flatData')
    await publishData(flatData, dispatch)
}

export const publishData = async (flatData, dispatch) => {

    try {
        const authUser = JSON.parse(sessionStorage.getItem("authUser"))
        const hInfo = JSON.parse(sessionStorage.getItem("hInfo"))
        dispatch(setPublishInfo({
            saving: true,
            successmsgshow: false,
            alertMsg: ''
        }))


        const responseData = await post("webhstre/incd-publishhstructure", {
            info: {
                encrypted_db_url: authUser.db_info.encrypted_db_url,
                company_id: hInfo.company_id,
                user_id: authUser.user_data._id,
                user_name: authUser.user_data.first_name,
                h_id: hInfo._id,
                flatData: flatData,
            }
        })
        console.log(responseData, 'responseData')

        if (responseData.data.response_code === 500) {
            dispatch(setPublishInfo({
                saving: false,
                successmsgshow: true,
                alertMsg: responseData.data.message
            }))

            setTimeout(() => {
                dispatch(setPublishInfo({
                    saving: false,
                    successmsgshow: false,
                    alertMsg: ""
                }))
            }, 2500);

        }



    } catch (error) {
        console.log(error, 'error')

    }



}






export const getTreeFromFlatDataAsync = (FlatData) => {
    // FlatData
    console.log(FlatData)
    try {
        return new Promise((resolve, reject) => {
            try {
                const treeData = getTreeFromFlatData({
                    flatData: FlatData,
                    getKey: (node) => node.id,
                    getParentKey: (node) => node.parent,
                    rootKey: null,
                    expanded: true
                });
                console.log(treeData, 'treeData')
                resolve(treeData);
            } catch (error) {
                reject(error);
            }
        });
    } catch (error) {

    }

}





export const addNodeUser = (treeData, nodeInfo, type, history, cat_type, path) => {
    var flatData = getFlatDataFromTree(
        {
            treeData: treeData,
            getNodeKey: getNodeKey,
            ignoreCollapsed: false,
        })

    console.log(flatData, 'flatData', nodeInfo)
    // if(nodeInfo.children === undefined){
    //     nodeInfo["children"]=[]
    // }
    // var node = {
    //     node:nodeInfo, path, totalHLength: flatData.length, cat_type: cat_type, 
    //     treeData: treeData,
    //     flat_data: _.map(flatData,'node')
    // }


    var node = {
        node: nodeInfo,
        path,
        totalHLength: flatData.length,
        cat_type: cat_type,
    }
    var treeData = {
        treeData: treeData,
    }
    var flat_data = {
        flat_data: _.map(flatData, 'node')
    }

    console.log(node, 'node')
    sessionStorage.removeItem("nodeInfo");
    sessionStorage.setItem("nodeInfo", JSON.stringify(node));
    // sessionStorage.setItem("treeData", JSON.stringify(treeData));
    // sessionStorage.setItem("flatData", JSON.stringify(flat_data));

    if (type === 2) {

        // history.push('/incd-mpusr')
    }
}

export const saveData = (flatData, totalHLength, dispatch) => {
    const hInfo = JSON.parse(sessionStorage.getItem("hInfo"))
    const authUser = JSON.parse(sessionStorage.getItem("authUser"))
    dispatch(setPublishInfo({
        saving: true,
        successmsgshow: false,
        alertMsg: ''
    }))
    var HtreeData = store.getState().IncidentHtreeData
    console.log(flatData,'flatData',HtreeData.treeData)

    try {
        post("webhstre/incd-savehstructure",
            {
                info: {
                    _id: hInfo._id,
                    encrypted_db_url: authUser.db_info.encrypted_db_url,
                    company_id: hInfo.company_id,
                    flatData: HtreeData.treeData,
                    lastGeneratedId: totalHLength
                }
            }
        ).then((response) => {
            console.log(response, 'response')
            dispatch(setPublishInfo({
                saving: false,
                successmsgshow: true,
                alertMsg: "Saved successfully"
            }))
            // setTimeout(() => {
            store.dispatch(getHdata())

            // }, 200);

            setTimeout(() => {
                dispatch(setPublishInfo({
                    saving: false,
                    successmsgshow: false,
                    alertMsg: ""
                }))
            }, 2500);
        })

    } catch (error) {

    }


}






//  function to get tree data from flat data
export const treeDataToFlat = (treeData) => {
    var flatData = getFlatDataFromTree({
        treeData: treeData,
        getNodeKey,
        ignoreCollapsed: false,
    });
    var explicitData = _.filter(flatData, (item) => {
        return item;
    });
    return explicitData;
};

//  action to save tree structure
const saveHStructure = (treeData) => {
    return new Promise((resolve, reject) => {
        try {
            const explicitData = treeDataToFlat(treeData);
            resolve(_.map(explicitData, 'node'))
        } catch (error) {
            reject(error)
        }
    })
};


//  action to add sub node
export const addNode = (node, path, type) => (dispatch, getState) => {
    const state = getState().IncidentHtreeData;
    console.log('state', state)
    if (!path || path.length === 0) {
        console.error("Path array is empty or undefined");
        return;
    }
    const getNodeInfo = getNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey,
    });

    if (!getNodeInfo) {
        console.error("Invalid path: getNodeInfo is null or undefined");
        return;
    }

    dispatch(setState({
        type,
        path: path,
        crud: true,
        editcrud: false,
        crudStatus: 1,
        title: getNodeInfo.node.title,
        getTitle: "",
        getSubTitle: "",
        getSubTitledd: "0",
        id: getNodeInfo.node.children ? getNodeInfo.node.children.length + 1 : getNodeInfo.node.id,
        parent: getNodeInfo.node.id,
        children: getNodeInfo.node.children || state.children || []
    }));
};

//  action to create parent , child, and edit node
export const crudNode = (values) => (dispatch, getState) => {
    const state = getState().IncidentHtreeData;
    const { treeData, menuName, totalHLength, crudStatus } = state;

    let updatedTreeData;

    switch (crudStatus) {

        case 0: // create main node
            const parentKey = state.path && state.path.length > 0 ? state.path[state.path.length - 1] : null;
            updatedTreeData = addNodeUnderParent({
                treeData,
                parentKey,
                expandParent: true,
                getNodeKey,
                newNode: {
                    id: totalHLength + 1,
                    parent: null,
                    title: values.title,
                    subtitle: values.subtitledd == "0" || values.subtitledd === "" || (values.subtitledd === "1" && (values.subtitle === "")) ? "Level " + (state.path.length + 1) : values.subtitledd === "1" ? values.subtitle : values.subtitledd,
                    node_positon: "Level " + (state.path.length + 1),
                    type: state.type,
                    category_input: values.subtitledd == "" || (values.subtitledd === "1" && values.subtitle === "") ? false : true,
                    ...values
                    // children: []
                },
                addAsFirstChild: state.addAsFirstChild,
            }).treeData;
            break;

        case 1: // Create sub-node
            const newNode = {
                id: totalHLength + 1,
                // parent: state.parent,
                title: values.title,
                // subtitle: values.subtitledd == "" || (values.subtitledd === "1" && values.subtitle === "") ? "Level " + (this.state.node == undefined ? 0 + 1 : this.state.main_level ? this.state.node.node_level + 1 : this.state.node.node_level + 2) : values.subtitledd == "0" || values.subtitledd === "" ? "Level " + (this.state.path.length + 1) : values.subtitledd === "1" ? values.subtitle : values.subtitledd,
                // node_positon: "Level " + (state.path.length + 1),
                type: state.type,
                ...values
            };

            updatedTreeData = addNodeUnderParent({
                treeData,
                parentKey: state.path[state.path.length - 1],
                expandParent: true,
                getNodeKey,
                newNode,
                addAsFirstChild: state.addAsFirstChild,
            }).treeData;

            break;

        default:
            console.error("Invalid crudStatus value:", crudStatus);
            return;
    }

    // Dispatch action to update treeData in Redux store
    dispatch(setTreeData(updatedTreeData));
    saveTreeData(updatedTreeData, crudStatus === 2 ? totalHLength : totalHLength + 1, dispatch)

    // Dispatch action to update state
    dispatch(setState({
        crud: false,
        editcrud: false,
        childToggle: false,
        mainToggle: false,
        dataLoaded: true,
        totalHLength: crudStatus === 2 ? totalHLength : totalHLength + 1,
    }));
};

//  action to edit  a node's information
export const editNode = (path, node) => (dispatch, getState) => {
    const state = getState().IncidentHtreeData;

    const getNodeInfo = getNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey,
    });
    console.log(getNodeInfo, 'getNodeInfo')

    dispatch(setState({
        crud: false,
        editcrud: true,
        crudStatus: 2,
        path: path,
        getNodeInfo: getNodeInfo.node,
        getCode: getNodeInfo.node.code,
        getTitle: getNodeInfo.node.title,
        type: getNodeInfo.node.type,
        id: getNodeInfo.node.id,
        parent: getNodeInfo.node.parent,
        menuName: node.title,
    }));
};

//  action to edit and update a node
export const updateNode = (values) => (dispatch, getState) => {
    const state = getState().IncidentHtreeData;
    const { treeData, menuName, totalHLength, crudStatus } = state;

    let updatedTreeData;

    switch (crudStatus) {
        case 2: // Edit node
            updatedTreeData = changeNodeAtPath({
                treeData,
                path: state.path,
                expandParent: true,
                getNodeKey,
                newNode: {
                    ...state.getNodeInfo,
                    title: values.title,
                    ...values
                },
            });
            break;

        default:
            console.error("Invalid crudStatus value:", crudStatus);
            return;
    }

    // Dispatch action to update treeData in Redux store
    dispatch(setTreeData(updatedTreeData));
    saveTreeData(updatedTreeData, totalHLength, dispatch);

    // Dispatch action to update state
    dispatch(setState({
        crud: false,
        editcrud: false,
        childToggle: false,
        mainToggle: false,
        dataLoaded: true,
        totalHLength: crudStatus === 2 ? totalHLength : totalHLength + 1,
    }));
};

//  action to delete node
export const deleteNode = (node, path) => (dispatch, getState) => {
    // Assuming you have imported the necessary functions like removeNodeAtPath and saveHStructure
    var totalHLength = getState().IncidentHtreeData.totalHLength
    dispatch(setTreeData(removeNodeAtPath({
        treeData: getState().IncidentHtreeData.treeData, // Accessing the treeData from Redux state
        path,
        getNodeKey,
    })));
    dispatch(setState({ crud: false }))
    saveTreeData(getState().IncidentHtreeData.treeData, totalHLength, dispatch)
};



const recursiveUpdate = async (nodeData, childData, count, node_level_length, flatData, dispatch, getState) => {
    var childData = _.cloneDeep(childData)
    console.log(node_level_length, 'node_level_length', childData)
    _.each(childData, async (item, idx) => {
        var addedValue = node_level_length + 1
        childData[idx].node_level = node_level_length
        childData[idx].node_positon = "Level " + addedValue
        // //(idx,'idx',item)
        if (childData[idx].children !== undefined && childData[idx].children.length > 0) {
            count++;
            var level_idx = childData[idx].node_level + 1
            recursiveUpdate(nodeData, childData[idx].children, count, level_idx, flatData, dispatch, getState)
        }
        else {
            _.each(childData, (item, idx1) => {
                var getIdx = _.findIndex(flatData, { id: childData[idx1].id })
                console.log(getIdx, 'flatIdx')
                if (getIdx !== -1) {
                    flatData[getIdx] = {
                        ...flatData[getIdx],
                        node_level: childData[idx1].node_level,
                        node_positon: childData[idx1].node_positon,
                    }

                }

            })

            console.log("all completed", childData, count, flatData)
            const treeData = await getTreeFromFlatDataAsync(flatData);
            console.log(treeData, 'treeData')
            dispatch(setTreeData(treeData));
            await saveTreeData(treeData, getState().IncidentHtreeData.totalHLength, dispatch);

        }
    })
}


//  action to drag and drop  nodes
export const dndNode = (droppedNode) => async (dispatch, getState) => {
    try {
        console.log(droppedNode, 'droppedNode')
        const parentId = droppedNode.nextParentNode ? droppedNode.nextParentNode.id : null;
        //  droppedNode.node.parent_path = droppedNode.nextParentNode.parent_path
        var getParentNodeInfo
        if (droppedNode.nextParentNode != null) {
            getParentNodeInfo = {
                id: droppedNode.nextParentNode.id,
                parent: droppedNode.nextParentNode.parent,
                title: droppedNode.nextParentNode.title,
                subtitle: droppedNode.nextParentNode.subtitle,
                code: droppedNode.nextParentNode.code,
                type: droppedNode.nextParentNode.type,
                h_id: droppedNode.node.h_id,
                company_id: droppedNode.node.company_id,
                company_name: droppedNode.node.company_name,
                owner_id: droppedNode.node.owner_id,
            }
        }

        // var hInfo = JSON.parse(sessionStorage.getItem("hInfo"))


        const updatedTreeData = changeNodeAtPath({
            treeData: getState().IncidentHtreeData.treeData,
            path: droppedNode.path,
            getNodeKey: getNodeKey,
            newNode: {
                parent: parentId,
                // id: droppedNode.node.id,
                // title: droppedNode.node.title,
                // subtitle: droppedNode.node.subtitle,
                // type: droppedNode.node.type,
                // children: droppedNode.node.children || [],
                title: droppedNode.node.title,
                code: droppedNode.node.code,
                id: droppedNode.node.id,
                // parent: null,
                children: droppedNode.node.children == undefined ? [] : droppedNode.node.children,
                type: droppedNode.node.type,
                // parent_node: parentId ===null ? []:getParentNodeInfo ,
                // parent_path:parentId ===null ? [] : droppedNode.node.parent_path.concat([getParentNodeInfo]),
                h_id: droppedNode.node.h_id,
                company_id: droppedNode.node.company_id,
                company_name: droppedNode.node.company_name,
                owner_id: droppedNode.node.owner_id,
                user_path: droppedNode.node.user_path,
                unique_users: droppedNode.node.unique_users == undefined ? [] : droppedNode.node.unique_users,
                node_level: droppedNode.path.length - 1,
                category_input: droppedNode.node.category_input,
                subtitle: droppedNode.node.category_input == false ? "Level " + droppedNode.path.length : droppedNode.node.subtitle,
                node_positon: "Level " + droppedNode.path.length
            },
        });
        console.log(updatedTreeData, 'updatedTreeData')

        dispatch(setTreeData(updatedTreeData));
        await saveTreeData(updatedTreeData, getState().IncidentHtreeData.totalHLength, dispatch);

        if (droppedNode.node.children !== undefined) {
            if (droppedNode.node.children.length > 0) {
                console.log(droppedNode.node.children, 'droppedNode', droppedNode)
                var flatData = await treeDataToFlat(getState().IncidentHtreeData.treeData)
                var flatData = _.map(flatData, 'node')
                console.log(flatData, 'flatData')
                recursiveUpdate(droppedNode, droppedNode.node.children, 0, droppedNode.path.length, flatData, dispatch, getState)
            }
        }
        // console.log(droppedNode,'droppedNode',parentId)

    } catch (error) {
        console.error('Error occurred while performing DND operation:', error);
        // Handle the error gracefully, e.g., show a message to the user or perform fallback actions
        // Optionally, re-throw the error if you need to propagate it further
        throw error;
    }
};
//  action to select node
export const getNodeData = (nodeData) => (dispatch, getState) => {
    const { type, title, children } = nodeData;

};

//  action to Expand button
export const onTreeChange = (newTreeData, dispatch) => {
    // Dispatch the setTreeData action to update the Redux store with the new tree data
    dispatch(setTreeData(newTreeData));
    dispatch(setState({
        nodeCount: getVisibleNodeCount({ treeData: newTreeData })
    }));
};


// export default treeDataSlice.reducer;
export const updateTreeData = (state) => (state.IncidentHtreeData.treeData)
export const IncidentHtreeDataSliceReducer = treeDataSlice.reducer;