import React, { useEffect, useMemo } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { CardBody, Container, Row, Col, Card, Badge, UncontrolledTooltip, Spinner,Button } from 'reactstrap'
import TableContainer from 'components/Common/TableContainer'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { retriveRoleData, setRoleData } from 'toolkitStore/IncidentReport/IR_RoleSlice'

const IR_Roles = () => {
    const dispatch = useDispatch()
    const IR_RoleSlice = useSelector(state => state.IR_RoleSlice)

    useEffect(() => {
        console.log('useffect')
        sessionStorage.removeItem("role_id")
        dispatch(retriveRoleData())
    }, [])


    const completedStatus = (data) => {
        var labels = []
        data == false ? labels.push({ status: "In active", color: "#303335", badgeClass: "danger" }) :
            data == true ? labels.push({ status: "Active", color: "#fbae17", badgeClass: "success" }) :
                labels.push({ status: "In active", color: "#303335", badgeClass: "danger" })
        return labels
    }



    const columns = 
             [

            {
                accessor: "role_name",
                Header: "Role Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    var itemStatus = completedStatus(item.role_status)[0]
                    var screens = ""
                    _.map(item.facilities, (item2, idx) => {
                        if(item2.active){
                        if (idx === 0) screens = item2.menu
                        else screens = screens + " | " + item2.menu
                        }
                    })
                    return (
                        <div className="d-flex flex-row align-items-center" >
                            <div className="me-2">
                                {
                                    itemStatus.badgeClass === "success" ?
                                        <i
                                            className={"mdi mdi-checkbox-marked-circle-outline font-size-20 text-" + itemStatus.badgeClass}
                                        /> :
                                        <i
                                            className={"mdi mdi-circle-slice-8 font-size-20 text-" + itemStatus.badgeClass}
                                        />
                                }
                            </div>
                            <div className="d-flex flex-column">
                                <div>{item.role_name}</div>
                                <div className='font-size-13 text-secondary'>{screens}</div>
                            </div>
                        </div>
                    )
                }
            },
            {
                accessor: 'role_status',
                Header: 'Status',
                isSort: true,
                width: "10%",
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    var itemStatus = completedStatus(item.role_status)[0]
                    return (
                        <>
                            <div className="d-flex gap-3">
                                <Badge className={"font-size-12 badge-soft-" + itemStatus.badgeClass}
                                    color={itemStatus.color}
                                    pill
                                >
                                    {itemStatus.status}
                                </Badge>
                            </div>
                        </>
                    )
                }
            },
            {
                accessor: "Action",
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                   
                        <div className="d-flex gap-1" style={{ display: 'flex', alignContent: 'center' }}>
                            <Link to={"/add-roles"}> 
                            <Button type="button" color="primary" size="sm" className="btn-sm waves-effect waves-light me-2" onClick={(e) => {
                                sessionStorage.setItem("role_id",item._id)
                            }}
                            >
                                Edit Role {""} <i className="mdi mdi-pencil  align-middle ms-2"></i>
                            </Button>
                            </Link>
                </div>

                    )
                }
            }
        ]


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Roles" breadcrumbItem="Roles" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Manage Roles</h5>
                                        <div className="flex-shrink-0">
                                            <Link to="/add-roles"
                                                className="btn btn-primary me-1">Add New Roles</Link>
                                            <Link to="#!" className="btn btn-light me-1" onClick={() => {
                                                dispatch(setRoleData([]));
                                                dispatch(retriveRoleData())

                                            }}><i className="mdi mdi-refresh"></i></Link>
                                        </div>
                                    </div>
                                </CardBody>
                                {
                                    IR_RoleSlice.roleData.length > 0 ?
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={IR_RoleSlice.roleData}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                isJobListGlobalFilter={false}
                                                isPagination={true}
                                                iscustomPageSizeOptions={true}
                                                isShowingPageLength={true}
                                                customPageSize={5}
                                                tableClass="table-bordered align-middle nowrap mt-2"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination justify-content-end pagination-rounded"
                                            />

                                        </CardBody>
                                        :
                                        <CardBody>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div>Loading...</div>
                                                <Spinner className="ms-2" color="primary" />
                                            </div>

                                        </CardBody>
                                }

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default IR_Roles
