import React from 'react'
import { useEffect,useMemo,useState } from 'react'
import { CardBody, Container,Button, Row, Col, Card ,Spinner,UncontrolledTooltip,Badge , Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { retriveUserList, setUserList,setEditUserInfo,createUserInfo,setUserNumExist,validateRequired } from 'toolkitStore/IncidentReport/IR_UserSlice'
import { Link } from 'react-router-dom'
import TableContainer from './Components/TableContainer'

const IRUsers = () => {
    const dispatch = useDispatch()
    const IR_UserSlice = useSelector(state => state.IR_UserSlice)
    const [authUser,setAuthUser] = useState(JSON.parse(sessionStorage.getItem("authUser")))
    const [validInfo,setvalidInfo] = useState(null)
    console.log(IR_UserSlice,'IR_UserSlice');
    const [modal, setModal] = useState(false);
  
    useEffect(()=>{
        console.log("user");
        dispatch(retriveUserList())
    },[])


    const completedStatus = (data) => {
        var labels = []
        data == false ? labels.push({ status: "In Active", color: "#303335", badgeClass: "danger" }) :
            data == true ? labels.push({ status: "Active", color: "#fbae17", badgeClass: "success" }) :
                labels.push({ status: "In Active", color: "#303335", badgeClass: "danger" })
        return labels
    }


    const selectRole=(event,user)=>{
        console.log(event.target.value,'selectRole');
        var getIdx = _.findIndex(IR_UserSlice.roleList,{_id : event.target.value})
        if(getIdx !== -1){
            var userInfo = _.cloneDeep(user)
            userInfo["role"]=IR_UserSlice.roleList[getIdx]["role_name"]
            userInfo["role_id"]=IR_UserSlice.roleList[getIdx]["_id"]
            console.log(IR_UserSlice.roleList[getIdx],'authUser',userInfo)
            dispatch(createUserInfo(userInfo))

        }

    }


    const columns = useMemo(
        () => [
            {
                accessor: "firstname",
                Header: "User Name",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.firstname}
                        </div>

                    )
                }
            },
            {
                accessor: "email_id",
                Header: "Email ID",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.email_id === null ? "-" : item.email_id}
                        </div>

                    )
                }
            },
            {
                accessor: "phone_number",
                Header: "Phone Number",
                filterable: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <div className="text-dark"
                            style={{ cursor: "pointer" }}
                        >
                            {item.phone_number === null ? "-" : item.phone_number}
                        </div>

                    )
                }
            },
            {
                accessor: 'active',
                Header: 'Status',
                isSort: true,
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    var itemStatus = completedStatus(item.active)[0]
                    return (
                        <>
                            <div className="d-flex gap-3">
                                <Badge className={"font-size-12 badge-soft-" + itemStatus.badgeClass}
                                    color={itemStatus.color}
                                    pill
                                >
                                    {itemStatus.status}
                                </Badge>
                            </div>
                        </>
                    )
                }
            },
            {
                accessor: 'roles',
                Header: 'Roles',
                Cell: (cellProps) => {
                    var item = cellProps.row.original
                    var itemStatus = completedStatus(item.active)[0]
                    return (
                        <>
                            <div className="select-container">
                                <select disabled={itemStatus.status !== "Active"} 
                                onChange={(e)=>{selectRole(e,item)}}
                                defaultValue={item.role_id == null ? "Select Role" : item.role_id}
                                  style={{
                                    width: '200px',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc'
                                }}>
                                    <option disabled value={'Select Role'}>{"Select Role"}</option>
                                    {
                                        IR_UserSlice.roleList.map((data, idx) => (
                                            <option key={idx} value={data._id}>{data.role_name} </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </>
                    )
                }
            },
            {
                accessor: "Action",
                Header: "Action",
                Cell: (cellProps) => {
                    var item = cellProps.row.original;
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link to="/add-user" onClick={() => { dispatch(setUserNumExist(false));sessionStorage.removeItem("userId"); sessionStorage.setItem("userId", item._id);                          dispatch(setEditUserInfo(item))

                                 }} id={`edittooltip-${item._id}`} className="btn btn-sm btn-soft-info">
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${item._id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        // onClickDelete(item)
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    )

                }
            }

        ]
    )

    const toggleModal = () => setModal(!modal);


  
    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid={true}>
            <Breadcrumbs title="Manage Users" breadcrumbItem="Manage Users" />

                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                            <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Manage User</h5>
                                        <div className="flex-shrink-0">
                                            <Link
                                                onClick={async() => {
                                                var info = await dispatch(validateRequired())
                                                console.log(info,'info')
                                                if(info.data.data.branchInfo.length === 0 || info.data.data.deptInfo.length === 0 || info.data.data.locInfo.length === 0){
                                                    setModal(true)
                                                }
                                                setvalidInfo(info.data.data)
                                                dispatch(setEditUserInfo(null))
                                                dispatch(setUserNumExist(false))
                                                sessionStorage.removeItem("userId")
                                                }}
                                                to={!modal &&"/add-user" }
                                                className="btn btn-primary me-1">Add New User</Link>
                                            <Link to="#!" className="btn btn-light me-1" onClick={() => {
                                                // dispatch(setSevrtTypNameExist(false))
                                                
                                                dispatch(setEditUserInfo(null))
                                                dispatch(setUserList([]));
                                                dispatch(retriveUserList())

                                            }}><i className="mdi mdi-refresh"></i></Link>
                                        </div>
                                    </div>
                            </CardBody>
                            {

                                IR_UserSlice.userList.length >0 ?
                                <CardBody>
                                <TableContainer
                                    columns={columns}
                                    data={IR_UserSlice.userList}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    isJobListGlobalFilter={false}
                                    isPagination={true}
                                    iscustomPageSizeOptions={true}
                                    isShowingPageLength={true}
                                    customPageSize={5}
                                    tableClass="table-bordered align-middle nowrap mt-2"
                                    paginationDiv="col-sm-12 col-md-7"
                                    pagination="pagination justify-content-end pagination-rounded"
                                />

                            </CardBody>
                                :
                                IR_UserSlice.userList.length === 0 && IR_UserSlice.aplnRequestLoading === false  ?
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                No Data Found
                                </div>
                                :
                                <CardBody>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <div>Loading...</div>
                                    <Spinner className="ms-2" color="primary" />
                                </div>
                            </CardBody>
                            }

                        </Card>

                    </Col>

                        <Modal isOpen={modal} toggle={toggleModal} className="custom-modal">
                            <ModalHeader toggle={toggleModal} className="text-center">Action Required</ModalHeader>
                            <ModalBody className="text-center">
                                <h6>{"You need to add the following before you can create a user"}</h6>
                                <div className="modal-buttons">
                                    {
                                        validInfo?.branchInfo.length === 0 &&
                                        <Link to={"/manage-organisation"}><Button color="info"
                                            className="m-2">
                                            Add Department
                                        </Button></Link>
                                    }

                                    {
                                        validInfo?.deptInfo.length === 0 &&
                                        <Link to={"/manage-organisation"}><Button color="warning"
                                            // onClick={goToBranch} 
                                            className="m-2">
                                            Add Branch
                                        </Button></Link>
                                    }

                                    {
                                        validInfo?.locInfo.length === 0 &&
                                        <Link to={"/manage-location"}><Button color="success"
                                            // onClick={goToDepartment}
                                            className="m-2">
                                            Add Location
                                        </Button></Link>
                                    }
                                </div>
                            </ModalBody>

                        </Modal>

                </Row>


            </Container>
        </div>
    </React.Fragment>
  )
}
export default IRUsers
