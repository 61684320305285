import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { retriveHData } from 'toolkitStore/IncidentReport/IR_LocationSlice';
import TreeStructure from './Components/TreeStructure';

const LocationInfo = () => {
    const dispatch =  useDispatch()
    const IR_Location  = useSelector(state => state.IR_LocationSlice)
    console.log(IR_Location,'IR_Location');

    useEffect(()=>{
        console.log("object");
        var sessionInfo = sessionStorage.getItem("hInfo")
        console.log(sessionInfo,'sessionInfo')
        if(sessionInfo){
            // dispatch(retriveHData(sessionInfo))
        }
        else{

        }

    },[])


  return (
    <div className='page-content'><TreeStructure/></div>
  )
}
export default LocationInfo
